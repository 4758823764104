import { action, makeObservable, observable } from 'mobx';
import { currency } from 'bu.formatters';
import findIndex from 'lodash/findIndex';
import slice from 'lodash/slice';
import { MAX_ALLOWED_BENEFIT_AMOUNT, ValueOf } from 'bu.lookups';

import { assertIsGreaterOrEqualThan } from '../helpers/validation.helper';
import ValidationState from '../helpers/validation-state';
import * as ERROR_MESSAGES from '../constants/error-messages.constant';
import BENEFIT_AMOUNT from '../constants/benefit-amount.constant';
import searchDataOptionsFactory from './../factories/search-data-options.factory';
import { SearchData } from '../types/search-data';
import { DYNAMIC_PREMIUM_FILTER, TARIFF_GRADE_FILTER } from '../constants/filter.constant';

class ResultFiltersStore {
  initialSearchData?: SearchData;
  @observable benefitAmount: string|number = 0;
  @observable monthlyBenefitAmount: string|number = 0;
  @observable benefitAgeLimit = 0;
  @observable excellentIncreaseOptionWithReasonDesired = false;
  @observable excellentSustainabilityDesired = false;
  @observable excellentStiftungWarentest = false;
  @observable excellentCertifiedSickness = false;
  @observable quickBenefitForDreadDisease = false;
  @observable dynamicPremiumFilter: ValueOf<typeof DYNAMIC_PREMIUM_FILTER>|'' = '';
  @observable tariffGradeFilter: ValueOf<typeof TARIFF_GRADE_FILTER> = '';
  @observable benefitAgeLimits: { value: number; text: string }[] = [];
  @observable showMore = false;

  @observable benefitAmountValidation = new ValidationState();
  @observable monthlyBenefitAmountValidation = new ValidationState();

  constructor() {
    makeObservable(this);
  }

  @action init(initialSearchData: SearchData) {
    this.initialSearchData = initialSearchData;

    this.benefitAmount = initialSearchData.benefitAmount;
    this.monthlyBenefitAmount = initialSearchData.benefitAmount;
    this.benefitAgeLimit = initialSearchData.benefitAgeLimit;
    this.excellentIncreaseOptionWithReasonDesired = initialSearchData.excellentIncreaseOptionWithReasonDesired;
    this.excellentSustainabilityDesired = Boolean(initialSearchData.excellentSustainabilityDesired);
    this.excellentStiftungWarentest = Boolean(initialSearchData.excellentStiftungWarentest);
    this.excellentCertifiedSickness = Boolean(initialSearchData.excellentCertifiedSickness);
    this.quickBenefitForDreadDisease = Boolean(initialSearchData.quickBenefitForDreadDisease);
    this.dynamicPremiumFilter = initialSearchData.dynamicPremiumFilter;
    this.tariffGradeFilter = initialSearchData.tariffGradeFilter;

    this.getBenefitAgeLimitArrayRange();
    this.validateBenefitAmount();
  }

  @action changeBenefitAmount(value: string|number) {
    const benefitAmount = currency.formatPriceWithoutSeparators(value);
    if (benefitAmount > MAX_ALLOWED_BENEFIT_AMOUNT) {
      return;
    }

    this.benefitAmount = benefitAmount;
  }

  @action validateBenefitAmount() {
    this.benefitAmountValidation = this.isBenefitAmountValid();
    return this.benefitAmountValidation.isValid;
  }

  isBenefitAmountValid() {
    return assertIsGreaterOrEqualThan(
      BENEFIT_AMOUNT.MIN_BENEFIT_AMOUNT,
      Number(this.benefitAmount),
      ERROR_MESSAGES.INVALID_BENEFIT_AMOUNT.UNDER_LIMIT
    );
  }

  validateAll() {
    return this.isBenefitAmountValid().isValid;
  }

  getSearchDataFilters() {
    const {
      benefitAmount,
      benefitAgeLimit,
      excellentIncreaseOptionWithReasonDesired,
      excellentSustainabilityDesired,
      excellentStiftungWarentest,
      excellentCertifiedSickness,
      quickBenefitForDreadDisease,
      dynamicPremiumFilter,
      tariffGradeFilter,
    } = this;

    return {
      benefitAmount,
      benefitAgeLimit,
      excellentIncreaseOptionWithReasonDesired,
      excellentSustainabilityDesired,
      excellentStiftungWarentest,
      excellentCertifiedSickness,
      quickBenefitForDreadDisease,
      dynamicPremiumFilter,
      tariffGradeFilter,
    };
  }

  @action
  getBenefitAgeLimitArrayRange() {
    const searchDataOptions = searchDataOptionsFactory.searchDataOptions;
    const benefitAgeLimit = searchDataOptions.mandatoryOptions.benefitAgeLimit;

    const selectedIndex = this._getCurrentIndex();
    const startIndex = Math.max(0, selectedIndex - 2);
    const endIndex = Math.min(startIndex + 6, benefitAgeLimit.length);
    const benefitAgeLimits = this.showMore ? benefitAgeLimit : slice(benefitAgeLimit, startIndex, endIndex);
    this.benefitAgeLimits = [...benefitAgeLimits];
  }

  anyFilterIsSelected() {
    return (
      this.excellentIncreaseOptionWithReasonDesired ||
      this.excellentSustainabilityDesired ||
      this.excellentStiftungWarentest ||
      this.excellentCertifiedSickness ||
      this.tariffGradeFilter !== '' ||
      this.quickBenefitForDreadDisease ||
      this.dynamicPremiumFilter !== ''
    );
  }

  _getCurrentIndex() {
    const searchDataOptions = searchDataOptionsFactory.searchDataOptions;
    const benefitAgeLimit = searchDataOptions.mandatoryOptions.benefitAgeLimit;
    return findIndex(benefitAgeLimit, (limit) => limit.value === this.benefitAgeLimit);
  }

  @action setQuickBenefitForDreadDisease(value: boolean) {
    this.quickBenefitForDreadDisease = value;
  }

  @action setExcellentCertifiedSickness(value: boolean) {
    this.excellentCertifiedSickness = value;
  }

  @action setExcellentSustainabilityDesired(value: boolean) {
    this.excellentSustainabilityDesired = value;
  }

  @action setExcellentIncreaseOptionWithReasonDesired(value: boolean) {
    this.excellentIncreaseOptionWithReasonDesired = value;
  }

  @action setExcellentStiftungWarentest(value: boolean) {
    this.excellentStiftungWarentest = value;
  }

  @action setDynamicPremiumFilter(value: ValueOf<typeof DYNAMIC_PREMIUM_FILTER>|'') {
    this.dynamicPremiumFilter = value;
  }

  @action setTariffGradeFilter(value: ValueOf<typeof TARIFF_GRADE_FILTER>) {
    this.tariffGradeFilter = value;
  }

  @action resetFilters(): void {
    this.excellentIncreaseOptionWithReasonDesired = false;
    this.excellentSustainabilityDesired = false;
    this.excellentStiftungWarentest = false;
    this.excellentCertifiedSickness = false;
    this.tariffGradeFilter = '';
    this.dynamicPremiumFilter = '';
  }
}

export default ResultFiltersStore;
