import http from 'bu.http';
import { Mobile } from 'bu.components-customer-apps';

import configService from './../services/config.service';
import { Config } from '../types/config';
import { GetConsultantCarouselWithSubstituteResponse } from
  '../types/network/GetConsultantCarouselWIthSubstituteResponse';

class ConsultantCarouselService {
  config: Config;
  constructor() {
    this.config = configService.getConfig();
  }

  getConsultantsCarousel() {
    return http.get<Mobile.Consultant[]>(`${this.config.services.mobileApi}/consultant-carousel`);
  }

  getConsultantForCarouselById(id: number) {
    return http.get<Mobile.Consultant>(`${this.config.services.mobileApi}/consultant-carousel/${id}`);
  }

  getConsultantCarouselWithSubstitute(id: number, date: string) {
    const queryParams = new URLSearchParams({ date }).toString();
    return http.get<GetConsultantCarouselWithSubstituteResponse>(
      `${this.config.services.mobileApi}/consultant-carousel-with-substitute/${id}?${queryParams}`
    );
  }
}

export default new ConsultantCarouselService();
