import { isClient } from 'bu.helpers';
import { DEVICE_TYPE, Type } from 'bu.lookups';
import semverGte from 'semver/functions/gte';
import semverCoerce from 'semver/functions/coerce';
import { webAppLoggingService } from 'bu.logger-client';
import userAgentService from '../services/user-agent.service';
import { DEVICE_OS } from '../constants/device.constant';
import { getDevice, getOs } from '../helpers/user-agent.helper';

type ParsedUserAgent = {
  osName: string;
  osVersion?: string;
};

const ANDROID_OS = 'Android';
const ANDROID_X86_OS = 'Android-x86';
const IOS_OS = 'iOS';
const ANDROID_MIN_VERSION = '8.0.0';
const IOS_MIN_VERSION = '15.0.0';
const HUAWEI_PHONE = 'Huawei';
const WINDOWS_PHONE = 'Windows';

class UaParserService {
  getDeviceTypeFromUserAgent(userAgent: string): Type.DeviceType {
    const device = getDevice(userAgent);

    if (device?.type === DEVICE_TYPE.TABLET || device?.type === DEVICE_TYPE.MOBILE) {
      return device.type;
    }

    return DEVICE_TYPE.DESKTOP;
  }

  getDeviceType(): Type.DeviceType {
    return this.getDeviceTypeFromUserAgent(userAgentService.getUserAgent());
  }

  getDeviceOS(userAgent = userAgentService.getUserAgent()) {
    const os = getOs(userAgent);

    return os.name ? os.name.toString() : DEVICE_TYPE.DESKTOP;
  }

  isIOS() {
    if (isClient()) {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    const os = this.getDeviceOS(userAgentService.getUserAgent());
    return os === DEVICE_OS.IOS;
  }

  isAndroid() {
    if (isClient()) {
      return /Android/i.test(navigator.userAgent);
    }

    const os = this.getDeviceOS(userAgentService.getUserAgent());
    return os === DEVICE_OS.ANDROID;
  }

  isHuaweiDevice(): boolean {
    const userAgent = userAgentService.getUserAgent();

    return this.#isHuaweiPhone(userAgent);
  }

  #isHuaweiPhone(userAgent: string): boolean {
    const deviceResult = getDevice(userAgent);
    return deviceResult.vendor === HUAWEI_PHONE;
  }

  #isWindowsPhone(userAgent: string): boolean {
    const osResult = getOs(userAgent);
    return osResult.name?.includes(WINDOWS_PHONE) ?? false;
  }

  isValidDeviceForAppPromoModal(): boolean {
    const userAgent = userAgentService.getUserAgent();
    return (
      !this.#isHuaweiPhone(userAgent) &&
      !this.#isWindowsPhone(userAgent) &&
      this.#isValidOsVersionForAppPromoModal(userAgent)
    );
  }

  #isValidOsVersionForAppPromoModal(userAgent: string): boolean {
    const userAgentOs = this.#parseUserAgentOs(userAgent);
    const version = userAgentOs.osVersion ?? '';
    switch (userAgentOs.osName) {
      case ANDROID_OS:
        return semverGte(version, ANDROID_MIN_VERSION);
      case IOS_OS:
        return semverGte(version, IOS_MIN_VERSION);
      default:
        return false;
    }
  }

  #parseUserAgentOs(userAgent: string): ParsedUserAgent {
    const parsedUserAgent: ParsedUserAgent = {
      osName: '',
      osVersion: '',
    };

    const osResult = getOs(userAgent);
    if (osResult.name && [ANDROID_OS, ANDROID_X86_OS].includes(osResult.name)) {
      parsedUserAgent.osName = ANDROID_OS;
      parsedUserAgent.osVersion = osResult.version;
    } else if (osResult.name === IOS_OS) {
      parsedUserAgent.osName = IOS_OS;
      parsedUserAgent.osVersion = osResult.version;
    } else {
      webAppLoggingService.warn(`Current os is neither Android or iOS. Will not parse user agent: ${userAgent}`);
    }

    return {
      ...parsedUserAgent,
      osVersion: semverCoerce(parsedUserAgent.osVersion)?.version || '',
    };
  }
}

export default new UaParserService();
