import { action, computed, flow, makeObservable, observable } from 'mobx';
import http, { CancelTokenSource } from 'bu.http';
import { sessionStorage } from 'js-storage';
import { Decimal } from 'decimal.js';
import tariffService from '../services/tariff.service';
import navigationService from '../services/navigation.service';
import SESSION_STORAGE_KEYS from '../constants/session-storage-keys.constant';
import { canShowGfBanner } from '../helpers/gf-banner.helper';
import { SearchData } from '../types/search-data';

class GfBannerStore {
  gfPopupTimer?: ReturnType<typeof setTimeout>;
  @observable buMinPrice = Number.MIN_VALUE;
  @observable gfMinPrice = Number.MAX_VALUE;
  @observable shouldShowGfBanner = false;
  @observable shouldShowGfPopup = false;
  @observable isLoading = true;

  cancellationToken?: CancelTokenSource;

  constructor() {
    makeObservable(this);
  }

  @flow *loadGfMinPrice(searchData: SearchData, buMinPrice: number) {
    try {
      this.isLoading = true;
      const usedSearchData = { ...searchData };
      this.shouldShowGfBanner = false;
      this.buMinPrice = buMinPrice;
      this._cancelTariffLoadingRequest();

      if (canShowGfBanner(searchData)) {
        this.cancellationToken = http.getCancelToken();
        this.gfMinPrice = yield tariffService.getMinGfTariffPrice(usedSearchData, this.cancellationToken);
        this.shouldShowGfBanner = this.isGfPriceDifferenceBigEnough();
      }

      this.updateGfPopupState();
    } catch (error) {
      if (!http.isCancelError(error)) {
        throw error;
      }
    } finally {
      this.cancellationToken = undefined;
      this.isLoading = false;
    }
  }

  updateGfPopupState() {
    this.cancelGfPopupTimeout();
    this.gfPopupTimer = setTimeout(() => {
      const isPopupShownOnce = sessionStorage.get(SESSION_STORAGE_KEYS.STORAGE_IS_GF_POPUP_SHOWN_ONCE);
      if (this.shouldShowGfBanner && !isPopupShownOnce && navigationService.isOnResultPage()) {
        this.showPopupWindow();
      }
      this.gfPopupTimer = undefined;

    }, 6000);
  }

  @action
  showPopupWindow() {
    this.shouldShowGfPopup = true;
    sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_IS_GF_POPUP_SHOWN_ONCE, true);
  }

  cancelGfPopupTimeout() {
    if (this.gfPopupTimer) {
      clearTimeout(this.gfPopupTimer);
      this.gfPopupTimer = undefined;
    }
  }

  isGfPriceDifferenceBigEnough() {
    if (!this.gfMinPrice) {
      return false;
    }

    const buMinPriceDecimal = new Decimal(this.buMinPrice);
    const gfMinPriceDecimal = new Decimal(this.gfMinPrice);
    return !!(
      this.gfMinPrice &&
      (gfMinPriceDecimal.lessThanOrEqualTo(Number(this.buMinPrice) * 0.6)) &&
      ((buMinPriceDecimal.minus(gfMinPriceDecimal)).greaterThan(20)));
  }

  @action
  resetGfBannerState() {
    this.shouldShowGfBanner = false;
    this.shouldShowGfPopup = false;
  }

  @computed
  get percentage() {
    return Math.round((1 - this.gfMinPrice / this.buMinPrice) * 100);
  }

  _cancelTariffLoadingRequest() {
    if (this.cancellationToken) {
      this.cancellationToken.cancel();
      this.cancellationToken = undefined;
    }
  }
}

export default GfBannerStore;
