import getConfig from 'next/config';
import { isTest } from 'bu.helpers';
import { Config } from '../types/config';

class ConfigService {
  #config?: Config;

  getConfig(): Config {
    if (!this.#config) {
      const config = isTest() ? global.buConfig : getConfig().publicRuntimeConfig;
      this.#config = config;
      return config;
    }

    return this.#config;
  }

  setBuRef(buRef: string) {
    this.getConfig().buRef = buRef;
  }
}

export default new ConfigService();
