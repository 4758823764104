import deviceOutputService from '../services/device-output.service';

class DeviceOutputStore {
  deviceOutput?: string;

  isApp() {
    return this.deviceOutput === 'app';
  }

  isAppOrTabletApp() {
    return deviceOutputService.getDeviceOutput() === 'app' || deviceOutputService.getDeviceOutput() === 'tabletapp' ||
      this.deviceOutput === 'app' || this.deviceOutput === 'tabletapp';
  }

  setDeviceOutput(deviceOutput: string) {
    this.deviceOutput = deviceOutput;
  }
}

export default DeviceOutputStore;
