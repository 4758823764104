import { sessionStorage } from 'js-storage';

import SESSION_STORAGE_KEYS from '../constants/session-storage-keys.constant';
import { PersonalData } from '../types/personal-data';

function savePersonalDataToStorage(personalData: PersonalData, bestSsoMode: boolean) {
  const personalDataToSave = createPersonalDataWithAgreementFieldsSetToFalse(personalData);
  sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_PERSONAL_DATA, { personalData: personalDataToSave, bestSsoMode });
}

function getPersonalDataFromStorage() {
  return sessionStorage.get<{ personalData: PersonalData; bestSsoMode: boolean }>(
    SESSION_STORAGE_KEYS.STORAGE_PERSONAL_DATA);
}

function removePersonalDataFromStorage() {
  sessionStorage.remove(SESSION_STORAGE_KEYS.STORAGE_PERSONAL_DATA);
}

function createPersonalDataWithAgreementFieldsSetToFalse(personalData: PersonalData) {
  const personalDataClone = { ...personalData };
  personalDataClone.newTermsConfirmed = false;
  personalDataClone.agreementToContactFromAdvisor = false;
  return personalDataClone;
}

export {
  removePersonalDataFromStorage,
  getPersonalDataFromStorage,
  savePersonalDataToStorage
};
