import { action, flow, makeObservable, observable } from 'mobx';

import { Mobile } from 'bu.components-customer-apps';
import consultantCarouselService from '../services/consultant-carousel.service';

class ConsultantCarouselStore {
  @observable consultants: Mobile.Consultant[] = [];
  @observable isInformationTextExpanded = false;
  @observable slideIndex = 0;
  @observable lastSeenConsultant?: Mobile.Consultant;

  constructor() {
    makeObservable(this);
  }

  @flow *loadConsultants() {
    if (this.consultants.length === 0) {
      this.consultants = yield consultantCarouselService.getConsultantsCarousel();
    }
    this.setLastSeenConsultant(this.slideIndex);
  }

  @action updateCarouselSlideIndex(slideIndex: number) {
    this.slideIndex = slideIndex;
  }

  @action setLastSeenConsultant(slideIndex: number) {
    this.lastSeenConsultant = this.consultants[slideIndex];
  }
}

export default ConsultantCarouselStore;
