import http from 'bu.http';
import configService from './config.service';
import { CustomerRating } from '../types/customer-rating';

class EFeedbackService {
  mobileApiUrl: string;
  constructor() {
    const config = configService.getConfig();
    this.mobileApiUrl = config.services.mobileApi;
  }

  getCustomerRatings() {
    return http.get<CustomerRating[]>(this.mobileApiUrl, '/efeedback/average-ratings');
  }
}

export default new EFeedbackService();
