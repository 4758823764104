import { action, flow, makeObservable, observable } from 'mobx';
import { NO_RESULTS_SPECIAL_TARIFF_ID, PRODUCT } from 'bu.lookups';
import { webAppLoggingService } from 'bu.logger-client';

import tariffService from './../services/tariff.service';
import { Tariff } from '../types/tariff';
import { SearchData } from '../types/search-data';

class TariffStore {
  @observable tariff?: Tariff;
  @observable isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @flow *loadTariff(searchData: SearchData, tariffId: number) {
    const clonedSearchData: SearchData = { ...searchData };
    let tariff: Awaited<ReturnType<typeof tariffService.getTariff>> = '';

    try {
      this.setIsLoading(true);

      tariff = yield tariffService.getTariff(clonedSearchData, tariffId);
      if (!tariff && clonedSearchData.useFormulas !== false) {
        webAppLoggingService.warn(
          `tariff ${tariffId} was not found. Will retry without formulas.`,
          { searchData: clonedSearchData }
        );

        tariff = yield tariffService.getTariff({ ...clonedSearchData, useFormulas: false }, tariffId);
      }

      if (!tariff) {
        throw new Error(`tariff ${tariffId} was not found. ${JSON.stringify({ searchData: clonedSearchData })}`);
      }
    } finally {
      this.setIsLoading(false);
    }

    this.tariff = {
      ...tariff,
      // TODO: VERBU-10509 temporarily add product field here. To be removed/improved in the future
      product: tariffId === 9999 ? PRODUCT.BU : tariff.product,
    };
  }

  isNoResultsTariff() {
    return this.tariff?._id === NO_RESULTS_SPECIAL_TARIFF_ID;
  }
}

export default TariffStore;
