import { Tariff, TariffFeature } from '../types/tariff';

function showDifferences(tariffs: Tariff[]) {
  const allCategoriesAndFeatures = getAllFeatures(tariffs[0]);

  return allCategoriesAndFeatures.map(
    (category) => ({
      ...category,

      features: category.features.filter(
        (feature) => _hasDifferences(feature, tariffs))
    })
  ).filter((category) => category.features.length > 0);
}

function getAllFeatures(tariff: Tariff) {
  return tariff.categories.map(
    (category) => ({
      ...category,
      features: category.features
    })
  );
}

function _hasDifferences(feature: TariffFeature, tariffs: Tariff[]) {
  const [tariff1, tariff2, tariff3] = tariffs;
  const feature1 = _getSingleTariffFeature(tariff1, feature);
  const feature2 = _getSingleTariffFeature(tariff2, feature);

  if (tariffs.length === 3) {
    const feature3 = _getSingleTariffFeature(tariff3, feature);
    return _areNotEqual(feature1, feature2) || _areNotEqual(feature2, feature3);
  }
  return _areNotEqual(feature1, feature2);
}

function _areNotEqual(feature1: TariffFeature|undefined, feature2: TariffFeature|undefined) {
  return feature1?.formattedValue !== feature2?.formattedValue;
}

function _getSingleTariffFeature(tariff: Tariff, feature: TariffFeature) {
  for (const category of tariff.categories) {
    for (const singleFeature of category.features) {
      if (singleFeature.name === feature.name) {
        return singleFeature;
      }
    }
  }
  return undefined;
}

export {
  showDifferences,
  getAllFeatures
};
