const FEATURES_TOGGLE_BUTTON = {
  IMPORTANT: 'Wichtigste Leistungen',
  DIFFERENT: 'Nur Unterschiede',
  ALL: 'Alle Leistungen'
} as const;

export default FEATURES_TOGGLE_BUTTON;

export const FEATURES_TOGGLE_PIWIK_EVENTS = {
  IMPORTANT: 'ClickOnMainBenefits',
  DIFFERENT: 'ClickOnDifferences',
  ALL: 'ClickOnAllBenefit'
} as const;
