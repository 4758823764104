import { SORT_OPTIONS, SortOption } from 'bu.lookups';

class SortOptionsFactory {
  sortOptions = [
    { value: SORT_OPTIONS.RESULT_ZERO, text: 'Empfehlung + Preis' },
    { value: SORT_OPTIONS.NET_PRICE, text: 'Niedrigster Preis' },
    { value: SORT_OPTIONS.GRADE, text: 'Beste Note' },
    { value: SORT_OPTIONS.NAME_ASC, text: 'Anbieter (A-Z)' },
    { value: SORT_OPTIONS.NAME_DESC, text: 'Anbieter (Z-A)' }
  ] as const;

  getSortOptionLabel(sortOption: SortOption): string {
    return this.sortOptions.find(option => option.value === sortOption)!.text;
  }
}
export default new SortOptionsFactory();
