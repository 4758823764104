import { action, flow, makeObservable, observable } from 'mobx';
import { strict as assert } from 'assert';
import { B2B_AD_PARTNERS, SEARCH_DATA_FIELDS } from 'bu.lookups';
import tariffService from '../services/tariff.service';
import { getSearchDataWithUnselectedFilters } from '../helpers/search-data.helper';
import { SearchData } from '../types/search-data';
import { Insurance } from '../types/tariff';
import { EUROPA_CROSS_SELLING_INSURANCE_ID } from '../constants/europa-cross-selling.constant';

class InsuranceFiltersStore {
  @observable notFilteredInsurances = new Map<number, Insurance>(); // Map(insuranceId, insurance);
  @observable filteredInsurancesAlphabetically = new Map<number, Insurance>(); // Map(insuranceId, insurance);
  @observable appliedInsurancesQueue = new Map<number, Insurance>(); // Map(insuranceId, insurance);
  @observable minPricePerInsuranceFilter: Record<number, number> = {};

  constructor() {
    makeObservable(this);
  }

  @flow *init(searchData: SearchData) {
    yield Promise.all([
      this.loadFilteredInsurances(searchData),
      this.loadNotFilteredInsurances(searchData)
    ]);
    assert(searchData.insuranceFilters, 'insuranceFilters should be defined');
    this.setAppliedInsuranceFilterIds(searchData.insuranceFilters);
  }

  @action setAppliedInsuranceFilterIds(appliedInsuranceIds: number[]) {
    this.appliedInsurancesQueue.clear();
    appliedInsuranceIds.forEach((insuranceId) => {
      const insurance = this.notFilteredInsurances.get(insuranceId);
      if (insurance) {
        this.appliedInsurancesQueue.set(insurance._id, insurance);
      }
    });
  }

  @flow *loadFilteredInsurances(searchData: SearchData) {
    let insurances: Awaited<ReturnType<typeof tariffService.getTariffsInsurances>> =
      yield tariffService.getTariffsInsurances({ ...searchData, insuranceFilters: [] });
    this.filteredInsurancesAlphabetically.clear();
    if (searchData[SEARCH_DATA_FIELDS.B2B_AD_PARTNER] !== B2B_AD_PARTNERS.EUROPA) {
      insurances = insurances.filter((insurance) => insurance._id !== EUROPA_CROSS_SELLING_INSURANCE_ID);
    }
    insurances.sort((a, b) => (a.name.toLowerCase() >= b.name.toLowerCase()) ? 1 : -1)
      .forEach((insurance) => this.filteredInsurancesAlphabetically.set(insurance._id, insurance));
  }

  @flow *loadNotFilteredInsurances(searchData: SearchData) {
    const searchDataWithoutFilters = getSearchDataWithUnselectedFilters(searchData);
    const insurances: Awaited<ReturnType<typeof tariffService.getTariffsInsurances>> =
      yield tariffService.getTariffsInsurances(searchDataWithoutFilters);
    this.notFilteredInsurances.clear();
    insurances.sort((a, b) => (a.name.toLowerCase() >= b.name.toLowerCase()) ? 1 : -1)
      .forEach((insurance) => this.notFilteredInsurances.set(insurance._id, insurance));
  }

  getAppliedInsuranceFilterIds() {
    return [...this.appliedInsurancesQueue.keys()];
  }

  @action setMinPricePerInsurance(minPrices: Record<number, number>) {
    this.minPricePerInsuranceFilter = minPrices;
  }

  @action toggleInsuranceFilter(insurance: Insurance) {
    if (this.appliedInsurancesQueue.has(insurance._id)) {
      this.appliedInsurancesQueue.delete(insurance._id);
    } else {
      this.appliedInsurancesQueue.set(insurance._id, insurance);
    }
  }

  getInsurancesOrderedByLastApplied() {
    const orderedFiltersMap = new Map([
      ...[...this.appliedInsurancesQueue].reverse(),
      ...this.filteredInsurancesAlphabetically]
    );

    return [...orderedFiltersMap.values()];
  }

  isInsuranceFilterAvailable(insurance: Insurance) {
    return this.filteredInsurancesAlphabetically.has(insurance._id);
  }

  isInsuranceFilterApplied(insurance: Insurance) {
    return this.appliedInsurancesQueue.has(insurance._id);
  }

  isAnyInsuranceFilterApplied() {
    return this.appliedInsurancesQueue.size > 0;
  }

  isOnlyOneFilterApplied() {
    return this.appliedInsurancesQueue.size === 1;
  }

  getAmountOfAppliedFilters() {
    return this.appliedInsurancesQueue.size;
  }

  @action clearInsuranceFilters() {
    this.appliedInsurancesQueue.clear();
  }
}

export default InsuranceFiltersStore;
