import { action, flow, makeObservable, observable } from 'mobx';
import { sessionStorage } from 'js-storage';
import Router from 'next/router';
import { strict as assert } from 'assert';

import { SETUP_APPOINTMENT_FAILURE_REASON, SEARCH_DATA_FIELDS, ValueOf } from 'bu.lookups';

import { webAppLoggingService } from 'bu.logger-client';
import { isPublicServant } from 'bu.helpers';
import { Mobile } from 'bu.components-customer-apps';
import consultantCalendarService from '../services/consultant-calendar.service';
import appointmentService from '../services/appointment.service';
import inquiryService from '../services/inquiry.service';
import consultantCarouselService from '../services/consultant-carousel.service';
import ValidationState from '../helpers/validation-state';
import emailService from '../services/email.service';
import piwikService from '../services/piwik.service';
import navigationService from '../services/navigation.service';

import SESSION_STORAGE_KEYS from '../constants/session-storage-keys.constant';

import {
  buildTimeslotFromRelevantAppointment,
  getLoadingDays,
  validateAppointment,
  validateTimeslot,
} from '../helpers/consultant-calendar.helper';
import { SearchData } from '../types/search-data';
import { AppointmentData } from '../types/appointment-data';
import { TimeSlot } from '../types/timeslot';
import { AssignedConsultantInfo } from '../types/assigned-consultant-info';
import { RelevantAppointment } from '../types/relevant-appointment';

class ConsultantCalendarStore {
  @observable isLoading = true;
  @observable calendar?: Mobile.CalendarCarouselAppointmentDate[];
  @observable isAppointmentBooked = false;
  @observable emailContentToConsultant?: string;
  @observable sendMailButtonClicked = false;
  @observable setupAppointmentErrorReason?: ValueOf<typeof SETUP_APPOINTMENT_FAILURE_REASON>;
  @observable showLeadWasAlreadyAppointedDialog = false;
  @observable hasExistingAppointment = false;
  @observable acknowledgedExpiredSession = sessionStorage.get(
    SESSION_STORAGE_KEYS.STORAGE_ACKNOWLEDGED_EXPIRED_SESSION,
  );

  @observable emailValidationState = new ValidationState();
  isAppointmentChanged = false;
  @observable timeslot?: TimeSlot;
  @observable assignedConsultant?: AssignedConsultantInfo;

  isPublicServantsCalendar = false;

  constructor() {
    makeObservable(this);
  }

  @flow *loadCalendar({ appointmentData, searchData }: { appointmentData?: AppointmentData; searchData?: SearchData }) {
    try {
      this.isLoading = true;
      if (appointmentData) {
        this.setDataFromStorage(appointmentData);
      } else {
        this.resetAppointmentData();
      }
      this.isPublicServantsCalendar = searchData
        ? isPublicServant(searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])
        : this.isPublicServantsCalendar;
      this.calendar = yield consultantCalendarService.getConsultantsCalendar(this.isPublicServantsCalendar);
    } finally {
      this.isLoading = false;
    }
  }

  @flow *loadSingleConsultantCalendar(inquiryPublicId: string) {
    try {
      this.isLoading = true;
      this.resetAppointmentData();

      const [assignedConsultantInfo, lastRelevantAppointment]: [
        Awaited<ReturnType<typeof inquiryService.getInquiryAssignedConsultantPersonalInfo>>,
        Awaited<ReturnType<typeof appointmentService.getRelevantInquiryAppointment>>,
      ] = yield Promise.all([
        inquiryService.getInquiryAssignedConsultantPersonalInfo(inquiryPublicId),
        appointmentService.getRelevantInquiryAppointment(inquiryPublicId),
      ]);

      assert(assignedConsultantInfo, 'assignedConsultantInfo is null');
      assert(lastRelevantAppointment, 'lastRelevantAppointment is undefined');

      this.timeslot = buildTimeslotFromRelevantAppointment(lastRelevantAppointment, assignedConsultantInfo.id);

      this.assignedConsultant = assignedConsultantInfo;

      this.calendar = yield consultantCalendarService.getSingleConsultantCalendarWithSubstitutes(
        assignedConsultantInfo.id,
      );
    } finally {
      this.isLoading = false;
    }
  }

  @action
  assignTimeslotAndConsultant(
    assignedConsultantInfo: AssignedConsultantInfo,
    lastRelevantAppointment: RelevantAppointment,
  ) {
    this.timeslot = buildTimeslotFromRelevantAppointment(lastRelevantAppointment, assignedConsultantInfo.id);

    this.hasExistingAppointment = Boolean(this.timeslot?.isRelevantAppointment);

    this.assignedConsultant = assignedConsultantInfo;
  }

  @action
  setCalendar(calendar: Mobile.CalendarCarouselAppointmentDate[]) {
    this.calendar = calendar;
  }

  @action
  resetAppointmentData() {
    this.calendar = undefined;
    this.isAppointmentBooked = false;
    this.isAppointmentChanged = false;
    this.assignedConsultant = undefined;
    this.sendMailButtonClicked = false;
    this.setupAppointmentErrorReason = undefined;
    this.showLeadWasAlreadyAppointedDialog = false;
    this.timeslot = undefined;
    this.resetEmailContentToConsultant();
  }

  @action
  resetEmailContentToConsultant() {
    this.emailContentToConsultant = undefined;
    this.emailValidationState = new ValidationState();
  }

  @flow *setupAppointment(inquiryPublicId: string, isDelayed: boolean) {
    assert(this.timeslot, 'Timeslot is undefined in setupAppointment');
    assert(this.timeslot.date, 'Timeslot date is undefined in setupAppointment');

    const result: Awaited<ReturnType<typeof appointmentService.setupAppointmentForInquiry>> =
      yield appointmentService.setupAppointmentForInquiry({
        inquiryPublicId,
        timeslot: { ...this.timeslot, isRelevantAppointment: undefined },
        isDelayed,
      });

    if (result.errorReason) {
      this.onSetupAppointmentError(result.errorReason);
      return;
    }

    const consultantData: Awaited<ReturnType<typeof consultantCarouselService.getConsultantCarouselWithSubstitute>> =
      yield consultantCarouselService.getConsultantCarouselWithSubstitute(result.consultantId, this.timeslot.date);

    this.assignedConsultant = {
      id: consultantData.id,
      salutation: consultantData.salutation,
      name: consultantData.firstName,
    };

    if (result.isSessionExpired) {
      this.onSetupAppointmentError(SETUP_APPOINTMENT_FAILURE_REASON.SESSION_EXPIRED);
      return;
    }

    if (result.isAlreadyAppointed) {
      this.onSetupAppointmentError(SETUP_APPOINTMENT_FAILURE_REASON.ALREADY_APPOINTED);
    }

    this.isAppointmentBooked = true;
    sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_APPOINTMENT_DATA, this.getDataForStorage());
  }

  @action
  onSetupAppointmentError(errorReason?: ValueOf<typeof SETUP_APPOINTMENT_FAILURE_REASON>) {
    this.setupAppointmentErrorReason = errorReason;
  }

  getCalendar() {
    return this.calendar || getLoadingDays();
  }

  @action
  setDataFromStorage(appointmentData: AppointmentData) {
    this.isAppointmentBooked = appointmentData.isAppointmentBooked;
    this.assignedConsultant = appointmentData.assignedConsultant;
    this.sendMailButtonClicked = appointmentData.sendMailButtonClicked;
    this.timeslot = appointmentData.timeslot;
  }

  getDataForStorage() {
    return {
      isAppointmentBooked: this.isAppointmentBooked,
      assignedConsultant: this.assignedConsultant,
      sendMailButtonClicked: this.sendMailButtonClicked,
      timeslot: this.timeslot,
    };
  }

  sendMail(inquiryPublicId: string) {
    assert(this.emailContentToConsultant, 'Mail content is not defined in sendMail');
    return emailService.sendMail({ text: this.emailContentToConsultant, inquiryPublicId: inquiryPublicId });
  }

  @action
  validateEmail() {
    if (!this.emailContentToConsultant) {
      this.emailValidationState = new ValidationState(false);
    } else {
      this.emailValidationState = new ValidationState();
    }
  }

  @flow *onSendMailButtonClicked(inquiryPublicId: string) {
    this.validateEmail();
    if (!this.sendMailButtonClicked && this.emailValidationState.isValid) {
      this.sendMailButtonClicked = true;
      piwikService.trackCurrentPageEvent('SendAppointmentMail_Clicked');
      yield this.sendMail(inquiryPublicId);
      sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_APPOINTMENT_DATA, this.getDataForStorage());
    }
  }

  @action
  setAcknowledgedExpiredSession() {
    this.acknowledgedExpiredSession = true;
    sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_ACKNOWLEDGED_EXPIRED_SESSION, true);
  }

  @action
  removeAcknowledgedExpiredSession() {
    this.acknowledgedExpiredSession = false;
    sessionStorage.remove(SESSION_STORAGE_KEYS.STORAGE_ACKNOWLEDGED_EXPIRED_SESSION);
  }

  async cancelAppointment(inquiryPublicId: string) {
    try {
      await appointmentService.cancelRelevantInquiryAppointment(inquiryPublicId);
      await Router.replace(navigationService.getAppointmentLink(inquiryPublicId));
    } catch (e) {
      webAppLoggingService.error(
        `ConsultantCalendarStore: error when canceling appointment for inquiry ${inquiryPublicId}`,
        e,
      );
    } finally {
      Router.reload();
    }
  }

  @action
  setTimeslot(timeslot: TimeSlot) {
    this.timeslot = timeslot;
    this.isAppointmentChanged = true;
  }

  isAppointmentValid() {
    return (
      validateAppointment(this.timeslot?.isRelevantAppointment, this.isAppointmentChanged) ||
      validateTimeslot(this.timeslot?.date, this.timeslot?.time)
    );
  }

  @action
  setEmailContentToConsultant(emailContentToConsultant: string) {
    this.emailContentToConsultant = emailContentToConsultant;
  }
}

export default ConsultantCalendarStore;
