import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import storeFactory from '../factories/store.factory';
import { isDUVapplicable } from '../helpers/duv.helper';
import userjourneyService from '../services/userjourney.service';

class UserjourneyStore {
  searchDataStore = storeFactory.getSearchDataStore();
  ssoStore = storeFactory.getSsoStore();
  deviceOutputStore = storeFactory.getDeviceOutputStore();

  createUserjourney() {
    if (this.ssoStore.isLoggedIn) {
      userjourneyService.createUserjourney(
        isDUVapplicable(this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION]),
        this.deviceOutputStore.deviceOutput
      );
    }
  }
}

export default UserjourneyStore;
