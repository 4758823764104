class ValidationState {
  errorMessage?: string;
  isValid: boolean;
  constructor(isValid = true, errorMessage: string|undefined = undefined) {
    this.isValid = isValid;
    this.errorMessage = errorMessage;
  }

  getErrorMessageIfAny() {
    return !this.isValid ? this.errorMessage ?? '' : '';
  }
}

export default ValidationState;
