import { isClient } from 'bu.helpers';
import { asyncLocalStorageService } from 'bu.http';

class UserAgentService {

  getUserAgent() {
    if (isClient()) {
      return navigator.userAgent;
    }
    const contextStore = asyncLocalStorageService.getStore();
    if (contextStore && contextStore.req && contextStore.req.headers) {
      return contextStore.req.headers['user-agent'] ?? '';
    }

    return '';
  }

}

export default new UserAgentService();
