import { action, makeObservable, observable } from 'mobx';
import eFeedbackService from './../services/efeedback.service';
import { CustomerRating } from '../types/customer-rating';
import { MINIMUM_COUNT_OF_COMMENTS_REQUIRED } from '../constants/efeedback.constant';

export type EFeedbackCustomerRating = {
  numberOfRatings: number;
  averageRating: number;
  providerId: number;
}

type CustomerRatingsByInsurance = Record<number, EFeedbackCustomerRating | undefined>
class EFeedbackStore {
  @observable customerRatingsByInsurance: CustomerRatingsByInsurance = {};

  constructor() {
    makeObservable(this);
  }

  async load() {
    const customerRatingsByInsurer = await eFeedbackService.getCustomerRatings();
    this._updateCustomerRatingsByInsurance(customerRatingsByInsurer);
  }

  @action
  _updateCustomerRatingsByInsurance(customerRatingsByInsurer: CustomerRating[]) {
    this.customerRatingsByInsurance = customerRatingsByInsurer.reduce<CustomerRatingsByInsurance>(
      (accumulator, insurerRating) => {
        accumulator[insurerRating._id] = this.getCustomerRatingsForInsurance(insurerRating);
        return accumulator;
      }, {});
  }

  getCustomerRatingsForInsurance(insurerRating: CustomerRating) {
    if (!this._shouldShowEFeedbacks(insurerRating)) {
      return undefined;
    }

    return {
      numberOfRatings: insurerRating.total_ratings,
      averageRating: insurerRating.average_rating,
      providerId: insurerRating.provider_id,
    };
  }

  _shouldShowEFeedbacks(insurerRating: CustomerRating) {
    return insurerRating?.total_ratings >= MINIMUM_COUNT_OF_COMMENTS_REQUIRED;
  }
}

export default EFeedbackStore;
