import { B2B_AD_PARTNERS, DUV_PHASE, PRODUCT,
  Product, RESULT_ZERO_TYPE, SEARCH_DATA_FIELDS, ValueOf } from 'bu.lookups';
import FEATURE_DATA from '../constants/feature.constant';
import { isDUVapplicable } from './duv.helper';
import { SearchData } from '../types/search-data';
import type { Tariff, TariffCategory, TariffFeature } from '../types/tariff';
import { EUROPA_CROSS_SELLING_TARIFF_IDS } from '../constants/europa-cross-selling.constant';

function shouldDisplayCategory(category: TariffCategory) {
  return category.features.length > 0 && !_isAllFeaturesAreHidden(category.features);
}

function _isAllFeaturesAreHidden(features: TariffFeature[]) {
  return features.every((feature) => feature.hidden);
}

function _filterByDuvPhase<TFeature extends { duvPhase?: string }>(tariff: Tariff, feature: TFeature) {
  if (tariff?.resultZeroType === RESULT_ZERO_TYPE.DUV_BEAMTE) {
    return feature.duvPhase === DUV_PHASE.CAREER_PHASE;
  }
  if (tariff?.resultZeroType === RESULT_ZERO_TYPE.DUV_BEAMTEN_ANWAERTER) {
    return feature.duvPhase === DUV_PHASE.ENTRY_PHASE;
  }
  return true;
}

function filterDataIfDuv(tariff: Tariff) {
  if (tariff.product === PRODUCT.DUV) {
    return {
      usps: tariff.usps.filter((usp) => _filterByDuvPhase(tariff, usp)),
      labels: tariff.labels.filter((label) => _filterByDuvPhase(tariff, label)),
      customerRatings: tariff.customerRatings
    };
  }
  return tariff;
}

function filterTariffIfEuropaCrossSellingEnabled(tariffs: Tariff[], b2BAdPartner: string|null) {
  if (b2BAdPartner !== B2B_AD_PARTNERS.EUROPA) {
    return tariffs.filter(tariff => !EUROPA_CROSS_SELLING_TARIFF_IDS.includes(Number(tariff._id)));
  }

  return tariffs;
}

export function getTariffListQueryVariables(searchData: Partial<SearchData>): Partial<SearchData> {
  const defaultVariables = {
    [SEARCH_DATA_FIELDS.OCCUPATION]: searchData[SEARCH_DATA_FIELDS.OCCUPATION],
    [SEARCH_DATA_FIELDS.FAMILY_STATUS]: searchData[SEARCH_DATA_FIELDS.FAMILY_STATUS],
    [SEARCH_DATA_FIELDS.BIRTHDAY]: searchData[SEARCH_DATA_FIELDS.BIRTHDAY],
    [SEARCH_DATA_FIELDS.EDUCATION_TYPE]: searchData[SEARCH_DATA_FIELDS.EDUCATION_TYPE],
    [SEARCH_DATA_FIELDS.JOB_SITUATION]: searchData[SEARCH_DATA_FIELDS.JOB_SITUATION],
    [SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT]: searchData[SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT],
    [SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]: Number(searchData[SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]),
    [SEARCH_DATA_FIELDS.FRACTION_OFFICE_WORK]: searchData[SEARCH_DATA_FIELDS.FRACTION_OFFICE_WORK],
    [SEARCH_DATA_FIELDS.INDUSTRY]: searchData[SEARCH_DATA_FIELDS.INDUSTRY],
    [SEARCH_DATA_FIELDS.STAFF_RESPONSIBILITY]: searchData[SEARCH_DATA_FIELDS.STAFF_RESPONSIBILITY],
    [SEARCH_DATA_FIELDS.SMOKER]: searchData[SEARCH_DATA_FIELDS.SMOKER] || false,
    salutation: FEATURE_DATA.SALUTATION.HERR,
    [SEARCH_DATA_FIELDS.INSURANCE_START]: searchData[SEARCH_DATA_FIELDS.INSURANCE_START],
    [SEARCH_DATA_FIELDS.EXCELLENT_INCREASE_OPTION_WITH_REASON_DESIRED]:
      searchData[SEARCH_DATA_FIELDS.EXCELLENT_INCREASE_OPTION_WITH_REASON_DESIRED],
    [SEARCH_DATA_FIELDS.EXCELLENT_SUSTAINABILITY_DESIRED]:
      searchData[SEARCH_DATA_FIELDS.EXCELLENT_SUSTAINABILITY_DESIRED],
    [SEARCH_DATA_FIELDS.EXCELLENT_STIFTUNG_WARENTEST]: searchData[SEARCH_DATA_FIELDS.EXCELLENT_STIFTUNG_WARENTEST],
    [SEARCH_DATA_FIELDS.EXCELLENT_CERTIFIED_SICKNESS]: searchData[SEARCH_DATA_FIELDS.EXCELLENT_CERTIFIED_SICKNESS],
    [SEARCH_DATA_FIELDS.QUICK_BENEFIT_FOR_DREAD_DISEASE]:
      searchData[SEARCH_DATA_FIELDS.QUICK_BENEFIT_FOR_DREAD_DISEASE],
    [SEARCH_DATA_FIELDS.TARIFF_GRADE_FILTER]: searchData[SEARCH_DATA_FIELDS.TARIFF_GRADE_FILTER] || undefined,
    [SEARCH_DATA_FIELDS.DYNAMIC_PREMIUM_FILTER]: searchData[SEARCH_DATA_FIELDS.DYNAMIC_PREMIUM_FILTER] || undefined,
    [SEARCH_DATA_FIELDS.NON_SMOKER_SINCE]: searchData[SEARCH_DATA_FIELDS.NON_SMOKER_SINCE] || undefined,
    [SEARCH_DATA_FIELDS.INSURANCE_FILTERS]: searchData[SEARCH_DATA_FIELDS.INSURANCE_FILTERS],
    [SEARCH_DATA_FIELDS.B2B_AD_PARTNER]: searchData[SEARCH_DATA_FIELDS.B2B_AD_PARTNER] || undefined,
    useFormulas: searchData.useFormulas
  };

  const publicServantVariables = {
    [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION]:
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION] || undefined,
    [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP]:
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP] || undefined,
    [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE]:
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE] || undefined,
    [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS]:
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS] || 0,
    [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]:
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE] || undefined,
    [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]:
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE] || undefined,
  };

  if (isDUVapplicable(searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])) {
    return { ...defaultVariables, ...publicServantVariables };
  }

  return defaultVariables;
}

export function isResultZero({ isAnyInsuranceFilterApplied, resultZeroType, product }: {
  isAnyInsuranceFilterApplied: boolean;
  resultZeroType: ValueOf<typeof RESULT_ZERO_TYPE>|'';
  product: Product;
}) {
  return resultZeroType && (!isAnyInsuranceFilterApplied || product === PRODUCT.DUV);
}

export function calculateVersicherungPlusPointsByMonthlyNetPrice(monthlyNetPrice: number): number {
  return Math.floor((12 * monthlyNetPrice) / 10);
}

export {
  shouldDisplayCategory,
  filterDataIfDuv,
  filterTariffIfEuropaCrossSellingEnabled
};
