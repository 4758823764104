import mergeWith from 'lodash/mergeWith';
import { ValueOf } from 'bu.lookups';
import { SearchData } from '../types/search-data';
import { SsoUserData } from '../types/sso-user-data';
import { createDefaultPersonalData } from '../factories/personalData.factory';
import { PersonalData } from '../types/personal-data';

function _ignoreNullValues(target: ValueOf<SearchData>, source: ValueOf<SsoUserData>) {
  if (source === null) {
    return target;
  }
  // if undefined is returned, lodash falls back to default merge strategy
  // see https://lodash.com/docs/4.17.11#mergeWith
  return undefined;
}

function createPersonalData(searchData: SearchData, ssoUserData: SsoUserData|undefined) {
  let personalData: PersonalData = createDefaultPersonalData();

  if (ssoUserData) {
    personalData = mergeWith(personalData, ssoUserData, _ignoreNullValues);
    personalData.phoneNumber = ssoUserData.phoneNumber || ssoUserData.loginPhoneNumber || '';
    delete personalData.loginPhoneNumber;
  }
  personalData.birthday = searchData.birthday;

  return personalData;
}

export {
  createPersonalData
};
