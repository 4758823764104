import urlParse from 'url-parse';
import { isClient } from 'bu.helpers';
import { asyncLocalStorageService } from 'bu.http';

import configService from './config.service';
import { convertQueryToStringsOnlyQuery } from '../helpers/convert-query.helper';
import { StringsOnlyQuery } from '../types/strings-only-query';

class CurrentUrlService {

  getCurrentUrlQueryParams(): StringsOnlyQuery {
    if (isClient()) {
      return convertQueryToStringsOnlyQuery(urlParse(window.location.href, true).query);
    }

    const contextStore = asyncLocalStorageService.getStore();
    return contextStore!.query ?? {};
  }

  getCurrentUrl(): string {
    if (isClient()) {
      return window.location.href;
    }

    const host = configService.getConfig().host;
    const contextStore = asyncLocalStorageService.getStore();
    return `${host}${contextStore!.pathWithSearch}`;
  }
}

export default new CurrentUrlService();
