export const INVALID_BIRTHDAY = {
  EMPTY: 'Bitte geben Sie Ihr Geburtsdatum an',
  INVALID: 'Bitte geben Sie ein gültiges Geburtsdatum an',
  MAX_AGE_VIOLATED: 'Bitte geben Sie ein Höchstalter von 65 Jahren an',
  MIN_AGE_VIOLATED: 'Bitte geben Sie ein Mindestalter von 10 Jahren an',
} as const;

export const INVALID_FAMILY_STATUS = {
  INVALID: 'Bitte geben Sie Ihre Familiensituation an'
} as const;

export const INVALID_JOB_SITUATION = {
  NOT_SELECTED: 'Bitte geben Sie Ihre berufliche Situation an',
} as const;

export const INVALID_OCCUPATION = {
  EMPTY: 'Bitte geben Sie Ihren Beruf an',
  NOT_IN_THE_LIST:
    'Leider finden wir keinen Beruf zu Ihrer Eingabe. Bitte versuchen Sie einen kürzeren oder ähnlichen Begriff'
} as const;

export const INVALID_EDUCATION_TYPE = {
  EMPTY: 'Bitte geben Sie Ihren höchsten Bildungsabschluss an'
} as const;

export const INVALID_BENEFIT_AMOUNT = {
  UNDER_LIMIT: 'Bitte geben Sie mindestens 100 € an'
} as const;

export const INVALID_BENEFIT_AGE_LIMIT = {
  EMPTY: 'Bitte geben Sie an, bis zu welchem Alter Sie sich absichern wollen.'
} as const;

export const PERSONAL_DATA = {
  SALUTATION_REQUIRED: 'Bitte wählen Sie eine Anrede',
  FIRSTNAME_REQUIRED: 'Bitte geben Sie Ihren Vornamen an',
  LASTNAME_REQUIRED: 'Bitte geben Sie Ihren Nachnamen an',
  PHONENUMBER_REQUIRED: 'Für die Angebotsbesprechung benötigen wir Ihre Telefonnummer',
  PHONENUMBER_INVALID: 'Bitte geben Sie eine gültige Mobilnummer an',
  EMAIL_REQUIRED: 'Für die Zusendung des Angebots und für eventuelle Rückfragen benötigen wir Ihre E-Mail-Adresse',
  EMAIL_INVALID: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
  EMAIL_ASSIGNED_TO_A_SUPERACCOUNT: 'So wie es aussieht, sind Sie bereits unter dieser Adresse registriert. Bitte melden Sie sich mit dieser an oder wählen Sie eine andere E-Mail-Adresse.', // eslint-disable-line max-len
  POSTALCODE_REQUIRED: 'Bitte geben Sie Ihre Postleitzahl an',
  POSTALCODE_INVALID: 'Bitte geben Sie eine gültige Postleitzahl an',
  CITY_REQUIRED: 'Bitte wählen Sie einen Ort',
  STREET_REQUIRED: 'Bitte wählen Sie eine Straße',
  HOUSENUMBER_REQUIRED: 'Bitte geben Sie Ihre Hausnummer an',
  AGREEMENT_REQUIRED: 'Ohne Ihre Zustimmung können wir Ihre Anfrage leider nicht weiter bearbeiten. Bitte stimmen Sie zu, dass wir Ihre Gesundheitsdaten erheben und verarbeiten dürfen' // eslint-disable-line max-len
} as const;

export const SSO_LOGIN = {
  PASSWORD_REQUIRED: 'Bitte geben Sie Ihr Passwort an',
  EMAIL_REQUIRED: 'Bitte geben Sie Ihre E-Mail-Adresse an',
  MOBILE_PHONE_NUMBER_REQUIRED: 'Bitte geben Sie Ihre Mobiltelefonnummer an',
  EMAIL_INVALID: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
  MOBILE_PHONE_NUMBER_INVALID: 'Bitte geben Sie eine gültige Mobilnummer an',
  WRONG_LOGIN_EMAIL_OR_PASSWORD: 'E-Mail-Adresse oder Passwort ist nicht korrekt',
  WRONG_LOGIN_PHONE_NUMBER_OR_PASSWORD: 'Mobiltelefonnummer oder Passwort ist nicht korrekt',
  USER_BLOCKED: 'Der Account ist gesperrt. Bitte wenden Sie sich an den Kundendienst',
  UNKNOWN_FAILURE: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut', // eslint-disable-line max-len
} as const;

export const SSO_REGISTER = {
  EMAIL_REQUIRED: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  EMAIL_INVALID: 'Ungültige E-Mail-Adresse',
  PASSWORD_REQUIRED: 'Bitte geben Sie Ihr Passwort ein',
  PASSWORD_LENGTH_INVALID: 'Das Passwort muss eine Länge von 6-50 Zeichen haben',
  PASSWORDS_DONT_MATCH: 'Die Passwörter stimmen nicht überein',
  USER_EXISTS: (email: string) => `Es existiert bereits ein Kundenkonto mit der E-Mail-Adresse ${email}`
} as const;

export const PASSWORD_RECOVERY = {
  EMAIL_REQUIRED: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  EMAIL_INVALID: 'Die eingegebene E-Mail-Adresse ist ungültig',
  USER_NOT_FOUND: 'Es wurde kein passendes Benutzerkonto gefunden. Bitte überprüfen Sie Ihre Eingabe'
} as const;

export const PUBLIC_SERVANT_EXPERIENCE_YEARS = {
  EMPTY: 'Bitte geben Sie Ihre Erfahrungsjahre an',
  INVALID: 'Bitte geben Sie Ihre Erfahrung in Jahren an!'
} as const;

export const PUBLIC_SERVANT_JOB_SITUATION = {
  EMPTY: 'Bitte geben Sie die Art Ihres Beamtenverhältnisses an',
} as const;

export const PUBLIC_SERVANT_CAREER_GROUP = {
  EMPTY: 'Bitte geben Sie Ihre Laufbahngruppe an',
} as const;

export const PUBLIC_SERVANT_PAY_GRADE = {
  EMPTY: 'Bitte eine Ziffer auswählen',
} as const;

export const PUBLIC_SERVANT_ENTERED_SERVICE_DATE = {
  EMPTY: 'Bitte geben Sie das Datum Ihres Diensteintritts an',
  INVALID: 'Bitte geben Sie ein gültiges Datum ein',
} as const;

export const PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE = {
  EMPTY: 'Bitte geben Sie das (erwartete) Datum Ihrer Ernennung zum Beamten auf Lebenszeit an',
  INVALID: 'Bitte geben Sie ein gültiges Datum ein',
  OVER_PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VIOLATED:
  'Bitte geben Sie ein gültiges Datum ein. Der Diensteintritt muss vor der Verbeamtung auf Lebenszeit liegen'
} as const;
