import { action, flow, makeObservable, observable, reaction, toJS } from 'mobx';
import moment from 'moment-timezone';
import { SEARCH_DATA_FIELDS, SORT_OPTIONS, JOB_SITUATIONS, INDUSTRIES, FAMILY_STATUS, PRODUCT,
  PUBLIC_SERVANT_JOB_SITUATIONS_VALUE, SortOption } from 'bu.lookups';
import { DATE_FORMAT, date } from 'bu.formatters';
import { isClient, isNullishOrEmpty } from 'bu.helpers';
import {
  getSearchDataWithUnselectedFilters,
  publicServantFields,
  isMoreThanOneFilterSelectedHelper
} from '../helpers/search-data.helper';
import navigationService from '../services/navigation.service';
import storeFactory from './../factories/store.factory';
import sortOptionsFactory from './../factories/sort-options.factory';
import { SMOKER_STATUS_MAP } from '../constants/smoker-status-map.constant';
import { calculateRecommendedAmount } from '../helpers/recommendation-hint.helper';
import { SsoUserData } from '../types/sso-user-data';
import { MaybeSearchDataTypes, SearchData } from '../types/search-data';
import searchDataFactory from '../factories/search-data.factory';

export default class SearchDataStore {
  @observable searchData: SearchData = searchDataFactory.createDefaultSearchData();
  @observable inited = false;
  @observable currentSelectedSortingOption?: SortOption;
  @observable defaultSorting: SortOption = SORT_OPTIONS.RESULT_ZERO;
  defaults = {};
  manuallySetByUserSortOption?: SortOption;
  searchDataSyncDisposeFunc?: ReturnType<typeof reaction>;
  @observable netIncome = 0;
  insuranceFiltersStore = storeFactory.getInsuranceFiltersStore();
  tariffListStore = storeFactory.getTariffListStore();

  constructor() {
    makeObservable(this);
    this.addSearchDataReactionForField(
      SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE, () => this.#insuranceStartUpdateReaction());
    this.addSearchDataReactionForField(SEARCH_DATA_FIELDS.JOB_SITUATION, () => this.#publicServantReaction());
    this.publicServantEnteredServiceDateReaction();
    if (isClient()) {
      window.addEventListener('sso-store-post-login', (e: CustomEvent) => this.handleSsoEvent(e));
    }
  }

  @action init(searchData: SearchData, ssoUserData: SsoUserData|undefined) {
    // VERBU-9985: "inited" field is a hacky way to prevent the useEffect in onboarding-page running multiple times.
    // Related to Single Onboarding Pages validation
    if (this.inited) {
      return;
    }

    this.searchData = searchData;
    if (!searchData.birthday && ssoUserData?.birthday) {
      this.updateSearchData({ [SEARCH_DATA_FIELDS.BIRTHDAY]: ssoUserData.birthday });
    }
    this.inited = true;
  }

  @action
  handleSsoEvent(e: CustomEvent) {
    const ssoUserData = e.detail.ssoUserData;
    if (!this.searchData.birthday && ssoUserData?.birthday) {
      const updatedSearchData = { ...this.searchData };
      updatedSearchData[SEARCH_DATA_FIELDS.BIRTHDAY] = ssoUserData.birthday;
      this.searchData = updatedSearchData;
    }
  }

  @action updateSearchData(updates: Partial<SearchData>) {
    Object.assign(this.searchData, updates);
  }

  @action unsetFields(fields: MaybeSearchDataTypes[]) {
    for (const field of fields) {
      delete this.searchData[field];
    }
  }

  isInitialVisit() {
    return isNullishOrEmpty(this.searchData.occupation) ||
      isNullishOrEmpty(this.searchData.birthday) || isNullishOrEmpty(this.searchData.educationType);
  }

  initDefaultSearchDataReactions(effect: () => void) {
    return reaction(() => [
      this.searchData.excellentIncreaseOptionWithReasonDesired,
      this.searchData.excellentSustainabilityDesired,
      this.searchData.excellentStiftungWarentest,
      this.searchData.excellentCertifiedSickness,
      this.searchData.quickBenefitForDreadDisease,
      this.searchData.dynamicPremiumFilter,
      this.searchData.tariffGradeFilter,
      this.searchData.insuranceFilters
    ], effect);
  }

  #insuranceStartUpdateReaction() {
    const value = this.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE];
    let insuranceStart = moment(value, DATE_FORMAT.ISO_8601_DATE, true);
    insuranceStart = insuranceStart.date() === 1 ?
      insuranceStart.startOf('month') : insuranceStart.add(1, 'month').startOf('month');
    const monthsDiff = moment().startOf('month').diff(insuranceStart, 'months', true);
    if (monthsDiff < 0 && monthsDiff >= -3) {
      this.updateSearchData({ [SEARCH_DATA_FIELDS.INSURANCE_START]: insuranceStart.format(DATE_FORMAT.ISO_8601_DATE) });
    } else {
      this.updateSearchData({ [SEARCH_DATA_FIELDS.INSURANCE_START]: date.getInsuranceStart() });
    }
  }

  addSearchDataReactionForField(field: keyof SearchData, effect: () => void) {
    return reaction(() => this.searchData[field], effect);
  }

  getEverySearchDataKey() {
    return toJS(this.searchData);
  }

  @action applySearchDataChanges(searchData: Partial<SearchData>) {
    Object.assign(this.searchData, searchData);
  }

  @action unselectAllFilters() {
    this.applySearchDataChanges(getSearchDataWithUnselectedFilters(this.searchData));
  }

  getAvailableSortingOptions() {
    const hasAtLeastOneResultZero = this.tariffListStore.hasAtLeastOneTariffResultZero();
    let sortOptions: readonly { value: SortOption; text: string }[] = sortOptionsFactory.sortOptions;
    if (!hasAtLeastOneResultZero || this.insuranceFiltersStore.isAnyInsuranceFilterApplied()) {
      sortOptions = sortOptions.filter((option) => option.value !== SORT_OPTIONS.RESULT_ZERO);
    }
    if (this.insuranceFiltersStore.isOnlyOneFilterApplied()) {
      sortOptions = sortOptions.filter(
        (sortOption) => sortOption.value !== SORT_OPTIONS.NAME_ASC && sortOption.value !== SORT_OPTIONS.NAME_DESC
      );
    }
    return sortOptions;
  }

  anyFilterIsApplied() {
    return (
      this.searchData.excellentIncreaseOptionWithReasonDesired || this.searchData.excellentSustainabilityDesired ||
      this.searchData.excellentStiftungWarentest || this.searchData.excellentCertifiedSickness ||
      this.searchData.quickBenefitForDreadDisease ||
      this.searchData.tariffGradeFilter !== '' || this.searchData.dynamicPremiumFilter !== ''
    );
  }

  isMoreThanOneFilterSelected() {
    return isMoreThanOneFilterSelectedHelper(this.searchData);
  }

  createQueryStringSyncReaction() {
    this.searchDataSyncDisposeFunc = reaction(() => this.getEverySearchDataKey(),
      () => navigationService.updateQueryFromCurrentSearchData()
    );
  }

  stopQueryStringSyncReaction() {
    if (this.searchDataSyncDisposeFunc) {
      this.searchDataSyncDisposeFunc();
    }
  }

  @action setSortingOption() {
    if (this.manuallySetByUserSortOption && this._isSortOptionAvailable(this.manuallySetByUserSortOption)) {
      this.currentSelectedSortingOption = this.manuallySetByUserSortOption;
      this.searchData.sortedBy = this.manuallySetByUserSortOption;
      return;
    }
    this.currentSelectedSortingOption = this.searchData.sortedBy;
  }

  @action setDefaultSorting(hasAtLeastOneResultZero: boolean, amountOfAppliedInsuranceFilters: number) {
    this.defaultSorting = (hasAtLeastOneResultZero && !amountOfAppliedInsuranceFilters) ?
      SORT_OPTIONS.RESULT_ZERO : SORT_OPTIONS.NET_PRICE;
    if (this.searchData.sortedBy === SORT_OPTIONS.RESULT_ZERO &&
      (!hasAtLeastOneResultZero || amountOfAppliedInsuranceFilters)) {
      this.searchData.sortedBy = SORT_OPTIONS.NET_PRICE;
    }
    if (amountOfAppliedInsuranceFilters === 1 &&
      this.searchData.sortedBy !== SORT_OPTIONS.NET_PRICE && this.searchData.sortedBy !== SORT_OPTIONS.GRADE) {
      this.searchData.sortedBy = SORT_OPTIONS.NET_PRICE;
    }
  }

  isSortingOptionDefaultSelected() {
    return this.currentSelectedSortingOption === this.defaultSorting;
  }

  _isSortOptionAvailable(sortOptionValue: SortOption) {
    return this.getAvailableSortingOptions().map((option) => option.value).includes(sortOptionValue);
  }

  @action setSmokerStatus(smokerSelectValue: typeof SMOKER_STATUS_MAP[number]['status']) {
    const smokerStatus = SMOKER_STATUS_MAP.find(v => v.status === smokerSelectValue);
    if (smokerStatus) {
      this.searchData.smoker = smokerStatus.value.smoker;
      this.searchData.nonSmokerSince = smokerStatus.value.nonSmokerSince;
      return true;
    }
    return false;
  }

  @flow *loadTariffsAndFilteredInsurances() {
    yield this.tariffListStore.loadOrWaitForTariffList(this.searchData);
    if (this.tariffListStore.tariffs?.length) {
      yield this.insuranceFiltersStore.loadFilteredInsurances(this.searchData);
      this.setSorting();
    }
  }

  setSorting() {
    this.setDefaultSorting(
      this.tariffListStore.hasAtLeastOneTariffResultZero(), this.insuranceFiltersStore.getAmountOfAppliedFilters()
    );
    this.setSortingOption();
  }

  @action setNetIncome(netIncome: number) {
    this.netIncome = netIncome;
  }

  isBenefitAmountHigher() {
    return this.netIncome && calculateRecommendedAmount(this.netIncome) <= Number(this.searchData.benefitAmount);
  }

  setDefaults(defaults: Partial<SearchData>) {
    this.defaults = defaults;
  }

  @action updateEmptySearchDataFieldsWithDefaults() {
    this.searchData = { ...this.defaults, ...this.searchData };
  }

  #publicServantReaction() {
    if (this.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION] === JOB_SITUATIONS.BEAMTET) {
      this.updateSearchData({
        [SEARCH_DATA_FIELDS.INDUSTRY]: INDUSTRIES.SONSTIGE_BRANCHE,
        [SEARCH_DATA_FIELDS.FAMILY_STATUS]: FAMILY_STATUS.KEINE_ANGABE
      });
      return;
    }
    this.unsetFields(publicServantFields);
  }

  isSortingOrFilteringApplied() {
    return Boolean(this.searchData.sortedBy !== this.defaultSorting
      || this.anyFilterIsApplied() || this.searchData.insuranceFilters?.length);
  }

  getMinPrice() {
    return this.isSortingOrFilteringApplied() ? this.tariffListStore.getMinPriceOfTariffsNonDuv()
      : this.tariffListStore.getMinPriceOfTariffs();
  }

  getMaxPrice() {
    return this.isSortingOrFilteringApplied() ? this.tariffListStore.getMaxPriceOfTariffsNonDuv()
      : this.tariffListStore.getMaxPriceOfTariffs();
  }

  getTariffCount() {
    return this.isSortingOrFilteringApplied()
      ? this.tariffListStore.tariffs!.filter((tariff) => tariff.product !== PRODUCT.DUV).length
      : this.tariffListStore.tariffs!.length;
  }

  @action updatePublicServantAppointmentDate() {
    const publicServantJobSituation = this.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION];
    if (publicServantJobSituation === PUBLIC_SERVANT_JOB_SITUATIONS_VALUE.BEAMTET_AUF_WIDERRUF) {
      const momentDate = moment(this.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE],
        DATE_FORMAT.ISO_8601_DATE,
        true);
      if (momentDate.isValid()) {
        this.updateSearchData({
          [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]:
            momentDate.add(6, 'years').startOf('month').format(DATE_FORMAT.ISO_8601_DATE)
        });
      } else {
        this.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]: '' });
      }
    }
  }

  publicServantEnteredServiceDateReaction() {
    return reaction(
      () => [
        this.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE],
        this.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION]
      ], () => {
        this.updatePublicServantAppointmentDate();
      }
    );
  }
}
