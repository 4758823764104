import moment from 'moment-timezone';
import { date } from 'bu.formatters';
import { Mobile } from 'bu.components-customer-apps';
import { RelevantAppointment } from '../types/relevant-appointment';
import { TimeSlot } from '../types/timeslot';

export function getLoadingDays() : Mobile.CalendarCarouselAppointmentDate[] {
  return date.enumerateWorkDaysAsISO(5).map((d) => ({
    date: d,
    timeslots: [
      { time: '08:00', consultants: [] },
      { time: '09:00', consultants: [] },
      { time: '10:00', consultants: [] },
      { time: '11:00', consultants: [] },
      { time: '12:00', consultants: [] },
      { time: '13:00', consultants: [] },
      { time: '14:00', consultants: [] },
      { time: '15:00', consultants: [] },
      { time: '16:00', consultants: [] },
      { time: '17:00', consultants: [] },
      { time: '18:00', consultants: [] },
      { time: '19:00', consultants: [] },
      { time: '20:00', consultants: [] },
      { time: '21:00', consultants: [] }
    ]
  }));
}

export function validateAppointment(
  isRelevantAppointment: boolean|undefined, isAppointmentChanged: boolean|undefined
) {
  if (!isAppointmentChanged && isRelevantAppointment) {
    return 'Dies ist Ihr aktueller Termin.' +
    ' Um Ihren Termin zu ändern, wählen Sie bitte einen anderen Zeitpunkt im Kalender aus';
  }

  return '';
}

export function validateTimeslot(day: string|undefined, time: string|undefined) {
  if (!day) {
    return 'Bitte wählen Sie einen Termin im Kalender aus';
  }

  if (!time) {
    return 'Bitte wählen Sie eine Uhrzeit aus';
  }

  return '';
}

export function buildTimeslotFromRelevantAppointment(relevantAppointment: RelevantAppointment, consultantId: number):
  TimeSlot {
  const dateTime = `${relevantAppointment.date} ${relevantAppointment.from}`;

  return {
    date: moment(dateTime).isBefore(moment()) ? '' : relevantAppointment.date,
    time: moment(dateTime).isBefore(moment()) ? undefined : relevantAppointment.from,
    consultants: [{ _id: consultantId }],
    isRelevantAppointment:
      !moment(dateTime).isBefore(moment()) && relevantAppointment.isAppointed
  };
}
