import http from 'bu.http';
import { webAppLoggingService } from 'bu.logger-client';
import configService from './../services/config.service';
import { SearchData, PrefillOnlyParams, PrefillAndIpssParams } from '../types/search-data';

export type PrefillingSearchData = Omit<Partial<SearchData>, 'insuranceFilters'> & { insuranceFilters?: unknown };

class BpmService {
  mobileApi: string;

  constructor() {
    this.mobileApi = configService.getConfig().services.mobileApi;
  }

  savePrefillingAndIpss(payload: PrefillOnlyParams | PrefillAndIpssParams) {
    return http.post(this.mobileApi, '/bpm/prefilling-ipss', payload);
  }

  async savePrefilling(searchData: SearchData) {
    if (!searchData.occupation) {
      webAppLoggingService.warn('No occupation set in SearchData, prefilling for BPM could not be saved');
      return;
    }
    await http.post(this.mobileApi, '/bpm/prefilling', searchData);
  }

  getPrefilling() {
    return http.get<PrefillingSearchData>(this.mobileApi, '/bpm/prefilling');
  }
}

export default new BpmService();
