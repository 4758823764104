import { action, flow, makeObservable, observable } from 'mobx';
import { isClient } from 'bu.helpers';

import { assertIsValidPostalCode } from '../helpers/validation.helper';
import addressService from './../services/address.service';
import storeFactory from '../factories/store.factory';
import { PersonalData } from '../types/personal-data';
import { Option } from '../types/option';
import { createPersonalData } from '../helpers/personal-data.helper';
import { removePersonalDataFromStorage } from '../helpers/personal-data-storage.helper';

import { createDefaultPersonalData } from '../factories/personalData.factory';

const DEFAULT_CITIES_OPTIONS: Option<string>[] = [{ value: '', text: 'Ort erst nach Postleitzahl' }];

class PersonalDataStore {
  searchDataStore = storeFactory.getSearchDataStore();

  @observable cities: Option<string>[] = DEFAULT_CITIES_OPTIONS;
  @observable streets: string[] = [];

  @observable bestSsoMode = false;
  @observable doNotSendInquiryNotificationEmail = true;
  @observable personalData: PersonalData = createDefaultPersonalData();
  @observable isPersonalDataSubmitting = false;
  requestedVersicherungPlusMembership = false;

  constructor() {
    makeObservable(this);
    if (isClient()) {
      window.addEventListener('sso-store-post-login', (e: CustomEvent) => this.handleSsoEvent(e));
      window.addEventListener('logout-custom-event', () => this.handleLogoutCustomEvent());
    }
  }

  async handleSsoEvent(e: CustomEvent) {
    const ssoUserData = e.detail.ssoUserData;
    if (ssoUserData) {
      const personalData = createPersonalData(this.searchDataStore.searchData, ssoUserData);
      await this.setPersonalData(personalData);
      const bestSsoMode = await storeFactory.getPersonalDataValidationStore().validateCanShowBestSso();
      this.setBestSsoMode(bestSsoMode);
    }
    removePersonalDataFromStorage();
  }

  setRequestedVersicherungPlusMembership(requestedVersicherungPlusMembership: boolean) {
    this.requestedVersicherungPlusMembership = requestedVersicherungPlusMembership;
  }

  @action setIsPersonalDataSubmitting(isPersonalDataSubmitting: boolean) {
    this.isPersonalDataSubmitting = isPersonalDataSubmitting;
  }

  @action
  handleLogoutCustomEvent() {
    this.personalData = createPersonalData(this.searchDataStore.searchData, undefined);
    removePersonalDataFromStorage();
    this.bestSsoMode = false;
  }

  @action
  setBestSsoMode(bestSsoMode: boolean) {
    this.bestSsoMode = bestSsoMode;
  }

  @action
  updatePersonalDataAddress(partialAddress = {}) {
    this.personalData.address = { ...this.personalData.address, ...partialAddress };
  }

  @flow *setPersonalData(personalData: PersonalData) {
    this.personalData = personalData;
    if (this.personalData.address) {
      yield this.verifyPostalCode(this.personalData.address.postalCode);
      if (personalData.address.city && this.isCityIncludedInAvailableCities(personalData.address.city)) {
        this.personalData.address.city = personalData.address.city;
      }
    }
  }

  @action
  patchPersonalDataChanges(changes: Partial<PersonalData>) {
    this.personalData = { ...this.personalData, ...changes };
  }

  isCityIncludedInAvailableCities(city: string) {
    return !!(this.cities.find(c => c.value.toLowerCase() === city.toLowerCase()));
  }

  @flow *verifyPostalCode(postalCode: string) {
    if (assertIsValidPostalCode(postalCode).isValid) {
      const { cities, streets }: Awaited<ReturnType<typeof addressService.getAddresses>>
        = yield addressService.getAddresses(postalCode);

      if (cities.length === 0) {
        this._setCityDefaultValue();
        return;
      }

      this.streets = streets;
      this.cities = cities.map((value) => ({
        value,
        text: value
      }));

      if (this.cities.length !== 1) {
        this.cities.unshift({ value: '', text: 'Bitte wählen' });
      }

      this.updatePersonalDataAddress({ city: this.cities[0].value });
      return;
    }

    this._setCityDefaultValue();
  }

  @action
  _setCityDefaultValue() {
    this.cities = DEFAULT_CITIES_OPTIONS;
    this.streets = [];

    this.updatePersonalDataAddress({
      city: this.cities[0].value,
      street: ''
    });
  }

  hasOnlyOneCity() {
    return this.cities.length === 1;
  }
}

export default PersonalDataStore;

