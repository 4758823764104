import http from 'bu.http';
import { webAppLoggingService } from 'bu.logger-client';
import { getCookie } from 'cookies-next';
import configService from './config.service';
import { StringsOnlyQuery } from '../types/strings-only-query';
import { NextRequestType } from '../types/next-types';
import { WPSET_COOKIE } from '../constants/cookies.constant';

class B2bPartnerService {

  async getB2BPartnerInfo(query: StringsOnlyQuery, req: NextRequestType | undefined) {
    let wpSet = {
      b2BAdPartner: query.wp2 || this.getDefaultWpSet().b2BAdPartner,
      b2BPartner: query.wp1
    };

    if (query.wp1 || query.wp2) {
      return wpSet;
    }

    const wpSetKey = this.readWpSetKey(query, req);
    wpSet = await this._readWpSetFromKey(wpSetKey);
    return wpSet;
  }

  getDefaultWpSet() {
    return {
      b2BPartner: 'check24',
      b2BAdPartner: 'checkvers'
    };
  }

  readWpSetKey(query: StringsOnlyQuery, req: NextRequestType | undefined) {
    if (query.wpset) {
      return query.wpset;
    }

    return getCookie(WPSET_COOKIE, { req });
  }

  async _readWpSetFromKey(wpSetKey: string|undefined) {
    const clientConfig = configService.getConfig();
    const defaultWpSet = this.getDefaultWpSet();

    if (wpSetKey) {
      try {
        const retrievedWpSet =
          await http.get<{
            b2BPartner: string;
            b2BAdPartner: string;
          }|undefined>(
            clientConfig.services.mobileApi, `/b2bpartner/${wpSetKey}`, { timeout: 1000 }
          );
        return retrievedWpSet || defaultWpSet;
      } catch (e) {
        webAppLoggingService.warn(`could not retrieve wpset for ${wpSetKey}`, e);
      }
    }

    return defaultWpSet;
  }
}

export default new B2bPartnerService();
