export const PAYGRADE_MAP = {
  A: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'],
  B: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  R: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  W: ['1', '2', '3']
} as const;

export type Paygrade = (`A${(typeof PAYGRADE_MAP)['A'][number]}`) | (`B${(typeof PAYGRADE_MAP)['B'][number]}`)
  | (`R${(typeof PAYGRADE_MAP)['R'][number]}`) | (`W${(typeof PAYGRADE_MAP)['W'][number]}`);

export const PAYGRADES: Paygrade[] = Object.entries(PAYGRADE_MAP)
  .flatMap(([letter, numbers]) => numbers.map((number) => `${letter}${number}`)) as Paygrade[];
