import http from 'bu.http';
import { StatusCodes } from 'http-status-codes';

import configService from './config.service';
import { WishlistItemPayload } from '../types/wishlist';
import { TariffId } from '../types/tariff-id';

class WishlistService {
  mobileApiUrl: string;

  constructor() {
    const config = configService.getConfig();
    this.mobileApiUrl = config.services.mobileApi;
  }

  async getWishlistTariffIdsOfCurrentUser(): Promise<TariffId[]> {
    try {
      return await http.get(this.mobileApiUrl, '/wishlist');
    } catch (error) {
      if (error.status === StatusCodes.UNAUTHORIZED) {
        return [];
      }
      throw error;
    }
  }

  async createWishlistItem(payload: WishlistItemPayload) {
    try {
      
      await http.post(this.mobileApiUrl, '/wishlist-item', payload);
      return true;
    } catch (error) {
      if (error.status === StatusCodes.UNAUTHORIZED) {
        return false;
      }
      throw error;
    }
  }

  async deleteWishlistItem(tariffId: TariffId) {
    const query = new URLSearchParams({ tariffId: tariffId.toString() });

    try {
      await http.delete(this.mobileApiUrl, `/wishlist-item?${query}`);
      return true;
    } catch (error) {
      if (error.status === StatusCodes.UNAUTHORIZED) {
        return false;
      }
      throw error;
    }
  }
}

export default new WishlistService();
