import { ParsedUrlQuery } from 'querystring';

// converts node.js ParsedUrlQuery object returned by Next, which can additionally have undefined
// or string arrays as values to an object with only strings as values, which is required by
// URLSearchParams. Also works with Record<string, unknown>.
export function convertQueryToStringsOnlyQuery(query: ParsedUrlQuery|Record<string, unknown>) {
  return Object.entries(query).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value === undefined) {
      return acc;
    }
    if (Array.isArray(value)) {
      acc[key] = value[value.length - 1];
    } else {
      acc[key] = String(value);
    }
    return acc;
  }, {});
}