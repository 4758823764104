import { isClient } from 'bu.helpers';
import { TariffId } from '../types/tariff-id';
import storeFactory from '../factories/store.factory';
import navigationService from '../services/navigation.service';

class TariffNavigationStore {
  #tariffIdToNavigateTo: TariffId | undefined;

  deviceOutputStore = storeFactory.getDeviceOutputStore();
  ssoStore = storeFactory.getSsoStore();

  constructor() {
    if (isClient()) {
      window.addEventListener('sso-store-post-login', () => this.handleSsoStoreLoginEvent());
    }
  }

  setTariffIdToNavigateTo(tariffId: TariffId | undefined) {
    this.#tariffIdToNavigateTo = tariffId;
  }

  getTariffIdToNavigateTo(): TariffId | undefined {
    return this.#tariffIdToNavigateTo;
  }
  
  async handleSsoStoreLoginEvent() {
    if (this.#tariffIdToNavigateTo) {
      await navigationService.navigate(navigationService.getPersonalDataLink(this.#tariffIdToNavigateTo));
      this.setTariffIdToNavigateTo(undefined);
    }
  }

  navigateToPersonalLoginPage(tariffId: TariffId, pointsAmount: string) {
    this.setTariffIdToNavigateTo(tariffId);
    if (this.deviceOutputStore.isApp()) {
      if (this.ssoStore.isLoggedIn && this.ssoStore.inCheck24PointsProgram) {
        return navigationService.navigate(navigationService.getPersonalDataLink(tariffId));
      }
      return this.ssoStore.openUnifiedLoginLayer(pointsAmount);
    } 
    return navigationService.navigate(navigationService.getPersonalLoginLink(this.#tariffIdToNavigateTo));
  }
}

export default TariffNavigationStore;