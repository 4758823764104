const CUSTOM_VARIABLES_PAGE = {
  1: 'occupation',
  2: 'isUserLoggedIn',
  3: 'birthday',
  4: 'educationType',
  5: 'jobSituation',
  6: 'benefitAgeLimit',
  7: 'benefitAmount',
  8: 'fractionOfficeWork',
  9: 'industry',
  10: 'staffResponsibility',
  11: 'familyStatus',
  12: 'smoker',
  13: 'excellentIncreaseOptionWithReasonDesired',
  15: 'dynamicPremiumFilter',
  16: 'sortedBy',
  17: 'excellentStiftungWarentest',
  18: 'tariffGradeFilter',
  19: 'excellentCertifiedSickness',
  20: 'duvVariables'
} as const;

export default CUSTOM_VARIABLES_PAGE;
