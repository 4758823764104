import { action, makeObservable, observable } from 'mobx';

class MinPricePerFilterStore {
  @observable minPricesPerFilter: Record<string, number|null> = {};

  constructor() {
    makeObservable(this);
  }

  @action setMinPricePerFeatureFilter(minPrices: Record<string, number|null>) {
    this.minPricesPerFilter = minPrices;
  }
}

export default MinPricePerFilterStore;
