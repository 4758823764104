import { isClient } from 'bu.helpers';
import CUSTOM_VARIABLES_VISIT from '../constants/custom-variables-visit.constant';
import CUSTOM_VARIABLES_PAGE from '../constants/custom-variables-page.constant';
import { TrackingArray } from '../types/tracking-array';

class PiwikService {

  constructor() {
    const trackingArray = this._getTrackingArray();

    trackingArray.push(['enableLinkTracking']);
    trackingArray.push(['setCookieDomain', '*.check24.de']);
    trackingArray.push(['setDomains', ['bu.check24.de']]);
    trackingArray.push(['FormAnalytics::enableFormAnalytics']);
  }

  getPiwikVisitorId() {
    return window.visitor_id;
  }

  trackCurrentPage(customVariables: Record<string, unknown>) {
    if (!isClient()) {
      return;
    }
    const trackingArray = this._getTrackingArray();

    trackingArray.push(['setDocumentTitle', `${document.domain} / ${document.title}`]);
    trackingArray.push(['setCustomUrl', window.location.href]);
    trackingArray.push(['trackVisibleContentImpressions', true, 500]);

    this._addCustomVariables(customVariables, trackingArray);

    trackingArray.push(['enableHeartBeatTimer']);
    trackingArray.push(['trackPageView', window.location.pathname]);
  }

  trackCurrentPageEvent(action: string, name?: string, value?: string) {
    const trackingArray = this._getTrackingArray();
    trackingArray.push(['trackEvent', window.location.pathname, action, name, value]);
  }

  trackFormAnalyticsForElement(element: Element|null) {
    const trackingArray = this._getTrackingArray();
    trackingArray.push(['FormAnalytics::trackForm', element]);
    trackingArray.push(['FormAnalytics::trackFormSubmit', element]);
  }

  offeredGfBanner(offeredGF: boolean) {
    const trackingArray = this._getTrackingArray();
    this._addCustomVariables({ offeredGF }, trackingArray);
  }

  _addCustomVariables(customVariables: Record<string, unknown>, trackingArray: TrackingArray) {
    for (const [variableIndex, variableName] of Object.entries(CUSTOM_VARIABLES_VISIT)) {
      if (customVariables[variableName] === undefined) {
        continue;
      }

      trackingArray.push([
        'setCustomVariable',
        Number(variableIndex),
        variableName,
        customVariables[variableName],
        'visit'
      ]);
    }

    for (const [variableIndex, variableName] of Object.entries(CUSTOM_VARIABLES_PAGE)) {
      if (customVariables[variableName] === undefined) {
        continue;
      }
      trackingArray.push([
        'setCustomVariable',
        Number(variableIndex),
        variableName,
        customVariables[variableName],
        'page'
      ]);
    }
  }

  _getTrackingArray(): TrackingArray {
    if (!isClient()) {
      return [];
    }
    window._paq = window._paq || [];
    return window._paq;
  }
}

export default new PiwikService();
