import { date } from 'bu.formatters';
import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import { minifyState, unminifyState } from '../helpers/state-minifier.helper';
import urlMappingFactory from './../factories/url-mapping.factory';
import b2bPartnerService from './b2b-partner.service';
import { migrateSearchData } from '../helpers/search-data-migration.helper';
import bpmService, { PrefillingSearchData } from './bpm.service';
import { SearchData } from '../types/search-data';
import { NextRequestType } from '../types/next-types';
import { StringsOnlyQuery } from '../types/strings-only-query';
import searchDataFactory from '../factories/search-data.factory';

class SearchDataService {

  createQueryFromSearchData(searchData: SearchData) {
    const mappedSearchData: SearchData = {
      ...searchData,
      nonSmokerSince: searchData.nonSmokerSince || undefined
    };

    return minifyState(mappedSearchData || searchDataFactory.createDefaultSearchData(),
      urlMappingFactory.createSearchDataMapping());
  }

  async _buildB2bSearchDataExtension(req: NextRequestType|undefined, query: StringsOnlyQuery) {
    const b2bInfo = await b2bPartnerService.getB2BPartnerInfo(query, req);
    return {
      b2BPartner: b2bInfo.b2BPartner || null,
      b2BAdPartner: b2bInfo.b2BAdPartner || null
    };
  }

  async createSearchDataFromQuery(req: NextRequestType|undefined, query: StringsOnlyQuery) {
    const defaultSearchData = searchDataFactory.createDefaultSearchData();
    const querySearchData = this._getQuerySearchData(query);

    const [b2bSearchDataExtension, bpmPrefilling] = await this.loadB2bAndPrefillingData(req, query);
    const prefillingSearchData: PrefillingSearchData = Object.assign(
      bpmPrefilling,
      querySearchData,
      b2bSearchDataExtension,
    );

    const searchData: SearchData = {
      ...defaultSearchData,
      ...prefillingSearchData,
      insuranceFilters: this._castInsuranceFiltersValue(prefillingSearchData.insuranceFilters)
    };

    searchData[SEARCH_DATA_FIELDS.INSURANCE_START] = date.getInsuranceStart();

    if (Number.isNaN(Number(searchData[SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]))) {
      throw new Error(`Invalid benefitAmount: ${searchData[SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]}`);
    }
    searchData[SEARCH_DATA_FIELDS.BENEFIT_AMOUNT] = Math.round(Number(searchData[SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]));

    const transformedSearchData: SearchData = this._transformSmokerSearchData(searchData);
    return migrateSearchData(transformedSearchData);
  }

  _transformSmokerSearchData(searchData: SearchData): SearchData {
    const transformedSearchData = {
      ...searchData,
    };

    if (searchData.nonSmokerSince) {
      transformedSearchData.nonSmokerSince = searchData.nonSmokerSince;
    }

    if (typeof transformedSearchData.smoker !== 'boolean') {
      transformedSearchData.smoker = false;
      transformedSearchData.nonSmokerSince = undefined;
    }

    if (transformedSearchData.nonSmokerSince !== null && transformedSearchData.smoker) {
      transformedSearchData.nonSmokerSince = undefined;
    }

    return transformedSearchData;
  }

  _getQuerySearchData(query: StringsOnlyQuery) {
    return unminifyState(query, urlMappingFactory.createSearchDataMapping());
  }

  async loadB2bAndPrefillingData(req: NextRequestType|undefined, query: StringsOnlyQuery) {
    if (Promise.allSettled) {
      const [b2bSearchDataExtension, bpmPrefilling] = await Promise.allSettled([
        this._buildB2bSearchDataExtension(req, query),
        bpmService.getPrefilling()
      ]);
      return [
        b2bSearchDataExtension.status === 'fulfilled' ? b2bSearchDataExtension.value : {},
        bpmPrefilling.status === 'fulfilled' ? bpmPrefilling.value : {}
      ];
    }

    return Promise.all([
      this._buildB2bSearchDataExtension(req, query).catch(() => ({})),
      bpmService.getPrefilling().catch(() => ({}))
    ]);
  }

  _castInsuranceFiltersValue(insuranceFilters: unknown): number[] {
    if (typeof insuranceFilters === 'string' && insuranceFilters) {
      return insuranceFilters.split(',').map((val) => parseInt(val, 10));
    }
    if (typeof insuranceFilters === 'number' && Number.isInteger(insuranceFilters)) {
      return [insuranceFilters];
    }
    return [];
  }
}

export default new SearchDataService();
