export function createDefaultPersonalData() {
  return {
    salutation: '',
    title: '',
    birthday: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: {
      postalCode: '',
      city: '',
      street: '',
      houseNumber: ''
    },
    agreementToContactFromAdvisor: false
  };
}
