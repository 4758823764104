import {
  JOB_SITUATIONS,
  FAMILY_STATUS,
  STAFF_RESPONSIBILITY_OPTIONS,
  ValueOf,
  EDUCATION_TYPES,
  INDUSTRIES
} from 'bu.lookups';

import { DYNAMIC_PREMIUM_FILTER, TARIFF_GRADE_FILTER } from '../constants/filter.constant';
import { SMOKER_STATUS_VALUES } from '../constants/smoker-status-map.constant';

export const fractionOfficeWork = [
  { value: 0, text: '0% bis 24%' },
  { value: 25, text: '25% bis 49%' },
  { value: 50, text: '50% bis 74%' },
  { value: 100, text: '75% bis 100%' }
] as const;

const smoker = [
  { value: SMOKER_STATUS_VALUES.SMOKER, text: 'Ja', isDefault: false },
  { value: SMOKER_STATUS_VALUES.NON_SMOKER_1YEAR, text: 'Nein, mind. 1 Jahr', isDefault: false },
  { value: SMOKER_STATUS_VALUES.NON_SMOKER_10YEAR, text: 'Nein, mind. 10 Jahre', isDefault: false },
  { value: SMOKER_STATUS_VALUES.NON_SMOKER, text: 'Nein, nie geraucht', isDefault: true },
] as const;

const onboardingSmoker = [
  { value: SMOKER_STATUS_VALUES.SMOKER, text: 'ja' },
  { value: SMOKER_STATUS_VALUES.NON_SMOKER_1YEAR, text: 'nein, seit mind. 1 Jahr nicht mehr' },
  { value: SMOKER_STATUS_VALUES.NON_SMOKER_10YEAR, text: 'nein, seit mind. 10 Jahren nicht mehr' },
  { value: SMOKER_STATUS_VALUES.NON_SMOKER, text: 'nein, noch nie geraucht', isDefault: true }
] as const;

const jobSituation = [
  { value: JOB_SITUATIONS.ANGESTELLT, text: JOB_SITUATIONS.ANGESTELLT },
  { value: JOB_SITUATIONS.SELBSTAENDIG, text: JOB_SITUATIONS.SELBSTAENDIG },
  { value: JOB_SITUATIONS.BEAMTET, text: JOB_SITUATIONS.BEAMTET },
  { value: JOB_SITUATIONS.STUDIEREND, text: JOB_SITUATIONS.STUDIEREND },
  { value: JOB_SITUATIONS.IN_AUSBILDUNG, text: JOB_SITUATIONS.IN_AUSBILDUNG },
  { value: JOB_SITUATIONS.ANDERES, text: JOB_SITUATIONS.ANDERES }
] as const;

const ABITUR_TEXT = `${EDUCATION_TYPES.ABITUR}/Fachabitur` as const;
type EducationTypeText =
  Omit<typeof EDUCATION_TYPES, 'ABITUR'> & { value: typeof EDUCATION_TYPES.ABITUR; text: typeof ABITUR_TEXT };

const educationType: readonly { value: ValueOf<typeof EDUCATION_TYPES>; text: ValueOf<EducationTypeText> }[] = [
  { value: EDUCATION_TYPES.PROMOTION, text: EDUCATION_TYPES.PROMOTION },
  { value: EDUCATION_TYPES.STUDIUM_MASTER, text: EDUCATION_TYPES.STUDIUM_MASTER },
  { value: EDUCATION_TYPES.STUDIUM_BACHELOR, text: EDUCATION_TYPES.STUDIUM_BACHELOR },
  { value: EDUCATION_TYPES.TECHNIKER, text: EDUCATION_TYPES.TECHNIKER },
  { value: EDUCATION_TYPES.MEISTER, text: EDUCATION_TYPES.MEISTER },
  { value: EDUCATION_TYPES.FACH_UND_BETRIEBSWIRT, text: EDUCATION_TYPES.FACH_UND_BETRIEBSWIRT },
  { value: EDUCATION_TYPES.BERUFSAUSBILDUNG, text: EDUCATION_TYPES.BERUFSAUSBILDUNG },
  { value: EDUCATION_TYPES.ABITUR, text: ABITUR_TEXT },
  { value: EDUCATION_TYPES.REALSCHULABSCHLUSS, text: EDUCATION_TYPES.REALSCHULABSCHLUSS },
  { value: EDUCATION_TYPES.HAUPTSCHULABSCHLUSS, text: EDUCATION_TYPES.HAUPTSCHULABSCHLUSS },
  { value: EDUCATION_TYPES.KEIN_ABSCHLUSS, text: EDUCATION_TYPES.KEIN_ABSCHLUSS }
] as const;

const searchDataOptions = {
  mandatoryOptions: {
    benefitAgeLimit: [
      { value: 55, text: 'bis zum 55. Lebensjahr' },
      { value: 56, text: 'bis zum 56. Lebensjahr' },
      { value: 57, text: 'bis zum 57. Lebensjahr' },
      { value: 58, text: 'bis zum 58. Lebensjahr' },
      { value: 59, text: 'bis zum 59. Lebensjahr' },
      { value: 60, text: 'bis zum 60. Lebensjahr' },
      { value: 61, text: 'bis zum 61. Lebensjahr' },
      { value: 62, text: 'bis zum 62. Lebensjahr' },
      { value: 63, text: 'bis zum 63. Lebensjahr' },
      { value: 64, text: 'bis zum 64. Lebensjahr' },
      { value: 65, text: 'bis zum 65. Lebensjahr' },
      { value: 66, text: 'bis zum 66. Lebensjahr' },
      { value: 67, text: 'bis zum 67. Lebensjahr' }
    ],
    jobSituation: jobSituation,
    educationType: educationType,
    dynamicPremiumFilter: [
      { value: DYNAMIC_PREMIUM_FILTER.NONE, text: 'Keine' },
      { value: DYNAMIC_PREMIUM_FILTER.DYNAMIC_PREMIUM_3_PERCENT, text: '3% p.a.' },
      { value: DYNAMIC_PREMIUM_FILTER.DYNAMIC_PREMIUM_5_PERCENT, text: '5% p.a.' }
    ],

    tariffGradeFilter: [
      { value: TARIFF_GRADE_FILTER.ONE_AND_A_HALF, text: '1,5 oder besser' },
      { value: TARIFF_GRADE_FILTER.TWO_AND_A_HALF, text: '2,5 oder besser' },
      { value: TARIFF_GRADE_FILTER.EMPTY, text: 'Alle' }
    ]
  },
  optionalOptions: {
    fractionOfficeWork: fractionOfficeWork,
    smoker: smoker,
    onboardingSmoker,
    industry: Object.values(INDUSTRIES).map((value) => ({ value, text: value })),
    staffResponsibility: STAFF_RESPONSIBILITY_OPTIONS,
    familyStatus: [
      { value: FAMILY_STATUS.KEINE_ANGABE, text: FAMILY_STATUS.KEINE_ANGABE, isDefault: true },
      { value: FAMILY_STATUS.PAAR_MIT_KIND, text: FAMILY_STATUS.PAAR_MIT_KIND, isDefault: false },
      { value: FAMILY_STATUS.SINGLE_MIT_KIND, text: FAMILY_STATUS.SINGLE_MIT_KIND, isDefault: false },
      { value: FAMILY_STATUS.KEINE_KINDER, text: FAMILY_STATUS.KEINE_KINDER, isDefault: false }
    ]
  }
} as const;

class SearchDataOptionsFactory {
  searchDataOptions = searchDataOptions;
}

export default new SearchDataOptionsFactory();
