
export const SMOKER_STATUS_VALUES = {
  SMOKER: 'smoker',
  NON_SMOKER: 'nonSmoker',
  NON_SMOKER_1YEAR: 'nonSmoker1Year',
  NON_SMOKER_10YEAR: 'nonSmoker10Year'
} as const;

export const SMOKER_STATUS_MAP = [
  { status: SMOKER_STATUS_VALUES.SMOKER, value: { smoker: true, nonSmokerSince: undefined } },
  { status: SMOKER_STATUS_VALUES.NON_SMOKER, value: { smoker: false, nonSmokerSince: undefined } },
  { status: SMOKER_STATUS_VALUES.NON_SMOKER_1YEAR, value: { smoker: false, nonSmokerSince: 1 } },
  { status: SMOKER_STATUS_VALUES.NON_SMOKER_10YEAR, value: { smoker: false, nonSmokerSince: 10 } },
] as const;
