import { deleteCookie, setCookie } from 'cookies-next';
import { WIREFRAME_PRODUCT, PRODUCT_HOLDING } from 'bu.lookups';

import configService from './config.service';
import { PPSET_COOKIE, VERSICHERUNG_PLUS_REGISTRATION_COOKIE } from '../constants/cookies.constant';

const PRODUCTION_ENV = 'production';

class SetCookieService {

  deletePpsetCookie() {
    deleteCookie(PPSET_COOKIE, {
      domain: configService.getConfig().domain,
      path: '/',
    });
  }

  setXppSetCookie() {
    const config = configService.getConfig();
    if (config.env !== PRODUCTION_ENV) {
      C24M.xppset.domain = config.domain;
    }
    C24M.xppset.addProduct(WIREFRAME_PRODUCT.BU);
  }

  setPpsetCookie() {
    setCookie(PPSET_COOKIE, PRODUCT_HOLDING.BU, {
      domain: configService.getConfig().domain,
      expires: new Date(Date.now() + 5 * 864e5), // 5 Days
      path: '/',
    });
  }

  setVersicherungPlusRegistrationCookie(isEligibleForVersicherungPlus: boolean) {
    setCookie(VERSICHERUNG_PLUS_REGISTRATION_COOKIE, isEligibleForVersicherungPlus, {
      domain: configService.getConfig().buCookieSubDomain,
      path: '/',
    });
  }

  deleteVersicherungPlusRegistrationCookie() {
    deleteCookie(VERSICHERUNG_PLUS_REGISTRATION_COOKIE, {
      domain: configService.getConfig().buCookieSubDomain,
      path: '/',
    });
  }
}

export default new SetCookieService();
