import { action, makeObservable, observable } from 'mobx';
import remove from 'lodash/remove';
import tariffService from '../services/tariff.service';
import storeFactory from './../factories/store.factory';
import {
  cleanIndexesFromSessionStorage,
  removeIndexByTariffIdFromSessionStorage,
  saveIndexToSessionStorage
} from '../helpers/index-session-storage.helper';
import navigationService from '../services/navigation.service';
import { TariffId } from '../types/tariff-id';

const MAX_COUNT_OF_SELECTED_TARIFFS = 3;

class TariffSelectionStore {
  searchDataStore = storeFactory.getSearchDataStore();

  @observable tariffIds: TariffId[] = [];
  @observable isComparisonPageLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  loadTariffs(tariffIds: TariffId[]) {
    this.tariffIds = [];
    for (const tariffId of tariffIds) {
      this.tariffIds.push(Number(tariffId));
      this.prefetchTariff(tariffId);
    }
  }

  isTariffCountLimitReached() {
    return this.tariffIds.length >= MAX_COUNT_OF_SELECTED_TARIFFS;
  }

  updateQueryWithSelectedTariffsState() {
    navigationService.updateQueryWithSelectedTariffsState(this.tariffIds);
  }

  @action select(tariffId: TariffId, index: number|undefined) {
    if (!this.isTariffCountLimitReached()) {
      this.tariffIds.push(tariffId);
      saveIndexToSessionStorage(tariffId, index);
      this.prefetchTariff(tariffId);
    }
  }

  @action unselect(tariffId: TariffId) {
    remove(this.tariffIds, (id) => id === tariffId);
    removeIndexByTariffIdFromSessionStorage(tariffId);
  }

  @action
  toggleTariffSelection(tariffId: TariffId, index: number|undefined) {
    if (this.isSelected(tariffId)) {
      this.unselect(tariffId);
    } else {
      this.select(tariffId, index);
    }

    this.updateQueryWithSelectedTariffsState();
  }

  @action
  clear() {
    this.tariffIds = [];
    this.updateQueryWithSelectedTariffsState();
    cleanIndexesFromSessionStorage();
  }

  isSelected(tariffId: TariffId) {
    return this.tariffIds.some(id => id === tariffId);
  }

  prefetchTariff(tariffId: TariffId) {
    return tariffService.getTariff(this.searchDataStore.searchData, tariffId);
  }

  @action
  setIsComparisonPageLoading(isComparisonPageLoading: boolean) {
    this.isComparisonPageLoading = isComparisonPageLoading;
  }
}

export default TariffSelectionStore;
