import { firstBy } from 'thenby';
import http, { CancelTokenSource } from 'bu.http';
import { SORT_OPTIONS, SortOption } from 'bu.lookups';

import configService from './config.service';
import tariffCachingService from './tariff-caching.service';

import { getTariffListQueryVariables } from '../helpers/tariff.helper';
import { SearchData } from '../types/search-data';
import { Insurance, Tariff } from '../types/tariff';
import { TariffId } from '../types/tariff-id';

export type TariffListQueryResult = {
  tariffs: Tariff[];
  isSpecialOccupation: boolean;
  minTariffPrice:number;
  minPricesForFeatureFilters: Record<string, number|null>;
  minPricesForInsuranceFilters:Record<string, number>;
}

class TariffService {
  mobileApiUrl: string;
  constructor() {
    const config = configService.getConfig();
    this.mobileApiUrl = config.services.mobileApi;
  }

  async #sendPostQuery(variables: Partial<SearchData>, cancellationToken: CancelTokenSource, useCache = true) {
    const requestVariables = getTariffListQueryVariables(variables);
    const cachedResponse = useCache && (await tariffCachingService.getResultList(requestVariables));

    if (cachedResponse) {
      return cachedResponse;
    }

    let response: TariffListQueryResult|undefined;

    try {
      response = await http.post<TariffListQueryResult>(
        this.mobileApiUrl,
        '/tariffs',
        {
          ...requestVariables, origin: '/tariffs'
        },
        {
          cancelToken: cancellationToken?.token
        }
      );

      tariffCachingService.cacheResultList(requestVariables, response);
    } catch (error) {
      if (!http.isCancelError(error)) {
        throw error;
      }
    }

    return response;
  }

  async getTariffList(searchData: SearchData, cancellationToken: CancelTokenSource, useCache = false):
    Promise<TariffListQueryResult> {
    const variables = { ...getTariffListQueryVariables(searchData) };
    const response = await this.#sendPostQuery(variables, cancellationToken, useCache);

    const tariffs = response?.tariffs ?? [];

    const result = {
      tariffs: this.sortTariffs(tariffs, searchData.sortedBy),
      isSpecialOccupation: response?.isSpecialOccupation ?? false,
      minTariffPrice: response?.minTariffPrice ?? Number.MAX_VALUE,
      minPricesForFeatureFilters: response?.minPricesForFeatureFilters ?? {},
      minPricesForInsuranceFilters: response?.minPricesForInsuranceFilters ?? {}
    };

    return result;
  }

  async getTariffsInsurances(searchData: SearchData, useCache = true): Promise<Insurance[]> {
    const requestVariables = getTariffListQueryVariables(searchData);
    const cachedResult = useCache && (await tariffCachingService.getInsurancePrice(requestVariables));

    if (cachedResult) {
      return cachedResult;
    }

    const variables = { ...requestVariables, origin: '/tariffs/insurances' };

    const response = await http.post<Insurance[]>(this.mobileApiUrl, '/tariffs/insurances', variables);

    tariffCachingService.cacheInsurancePrice(requestVariables, response);

    return response;
  }

  async getSelectedTariffs(searchData: SearchData, tariffIds: TariffId[]) {
    const tariffQuery = { ...getTariffListQueryVariables(searchData) };

    const calculationInput = { ...tariffQuery, tariffIds };
    const cachedResult = tariffCachingService.getSelectedTariffs(calculationInput);

    if (cachedResult) {
      return cachedResult;
    }

    const response = await http.post<Tariff[]>(this.mobileApiUrl,
      '/selected-tariffs',
      { ...calculationInput, origin: '/selected-tariffs' }
    );
    tariffCachingService.cacheSelectedTariffs(calculationInput, response);

    return response;
  }

  async getTariff(searchData: SearchData, tariffId: TariffId) {
    const tariffQuery = { ...getTariffListQueryVariables(searchData) };
    const cacheKey = { ...tariffQuery, tariffId };
    const cachedResult = tariffCachingService.getTariff(cacheKey);

    if (cachedResult) {
      return cachedResult;
    }

    const query = { ...tariffQuery, origin: '/tariffs/:tariffId' };

    const tariff = await http.post<Tariff | ''>(this.mobileApiUrl, `/tariffs/${tariffId}`, query);
    tariffCachingService.cacheTariff(cacheKey, tariff);

    return tariff;
  }

  async getMinGfTariffPrice(searchData: SearchData, cancellationToken: CancelTokenSource) {
    const queryVariable = { ...getTariffListQueryVariables(searchData), timeout: 10 };
    const cachedResponse = await tariffCachingService.getMinGfPrice(queryVariable);

    if (cachedResponse) {
      return cachedResponse;
    }
    const variables = { ...queryVariable, origin: '/min-tariff-price' };

    let response: number|undefined;

    try {
      response = await http.post<number>(this.mobileApiUrl, '/min-tariff-price', variables, {
        cancelToken: cancellationToken?.token
      });

      tariffCachingService.cacheMinGfPrice(queryVariable, response);
    } catch (error) {
      if (!http.isCancelError(error)) {
        throw error;
      }
    }

    return response;
  }

  sortTariffs(tariffs: Tariff[], sortBy: SortOption) {
    if (!tariffs?.length) {
      return [];
    }

    const tariffsCopy = [...tariffs];

    switch (sortBy) {
      case SORT_OPTIONS.NAME_ASC:
        return tariffsCopy.sort(firstBy(this.#sortByName).thenBy(this.#sortByPrice));
      case SORT_OPTIONS.NAME_DESC:
        return tariffsCopy.sort(firstBy(this.#sortByNameDesc).thenBy(this.#sortByPrice));
      case SORT_OPTIONS.GRADE:
        return tariffsCopy.sort(firstBy(this.#sortByPoints).thenBy(this.#sortByPrice));
      default:
        return tariffsCopy.sort(firstBy(this.#sortByPrice).thenBy(this.#sortByName));
    }
  }

  #sortByName(t1: Tariff, t2: Tariff) {
    const nameSortRegex = /^die [a-zA-Z\s]+$/;
    let t1InsuranceName = t1.insurance.name.toLowerCase();
    let t2InsuranceName = t2.insurance.name.toLowerCase();

    if (t1InsuranceName.match(nameSortRegex)) {
      t1InsuranceName = t1InsuranceName.substr(4);
    }

    if (t2InsuranceName.match(nameSortRegex)) {
      t2InsuranceName = t2InsuranceName.substr(4);
    }

    return t1InsuranceName.localeCompare(t2InsuranceName);
  }

  #sortByNameDesc(t1: Tariff, t2: Tariff) {
    const nameSortRegex = /^die [a-zA-Z\s]+$/;
    let t1InsuranceName = t1.insurance.name.toLowerCase();
    let t2InsuranceName = t2.insurance.name.toLowerCase();

    if (t1InsuranceName.match(nameSortRegex)) {
      t1InsuranceName = t1InsuranceName.substr(4);
    }

    if (t2InsuranceName.match(nameSortRegex)) {
      t2InsuranceName = t2InsuranceName.substr(4);
    }

    return t2InsuranceName.localeCompare(t1InsuranceName);
  }

  #sortByPrice(t1: Tariff, t2: Tariff) {
    return t1.netPrice - t2.netPrice;
  }

  #sortByPoints(t1: Tariff, t2: Tariff) {
    return t2.points - t1.points;
  }
}

export default new TariffService();
