import configService from './config.service';
import { Config } from '../types/config';

class FeatureSwitchService {
  featureSwitches: Config['featureSwitches'];
  constructor() {
    this.featureSwitches = configService.getConfig().featureSwitches;
  }

  isLinearPointsXtremeEnabled() {
    return this.featureSwitches.isLinearPointsXtremeEnabled;
  }

  isCustomChattyEnabled() {
    return this.featureSwitches.isCustomChattyEnabled;
  }
}

export default new FeatureSwitchService();
