const SESSION_STORAGE_KEYS = {
  STORAGE_ACKNOWLEDGED_EXPIRED_SESSION: 'acknowledgedExpiredSession',
  STORAGE_APPOINTMENT_DATA: 'appointmentData',
  STORAGE_INQUIRY: 'inquiry',
  STORAGE_IS_GF_POPUP_SHOWN_ONCE: 'isGfPopupShownOnce',
  STORAGE_INDEX: 'tariffIndexes',
  STORAGE_PERSONAL_DATA: 'personaldata'
} as const;

export default SESSION_STORAGE_KEYS;
