import { action, flow, makeObservable, observable } from 'mobx';
import { getCookie } from 'cookies-next';
import { isClient } from 'bu.helpers';
import { webAppLoggingService } from 'bu.logger-client';

import authService from '../services/auth.service';
import { SsoUserData } from '../types/sso-user-data';
import { ExpiredSessionData } from '../types/expired-session-data';
import { UnifiedLoginData } from '../types/unified-login-data';
import storeFactory from '../factories/store.factory';
import unifiedLoginService from '../services/unified-login.service';
import { VERSICHERUNG_PLUS_REGISTRATION_COOKIE } from '../constants/cookies.constant';
import setCookieService from '../services/set-cookie.service';
import { isPointsParticipantWithoutVersicherungPlus } from '../helpers/sso-user-data.helper';

// https://confluence.check24.de/display/HOULI/Integration+Guide
// STATE: state of the user ("loggedin", "anonymous", "browserback", session-id on int/test)
const IS_STATE_LOGGED_IN_REGEX = /^loggedin|[0-9a-f]{32}$/;

class SsoStore {
  deviceOutputStore = storeFactory.getDeviceOutputStore();

  @observable isLoggedIn = false;
  @observable ssoUserData?: SsoUserData;
  @observable expiredSessionData?: ExpiredSessionData;
  @observable loggedInWithPhoneAccount = false;
  @observable inCheck24PointsProgram = false;
  @observable isEligibleForVersicherungPlus = false;
  unifiedLoginData?: UnifiedLoginData | undefined;

  unifiedLoginHandler: typeof SsoStore.prototype.handleUnifiedLoginEvent | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  init(ssoUserData: SsoUserData|undefined, expiredSsoUserData: ExpiredSessionData|undefined) {
    this.isLoggedIn = !!ssoUserData;
    this.ssoUserData = ssoUserData;
    this.expiredSessionData = expiredSsoUserData;

    this.loggedInWithPhoneAccount = Boolean(this.isLoggedIn && ssoUserData!.loginPhoneNumber);
    this.inCheck24PointsProgram = this.isLoggedIn && ssoUserData?.points === 'yes';
    this.isEligibleForVersicherungPlus = getCookie(VERSICHERUNG_PLUS_REGISTRATION_COOKIE) === 'true' ||
      isPointsParticipantWithoutVersicherungPlus(this.ssoUserData, this.getPointsParticipantFromUnifiedLoginData());
  }

  initUnifiedLoginEventListener() {
    if (!this.unifiedLoginHandler) {
      this.unifiedLoginHandler = this.handleUnifiedLoginEvent.bind(this);
      if (isClient()) {
        window.addEventListener('unified-login', (e) => this.unifiedLoginHandler?.(e));
        window.addEventListener('logout-custom-event', () => this.handleLogoutCustomEvent());
      }
    }
  }

  @action
  setUnifiedLoginData(unifiedLoginData: UnifiedLoginData | undefined) {
    this.unifiedLoginData = unifiedLoginData;
  }

  getPointsParticipantFromUnifiedLoginData(): boolean {
    return Boolean(this.unifiedLoginData && [true, 'true', 'yes'].includes(this.unifiedLoginData.USERPOINTS));
  }

  isUserInCheck24PointsProgram() {
    return (this.isLoggedIn &&
      (this.getPointsParticipantFromUnifiedLoginData() || this.inCheck24PointsProgram));
  }

  openUnifiedLoginLayer(pointsAmount: string) {
    if (this.deviceOutputStore.isApp()) {
      const email = this.unifiedLoginData?.USEREMAIL || '';
      const customParam = pointsAmount ? pointsAmount.toString() : '';

      if (typeof C24App !== 'undefined') {
        C24App.triggerUnifiedLogin('bu-app', email, customParam);
      }
    } else {
      unifiedLoginService.openUnifiedLoginLayer();
    }
  }

  @flow *handleUnifiedLoginEvent(e: CustomEvent<{ ul: UnifiedLoginData }>) {
    const payload = e.detail.ul;

    this.setUnifiedLoginData(payload);

    if (!IS_STATE_LOGGED_IN_REGEX.test(payload.STATE) && payload.STATE !== 'anonymous') {
      return;
    }

    let ssoUserData: SsoUserData | undefined;
    let expiredSessionData: ExpiredSessionData | undefined;

    if (IS_STATE_LOGGED_IN_REGEX.test(payload.STATE)) {
      unifiedLoginService.setC24SessionCookieForDevAndInt(payload);

      try {
        ({ ssoUserData, expiredSessionData } = yield authService.getUserDataAndExpiredSessionData());
      } catch (err) {
        webAppLoggingService.error('Error occurred while exchanging Unified Login data with SSO user Data', err);
      }
      setCookieService.setVersicherungPlusRegistrationCookie(
        isPointsParticipantWithoutVersicherungPlus(
          ssoUserData,
          this.getPointsParticipantFromUnifiedLoginData())
      );
    }

    this.init(ssoUserData, expiredSessionData);

    // We expect this event to be triggered in these cases:
    // Anonymous: ssoUserData will be nullish
    // Normal login: ssoUserData will be set
    const event = new CustomEvent('sso-store-post-login', { detail: { ssoUserData } });
    window.dispatchEvent(event);
  }

  @action
  handleLogoutCustomEvent() {
    this.init(undefined, undefined);
    this.setUnifiedLoginData(undefined);
    setCookieService.deleteVersicherungPlusRegistrationCookie();
  }
}

export default SsoStore;
