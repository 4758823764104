import { getCookie, setCookie } from 'cookies-next';
import { DEVICE_OUTPUT, DEVICE_TYPE } from 'bu.lookups';

import configService from './config.service';
import { NextRequestType, NextResponseType } from '../types/next-types';
import { StringsOnlyQuery } from '../types/strings-only-query';
import { DEVICE_OUTPUT_COOKIE_PARAM } from '../constants/cookies.constant';
import uaParserService from './ua-parser.service';

const MAP_DEVICE_TYPE_TO_DEVICE_OUTPUT = {
  [DEVICE_TYPE.DESKTOP]: DEVICE_OUTPUT.DESKTOP,
  [DEVICE_TYPE.MOBILE]: DEVICE_OUTPUT.MOBILE,
  [DEVICE_TYPE.TABLET]: DEVICE_OUTPUT.TABLET,
};

class DeviceOutputService {
  getDeviceOutput(req?: NextRequestType): string {
    return getCookie(DEVICE_OUTPUT_COOKIE_PARAM, { req }) ?? DEVICE_OUTPUT.MOBILE;
  }

  setAndGetDeviceOutputCookie(query: StringsOnlyQuery, req?: NextRequestType, res?: NextResponseType): string {
    const deviceOutput = query[DEVICE_OUTPUT_COOKIE_PARAM] 
      ?? getCookie(DEVICE_OUTPUT_COOKIE_PARAM, { req }) 
      ?? MAP_DEVICE_TYPE_TO_DEVICE_OUTPUT[uaParserService
        .getDeviceType()];
    
    setCookie(DEVICE_OUTPUT_COOKIE_PARAM, deviceOutput, {
      req: req,
      res: res,
      httpOnly: false,
      maxAge: 21_600, // seconds
      domain: configService.getConfig().domain,
      path: '/',
      sameSite: 'lax'
    });

    return deviceOutput;
  }
}

export default new DeviceOutputService();
