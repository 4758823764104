import { EDUCATION_TYPES, ValueOf, SEARCH_DATA_FIELDS } from 'bu.lookups';
import { isPublicServant } from 'bu.helpers';

import HIGHER_EDUCATION_TYPES from '../constants/higher-education-types.constant';
import { SearchData } from '../types/search-data';

function canShowGfBanner(searchData: SearchData) {
  const higherEducationTypes: readonly (ValueOf<typeof EDUCATION_TYPES> | '')[] = HIGHER_EDUCATION_TYPES;
  return Number(searchData.fractionOfficeWork) === 0 &&
    !higherEducationTypes.includes(searchData.educationType) &&
    !isPublicServant(searchData[SEARCH_DATA_FIELDS.JOB_SITUATION]);
}

export {
  canShowGfBanner
};
