import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import { SearchData } from '../types/search-data';

export default {
  createSearchDataMapping,
  getMinKeyForField
};

const mappings: { key: keyof SearchData; minifiedKey: string }[] = [
  { key: SEARCH_DATA_FIELDS.JOB_SITUATION, minifiedKey: 'js' },
  { key: SEARCH_DATA_FIELDS.OCCUPATION, minifiedKey: 'oc' },
  { key: SEARCH_DATA_FIELDS.EDUCATION_TYPE, minifiedKey: 'et' },
  { key: SEARCH_DATA_FIELDS.BIRTHDAY, minifiedKey: 'bd' },
  { key: SEARCH_DATA_FIELDS.BENEFIT_AMOUNT, minifiedKey: 'ba' },
  { key: SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT, minifiedKey: 'bal' },
  { key: SEARCH_DATA_FIELDS.FRACTION_OFFICE_WORK, minifiedKey: 'fow' },
  { key: SEARCH_DATA_FIELDS.INDUSTRY, minifiedKey: 'ind' },
  { key: SEARCH_DATA_FIELDS.STAFF_RESPONSIBILITY, minifiedKey: 'sr' },
  { key: SEARCH_DATA_FIELDS.FAMILY_STATUS, minifiedKey: 'fs' },
  { key: SEARCH_DATA_FIELDS.NON_SMOKER_SINCE, minifiedKey: 'nss' },
  { key: SEARCH_DATA_FIELDS.SMOKER, minifiedKey: 'sm' },
  { key: SEARCH_DATA_FIELDS.B2B_PARTNER, minifiedKey: 'wp1' },
  { key: SEARCH_DATA_FIELDS.B2B_AD_PARTNER, minifiedKey: 'wp2' },
  { key: SEARCH_DATA_FIELDS.WP_SET, minifiedKey: 'wpset' },
  { key: SEARCH_DATA_FIELDS.SORTED_BY, minifiedKey: 'sort' },
  { key: SEARCH_DATA_FIELDS.DYNAMIC_PREMIUM_FILTER, minifiedKey: 'dp' },
  { key: SEARCH_DATA_FIELDS.EXCELLENT_INCREASE_OPTION_WITH_REASON_DESIRED, minifiedKey: 'ad' },
  { key: SEARCH_DATA_FIELDS.EXCELLENT_SUSTAINABILITY_DESIRED, minifiedKey: 'sd' },
  { key: SEARCH_DATA_FIELDS.INSURANCE_START, minifiedKey: 'is' },
  { key: SEARCH_DATA_FIELDS.KEYWORD, minifiedKey: 'keyword' },
  { key: SEARCH_DATA_FIELDS.EXCELLENT_STIFTUNG_WARENTEST, minifiedKey: 'sw' },
  { key: SEARCH_DATA_FIELDS.EXCELLENT_CERTIFIED_SICKNESS, minifiedKey: 'cs' },
  { key: SEARCH_DATA_FIELDS.QUICK_BENEFIT_FOR_DREAD_DISEASE, minifiedKey: 'qbsi' },
  { key: SEARCH_DATA_FIELDS.TARIFF_GRADE_FILTER, minifiedKey: 'tg' },
  { key: SEARCH_DATA_FIELDS.INSURANCE_FILTERS, minifiedKey: 'if' },
  { key: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION, minifiedKey: 'psjs' },
  { key: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP, minifiedKey: 'pscg' },
  { key: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE, minifiedKey: 'pspg' },
  { key: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS, minifiedKey: 'psey' },
  { key: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE, minifiedKey: 'pssd' },
  { key: SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE, minifiedKey: 'pslad' },
  { key: SEARCH_DATA_FIELDS.FOREIGN_REFERRAL_LINK, minifiedKey: 'frl' },
];

function createSearchDataMapping() {
  return mappings;
}

function getMinKeyForField(searchDataField: keyof SearchData) {
  return mappings.find(mapping => mapping.key === searchDataField)!.minifiedKey;
}
