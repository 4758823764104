export const NAME = {
  MENTAL_ILLNESS: 'mental_illness',
  TRAFFIC_VIOLATIONS: 'traffic_violations',
  FORECASTING_HORIZON_REDUCED_TO_6_MONTHS: 'forecasting_horizon_reduced_to_6_months',
  DANGEROUS_HOBBIES: 'dangerous_hobbies',
  DYNAMIC_PREMIUM: 'dynamic_premium',
  RETROSPECTIVE_BENEFITS: 'retrospective_benefits',
  NO_ABSTRACT_REFERRAL: 'no_abstract_referral',
  DRIVING_EVENTS: 'driving_events',
  INCREASE_OPTION_WITH_REASON: 'increase_option_with_reason',
  CLAIM_ACCEPTANCE_RATIO: 'claim_acceptance_ratio',
  FINANCIAL_STABILITY: 'financial_stability',
  STOP_GAP_PREMIUM_PAYMENT_GENERAL: 'stop_gap_premium_payment_general',
  DISABILITY_FOR_SERVICE: 'disability_for_service',
  CUSTOMER_SERVICE: 'customer_service',
} as const;

export const CATEGORY_NAME = {
  DYNAMIC: 'dynamic',
  CLAIM_ASSESSMENT: 'claim_assessment',
} as const;

export const VALUE_TITLE = {
  EXCELLENT: 'Exzellent',
  MEDIUM: 'Mittel',
  BAD: 'Schlecht',
  YES: 'Ja',
  NO: 'Nein',
} as const;

export const SALUTATION = {
  HERR: 'Herr',
  FRAU: 'Frau',
} as const;

export default {
  NAME,
  VALUE_TITLE,
  CATEGORY_NAME,
  SALUTATION,
};
