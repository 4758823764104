import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import { SearchData } from '../types/search-data';
import { SsoUserData } from '../types/sso-user-data';

const SMOKING_BENEFIT_AGE_LIMIT = 67;

type SelectOption = {
  value: number;
  text: string;
}

function isBenefitAgeLimitSmoking(benefitAgeLimit: number) {
  return benefitAgeLimit === SMOKING_BENEFIT_AGE_LIMIT;
}

function getSearchDataWithUnselectedFilters(searchData: SearchData): SearchData {
  return {
    ...searchData,
    [SEARCH_DATA_FIELDS.EXCELLENT_INCREASE_OPTION_WITH_REASON_DESIRED]: false,
    [SEARCH_DATA_FIELDS.EXCELLENT_SUSTAINABILITY_DESIRED]: false,
    [SEARCH_DATA_FIELDS.EXCELLENT_STIFTUNG_WARENTEST]: false,
    [SEARCH_DATA_FIELDS.EXCELLENT_CERTIFIED_SICKNESS]: false,
    [SEARCH_DATA_FIELDS.QUICK_BENEFIT_FOR_DREAD_DISEASE]: false,
    [SEARCH_DATA_FIELDS.DYNAMIC_PREMIUM_FILTER]: '',
    [SEARCH_DATA_FIELDS.TARIFF_GRADE_FILTER]: '',
    [SEARCH_DATA_FIELDS.INSURANCE_FILTERS]: []
  };
}

function getSourceDataForBirthdayValidation(searchData: SearchData, ssoUserData: SsoUserData|undefined): SearchData {
  if (ssoUserData && (!searchData.birthday || searchData.birthday.length === 0)) {
    return {
      ...searchData,
      birthday: ssoUserData.birthday ?? ''
    };
  }
  return searchData;
}

const publicServantFields = [
  SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION,
  SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP,
  SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE,
  SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS,
  SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE,
  SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE
];

function mapSelectOptions(options: readonly SelectOption[]) {
  return options.map(option => ({ value: String(option.value), text: option.text }));
}

function isMoreThanOneFilterSelectedHelper(searchData: SearchData) {
  let countOfSelectedFilters = 0;
  for (const filter of [
    searchData.excellentIncreaseOptionWithReasonDesired,
    searchData.excellentSustainabilityDesired,
    searchData.excellentCertifiedSickness,
    searchData.excellentStiftungWarentest,
    searchData.dynamicPremiumFilter,
    searchData.tariffGradeFilter,
    searchData.insuranceFilters?.length
  ]) {
    if (filter) {
      countOfSelectedFilters++;
    }
  }
  return countOfSelectedFilters > 1;
}

export {
  getSearchDataWithUnselectedFilters,
  getSourceDataForBirthdayValidation,
  isBenefitAgeLimitSmoking,
  publicServantFields,
  mapSelectOptions,
  isMoreThanOneFilterSelectedHelper
};
