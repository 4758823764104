import http from 'bu.http';
import debounce from 'lodash/debounce';
import moize, { Memoized } from 'moize';
import configService from './config.service';
import VEVE_WPSET from '../constants/veve-wpset.constant';
import { Occupation } from '../types/occupation';

class OccupationService {
  debouncedSuggest: ReturnType<typeof debounce<typeof OccupationService.prototype.suggestOccupations>>;
  mobileApiUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  suggestOccupations: Memoized<(occupation: string) => Promise<string[]>>;

  constructor() {
    const config = configService.getConfig();
    this.mobileApiUrl = config.services.mobileApi;
    this.suggestOccupations = moize(async (occupation: string) => {
      if (!occupation) {
        return [];
      }
      const query = new URLSearchParams({
        occupation: occupation
      }).toString();
      const response = await http.get<string[]>(this.mobileApiUrl, `/occupations/suggestions?${query}`);
      return response;
    }, {
      maxAge: 60 * 60 * 1000,
      maxSize: 1000
    });
    this.debouncedSuggest = debounce(this.suggestOccupations, 100, { leading: true });
  }

  async getOccupation(occupation: string, jobSituation: string, wpSet: string|undefined) {
    const query = new URLSearchParams({
      occupation: occupation,
      jobsituation: jobSituation
    }).toString();
    const searchResult = await http.get<{occupation: Occupation}>(this.mobileApiUrl, `/occupations/search?${query}`);

    if (wpSet === VEVE_WPSET.CH24_V_VC_BEDARF || wpSet === VEVE_WPSET.CH24_V_VC_BEDARF_TODOS) {
      searchResult.occupation.benefitAmount = 0;
    }

    return searchResult.occupation;
  }

  async isOccupationInList(occupation: string) {
    if (occupation?.length > 1) {
      const suggestions = await this.suggestOccupations(occupation);
      return Boolean(suggestions?.includes(occupation));
    }
    return false;
  }

  async occupationIsValid(occupation: string) {
    const query = new URLSearchParams({
      occupation: occupation
    }).toString();
    const response = await http.get<{ isValid: boolean }>(this.mobileApiUrl, `/occupations/is-valid?${query}`);
    return response.isValid;
  }
}

export default new OccupationService();

