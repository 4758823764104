import moment from 'moment-timezone';
import { EDUCATION_TYPES, SEARCH_DATA_FIELDS } from 'bu.lookups';
import { mapJobSituation } from 'bu.helpers';
import { SearchData } from '../types/search-data';

function migrateSearchData(searchData: SearchData) {
  if (searchData) {
    // VERBU-21163: we'll replace plus with whitespace for values 'Keine+Angabe'/'Paar+mit+Kind(ern)'
    // and double decode query param values only
    // Since decodeURIComponent only decodes encoded values, single encoded values are safe.
    // Thrown if encodedURI contains a % not followed by two hexadecimal digits.
    const fieldsToVerify = Object.values(SEARCH_DATA_FIELDS) as Array<keyof SearchData>;
    fieldsToVerify.forEach(key => {
      const value = searchData[key];
      if (typeof value === 'string') {
        try {
          // @ts-expect-error searchData[key] resolves to type "never" and does not match with value's 'string' value
          searchData[key] = decodeURIComponent(value.replace(/\+/g, ' '));
        } catch {
          // @ts-expect-error searchData[key] resolves to type "never" and does not match with value's 'string' value
          searchData[key] = value;
        }
      }
    });
    // VERBU-1151
    const birthdayInGermanFormat = moment(searchData.birthday, 'DD.MM.YYYY', true);
    if (birthdayInGermanFormat.isValid()) {
      searchData.birthday = birthdayInGermanFormat.format('YYYY-MM-DD');
    }

    // VERBU-8494
    if (searchData.educationType === EDUCATION_TYPES.STUDIUM_MASTER_OLD) {
      searchData.educationType = EDUCATION_TYPES.STUDIUM_MASTER;
    }

    searchData.jobSituation = mapJobSituation(searchData.jobSituation);
  }

  return searchData;
}

export {
  migrateSearchData
};

