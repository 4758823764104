const TARIFF_GRADE_FILTER = {
  ONE_AND_A_HALF: 1.5,
  TWO_AND_A_HALF: 2.5,
  EMPTY: ''
} as const;

const DYNAMIC_PREMIUM_FILTER = {
  DYNAMIC_PREMIUM_3_PERCENT: 'dynamic_premium_3_percent',
  DYNAMIC_PREMIUM_5_PERCENT: 'dynamic_premium_5_percent',
  NONE: ''
} as const;

const FEATURE_DYNAMIC_PREMIUM = {
  [DYNAMIC_PREMIUM_FILTER.DYNAMIC_PREMIUM_3_PERCENT]: '3% Beitragsdynamik',
  [DYNAMIC_PREMIUM_FILTER.DYNAMIC_PREMIUM_5_PERCENT]: '5% Beitragsdynamik',
  [DYNAMIC_PREMIUM_FILTER.NONE]: 'Beitragsdynamik',
} as const;

const FEATURE_TARIFF_GRADE = {
  [TARIFF_GRADE_FILTER.ONE_AND_A_HALF]: 'Tarifnote: 1,5 oder besser',
  [TARIFF_GRADE_FILTER.TWO_AND_A_HALF]: 'Tarifnote: 2,5 oder besser',
  [TARIFF_GRADE_FILTER.EMPTY]: 'Alle',
} as const;

export {
  TARIFF_GRADE_FILTER,
  DYNAMIC_PREMIUM_FILTER,
  FEATURE_TARIFF_GRADE,
  FEATURE_DYNAMIC_PREMIUM
};
