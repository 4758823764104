import http from 'bu.http';
import configService from './config.service';

class AddressService {
  getAddresses(postalCode: string) {
    const clientConfig = configService.getConfig();
    return http.get<{ cities: string[]; streets: string[]; areaCode: string }>(
      clientConfig.services.mobileApi, `/addresses/${postalCode}`
    );
  }
}

export default new AddressService();
