import http from 'bu.http';
import configService from './../services/config.service';
import { Config } from '../types/config';

class EmailService {
  config: Config;
  constructor() {
    this.config = configService.getConfig();
  }

  sendMail(mailData: { inquiryPublicId: string; text: string }) {
    return http.post(this.config.services.mobileApi, '/send-email-from-customer', mailData);
  }

  validateEmailAddress(emailAddress: string) {
    return http.post<{ isValid: boolean }>(
      this.config.services.mobileApi, '/email/address-validation', { emailAddress }
    );
  }

  resubscribeEmail(email: string) {
    return http.post(this.config.services.mobileApi, `/email/resubscribe/${encodeURIComponent(email)}`);
  }

  unsubscribeEmail(email: string) {
    return http.post(this.config.services.mobileApi, `/email/unsubscribe/${encodeURIComponent(email)}`);
  }
}

export default new EmailService();
