import Router, { Router as RouterType } from 'next/router';
import { JOB_SITUATIONS, SEARCH_DATA_FIELDS } from 'bu.lookups';
import { wait } from 'bu.helpers';

import searchDataService from './search-data.service';
import storeFactory from '../factories/store.factory';
import currentUrlService from './current-url.service';
import PAGES from '../constants/pages.constant';
import configService from './config.service';
import urlMappingFactory from '../factories/url-mapping.factory';
import { TariffId } from '../types/tariff-id';
import { StringsOnlyQuery } from '../types/strings-only-query';
import { convertQueryToStringsOnlyQuery } from '../helpers/convert-query.helper';

// Note: pages like _error, _app, _document, cannot be accessed directly by the Next.JS router
class NavigationService {
  WAIT_BEFORE_REDIRECT = 3000;

  async navigate(link: Parameters<RouterType['push']>[0]) {
    await Router.push(link);
    window.scrollTo(0, 0);
  }

  navigateToPersonalDataPage(tariffId: TariffId) {
    return this.navigate(this.getPersonalDataLink(tariffId));
  }

  updateQueryFromCurrentSearchData() {
    const link = this.getLink(window.location.pathname);
    if (typeof link.query.ba === 'string') {
      link.query.ba = link.query.ba.replace('.', '');
    }
    return Router.replace(link, link, { shallow: true });
  }

  updateQueryWithSelectedTariffsState(tariffIds: TariffId[]) {
    const link = this.getLink(window.location.pathname, { tariffIds: tariffIds.join(';') });
    return Router.replace(link, link, { shallow: true });
  }

  updateQueryWithCurrentComparisonViewState(isInComparisonView: boolean) {
    const link = this.getLink(window.location.pathname, { isInComparisonView });
    return Router.replace(link, link, { shallow: true });
  }

  removeTrackingParamsFromQuery() {
    const link = this.getLink(window.location.pathname, { utm_source: undefined });
    return Router.replace(link, link, { shallow: true });
  }

  getLink(
    pathname: string,
    additionalQuery: Record<string, unknown> = {},
  ): {
    pathname: string;
    query: StringsOnlyQuery;
  } {
    const searchDataStore = storeFactory.getSearchDataStore();

    const currentQuery = currentUrlService.getCurrentUrlQueryParams();
    const searchDataQuery = searchDataService.createQueryFromSearchData(searchDataStore.searchData);

    const jobSituationMinifiedKey = urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.JOB_SITUATION);
    if (
      currentQuery[jobSituationMinifiedKey] === JOB_SITUATIONS.BEAMTET &&
      searchDataQuery[jobSituationMinifiedKey] !== JOB_SITUATIONS.BEAMTET
    ) {
      delete currentQuery[urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP)];
      delete currentQuery[urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE)];
      delete currentQuery[urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS)];
      delete currentQuery[urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION)];
      delete currentQuery[
        urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE)
      ];
      delete currentQuery[urlMappingFactory.getMinKeyForField(SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE)];
    }

    const query = { ...currentQuery, ...searchDataQuery, ...additionalQuery };

    return { pathname, query: convertQueryToStringsOnlyQuery(query) };
  }

  buildAppLoginRedirectUrl() {
    return `c24-app-login://${window.location.host}${window.location.pathname}${window.location.search}`;
  }

  getOnboardingLink() {
    return this.getLink('/onboarding-personal-data');
  }

  getSalutationEducationLink() {
    return this.getLink('/onboarding/salutation-education');
  }

  getSalutationBirthdayLink() {
    return this.getLink('/onboarding/salutation-birthday');
  }

  getOnboardingSummaryLink() {
    return this.getLink('/onboarding-summary');
  }

  getOnboardingCoverageLink() {
    return this.getLink('/onboarding-coverage-data');
  }

  getOnboardingAdditionalDataLink() {
    return this.getLink('/onboarding-additional-data');
  }

  getResultLinkForComparisonPage(isInComparisonView: boolean, tariffIds: TariffId[]) {
    return this.getLink('/result', { isInComparisonView, tariffIds });
  }

  getResultLink(isInComparisonView: boolean) {
    return this.getLink('/result', { isInComparisonView });
  }

  getComparisonLink(tariffIds: TariffId[]) {
    return this.getLink('/comparison', { tariffIds: tariffIds.join(';') });
  }

  getPersonalDataLink(tariffId?: TariffId) {
    return this.getLink('/personal-data', tariffId ? { tariffId } : {});
  }

  getThankYouLink({
    shouldShowAppointmentCalendar,
    consultantId,
  }: {
    shouldShowAppointmentCalendar: boolean;
    consultantId?: number;
  }) {
    return this.getLink('/thankyou', {
      shouldShowAppointmentCalendar: String(shouldShowAppointmentCalendar),
      ...(consultantId ? { consultantId: String(consultantId) } : null),
    });
  }

  getAppointmentLink(inquiryPublicId: string) {
    return this.getLink('/appointment', { inquiryPublicId });
  }

  getThankYouErrorLink() {
    return this.getLink('/thankyou-error');
  }

  getCurrentLink() {
    return this.getLink(window.location.pathname);
  }

  getPersonalLoginLink(tariffId?: TariffId) {
    return this.getLink(PAGES.PERSONAL_LOGIN, { tariffId });
  }

  isOnResultPage() {
    return window.location.pathname === '/result';
  }

  openGfResultPageLink(customVariable: string) {
    const config = configService.getConfig();
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('tariffIds');
    searchParams.set('sort', 'resultZero');
    searchParams.set('isFromBu', customVariable);
    const url = `${config.gfMobileAppUrl}${window.location.pathname}?${searchParams}`;

    this.updateQueryWithXsSbu();
    return Router.push(url);
  }

  updateQueryWithXsSbu() {
    const link = this.getLink(window.location.pathname, { xs_sbu: 'CH24_V_XS_SBU' });
    return Router.replace(link, link, { shallow: true });
  }

  async navigateToOnboarding() {
    await wait(this.WAIT_BEFORE_REDIRECT);
    return Router.push(this.getOnboardingLink());
  }

  getResubscribeLink(email: string) {
    return PAGES.RESUBSCRIBE.replace('[email]', encodeURIComponent(email));
  }

  navigateToThirdView(inquiryPublicId: string) {
    const queryParams = new URLSearchParams({ inquiryPublicId });
    return this.navigate(`${configService.getConfig().customerAreaUrl}/mobile/contract-details?${queryParams}`);
  }
}

export default new NavigationService();
