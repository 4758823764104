type AnyStoreInstance = NonNullable<unknown>;

type AnyStoreConstructor = {
  new(): AnyStoreInstance;
}

class StoreContainer {

  stores = new Map<AnyStoreConstructor, AnyStoreInstance>();

  getStore<TStoreClass extends AnyStoreConstructor>(StoreClass: TStoreClass): InstanceType<TStoreClass> {
    if (!this.stores.get(StoreClass)) {
      this.stores.set(StoreClass, new StoreClass());
    }
    const store = this.stores.get(StoreClass);
    if (!store) {
      throw new Error(`Store ${StoreClass.name} not found`);
    }
    return store as InstanceType<TStoreClass>;
  }
}

export default StoreContainer;
