import { PRODUCT_HOLDING } from 'bu.lookups';

import { isDevEnv, isIntEnv } from 'bu.helpers';
import { deleteCookie, setCookie } from 'cookies-next';
import configService from './config.service';
import { disableScrollForUnifiedLoginLayer, enableScrollForUnifiedLoginLayer } from '../helpers/scroll.helper';
import authService from './auth.service';
import PAGES from '../constants/pages.constant';
import { removePersonalDataFromStorage } from '../helpers/personal-data-storage.helper';
import { UnifiedLoginData } from '../types/unified-login-data';

class UnifiedLoginService {
  unifiedLoginDomain = configService.getConfig().unifiedLoginDomain;
  unifiedLoginVersion = configService.getConfig().unifiedLoginVersion;

  getUnifiedLoginDomainWithCurrentVersion() {
    return `${this.unifiedLoginDomain}/${this.unifiedLoginVersion}`;
  }

  openUnifiedLoginLayer() {
    this.initUnifiedLogin();
    Check24.uliloginlayer.open();
    disableScrollForUnifiedLoginLayer();
  }

  async logout() {
    await authService.logout();
    removePersonalDataFromStorage();
    this.removeLoginCookie();

    const event = new CustomEvent('logout-custom-event', { detail: { } });
    window.dispatchEvent(event);

    const element = document.getElementById('c24m-nav-slide');
    if (element) {
      element.scrollTop = 0;
    }

    C24M.navi.close();
  }

  removeLoginCookie() {
    const config = configService.getConfig();
    if (config.env === 'local' || config.env === 'integration') {
      deleteCookie('c24session', { path: '/' });
    }
  }

  _getUnifiedLoginLayerEnvironment() {
    return configService.getConfig().unifiedLoginEnv;
  }

  _getSocialLoginBaseUrl() {
    return configService.getConfig().socialLoginBaseUrl;
  }

  setC24SessionCookieForDevAndInt(userData: UnifiedLoginData) {
    const shouldSetSessionCookie = isDevEnv() || isIntEnv();

    if (shouldSetSessionCookie && userData.SSO_SESSION) {
      setCookie('c24session', userData.SSO_SESSION, {
        domain: configService.getConfig().domain,
        path: '/',
      });
    }
  }

  initUnifiedLogin() {
    Check24.uliloginlayer.config.env = this._getUnifiedLoginLayerEnvironment();
    Check24.uliloginlayer.init({
      deviceoutput: 'mobile',
      api_product: PRODUCT_HOLDING.BU, // we use bu for both bu & duv products
      login_email: '',
      login_phone: '',
      login_type: '',
      social_login_callback_url: `${window.location.origin}${PAGES.SOCIAL_LOGIN_CLOSING}`,
      social_login_closing_url: `${window.location.origin}${PAGES.SOCIAL_LOGIN_CLOSING}`,
      social_login_base_url: this._getSocialLoginBaseUrl(),
    });

    Check24.uliloginlayer.beforeOpen = () => {
      disableScrollForUnifiedLoginLayer();
    };

    Check24.uliloginlayer.closeAfter = (userData: UnifiedLoginData) => {
      enableScrollForUnifiedLoginLayer();
      this.setC24SessionCookieForDevAndInt(userData);

      let historyCount = Check24.uliloginlayer.historyCount;
      let minHistoryCount;
      if (window.location.pathname.includes('personal-data')) {
        minHistoryCount = 1;
      } else {
        minHistoryCount = 0;
      }

      while (historyCount > minHistoryCount) {
        window.history.back();
        historyCount--;
      }
    };
  }
}

export default new UnifiedLoginService();
