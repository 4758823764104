import { action, flow, makeObservable, observable } from 'mobx';
import { webAppLoggingService } from 'bu.logger-client';
import { Mobile } from 'bu.components-customer-apps';

import consultantCarouselService from '../services/consultant-carousel.service';

class ThankYouConsultantBoxStore {
  @observable consultant?: Mobile.Consultant;
  @observable isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @flow *loadConsultant(id: number) {
    try {
      this.isLoading = true;
      const consultant: Awaited<ReturnType<typeof consultantCarouselService.getConsultantForCarouselById>> =
        yield consultantCarouselService.getConsultantForCarouselById(id);
      this.consultant = consultant;
    } catch (e) {
      this.consultant = undefined;
      webAppLoggingService.error('something wrong when getting consultant carousel by id', e);
    } finally {
      this.isLoading = false;
    }
  }

  @action setConsultant(consultant: Mobile.Consultant) {
    this.consultant = consultant;
  }
}

export default ThankYouConsultantBoxStore;
