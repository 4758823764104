import http from 'bu.http';
import { Mobile } from 'bu.components-customer-apps';
import configService from './../services/config.service';
import { Config } from '../types/config';

class ConsultantCalendarService {
  config: Config;
  mobileApiUrl: string;
  constructor() {
    this.config = configService.getConfig();
    this.mobileApiUrl = this.config.services.mobileApi;
  }

  getConsultantsCalendar(isPublicServant: boolean) {
    const query = { isPublicServant: String(isPublicServant) };

    return http.get<Mobile.CalendarCarouselAppointmentDate[]>(this.mobileApiUrl,
      `/consultant/appointments?${new URLSearchParams(query)}`);
  }

  getSingleConsultantCalendarWithSubstitutes(consultantId: number) {
    return http.get<Mobile.CalendarCarouselAppointmentDate[]>(this.mobileApiUrl,
      `/consultant/${consultantId}/appointments`);
  }
}

export default new ConsultantCalendarService();
