import { PRODUCT_HOLDING } from 'bu.lookups';
import { webAppLoggingService } from 'bu.logger-client';
import { wait } from 'bu.helpers';

export function tryInitC24App() {
  C24App.onSupportDialogTriggered ??= () => {};

  try {
    // https://confluence.check24.de/display/C24APPS/JavaScript+Interface#JavaScriptInterface-Newversion
    C24App.setProduct({ productSSO: PRODUCT_HOLDING.BU }, async (result, message) => {
      if (!result) {
        webAppLoggingService.warn(
          `C24App.setProduct() error on 1st try (available only on C24 Alpha App): ${message}`
        );

        // Context: https://c24-vorsorge.atlassian.net/browse/VERBU-21611
        // According to the native C24 App code, an error can only happen in case app-settings are not yet loaded;
        // and this is where the Product List is extracted from. Maybe a retry can help recovering from this.
        await wait(2000);

        C24App.setProduct({ productSSO: PRODUCT_HOLDING.BU }, (retriedResult, retriedMessage) => {
          if (!retriedResult) {
            webAppLoggingService.warn(
              `C24App.setProduct() error on 2nd try (available only on C24 Alpha App): ${retriedMessage}`
            );
          }
        });
      }
    });
  } catch (e) {
    webAppLoggingService.error('C24App.setProduct() error', e);
  }
}

export function tryTrackConversionFunnel() {
  C24App.trackConversionFunnel({ productSSO: PRODUCT_HOLDING.BU, voucherCode: null }, (result, message) => {
    if (!result) {
      webAppLoggingService.error(`Conversion funnel tracking failed: ${message}`);
    }
  });
}
