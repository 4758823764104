import http from 'bu.http';
import { isClient } from 'bu.helpers';
import { webAppLoggingService } from 'bu.logger-client';

import configService from './config.service';
import { SsoUserData } from '../types/sso-user-data';
import { ExpiredSessionData } from '../types/expired-session-data';

const AUTH_TIMEOUT_MILLIS = 5000;

class AuthService {
  // **SECURITY WARNING** : if these fields get cached in SSR they will mix different customers' data.
  // There are only meant to be used in CSR.
  #ssoUserData?: SsoUserData;
  #expiredSessionData?: ExpiredSessionData;

  async getUserDataAndExpiredSessionData() {
    if (isClient() && this.#ssoUserData) {
      // Even if the expiredSessionData is cached, we still want to retry fetching the ssoUserData
      return { ssoUserData: this.#ssoUserData, expiredSessionData: this.#expiredSessionData };
    }

    const config = configService.getConfig();
    let ssoUserData: SsoUserData | undefined;
    let expiredSessionData: ExpiredSessionData | undefined;
    try {
      // When SSO API is slow or down, we want to be able to continue in logged-out mode
      ({ ssoUserData, expiredSessionData } = await http
        .get<{ ssoUserData?: SsoUserData; expiredSessionData?: ExpiredSessionData }>(
          config.services.mobileApi,
          '/auth/user/data-and-expired-session-data',
          { timeout: AUTH_TIMEOUT_MILLIS }
        ));
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        webAppLoggingService.warn('Timeout while getting user and expired-session data from the c24session cookie', e);
      } else {
        webAppLoggingService.error('Error while getting user and expired-session data from the c24session cookie', e);
      }
    }

    if (isClient()) {
      this.#ssoUserData = ssoUserData;
      this.#expiredSessionData = expiredSessionData;
    }

    return { ssoUserData, expiredSessionData };
  }

  logout() {
    this.#ssoUserData = undefined;
    this.#expiredSessionData = undefined;

    const config = configService.getConfig();
    return http.post<undefined>(config.services.mobileApi, '/auth/logout', {});
  }
}

export default new AuthService();

