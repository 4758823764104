import { PrefillingSearchData } from '../services/bpm.service';
import { SearchData } from '../types/search-data';
import { StringsOnlyQuery } from '../types/strings-only-query';

function minifyState(dataToMinify: SearchData, mappings: readonly {
  readonly key: keyof SearchData;
  readonly minifiedKey: string;
}[]) {
  const minifiedData: Record<string, string> = {};

  mappings.forEach((mapping) => {
    if (_isNull(dataToMinify[mapping.key])) {
      if (Object.hasOwnProperty.call(dataToMinify, mapping.key)) {
        // This will set values assigned to null or undefined to empty string as otherwise resulting query would
        // contain "null" or "undefined" strings inside, which can cause issues. However, values that are not
        // assigned at all will not be set to empty string as they are not supposed to be present in the query.
        minifiedData[mapping.minifiedKey] = '';
      }
      return;
    }
    minifiedData[mapping.minifiedKey] = String(dataToMinify[mapping.key]);
  });

  return minifiedData;
}

function unminifyState(dataToUnminify: StringsOnlyQuery, mappings:
  readonly { key: keyof SearchData; minifiedKey: string }[]) {
  const unminifiedData: PrefillingSearchData = {};

  mappings.forEach((mapping) => {
    const storedValue = _tryCastToSpecificDataType(dataToUnminify[mapping.minifiedKey]);
    if (!_isNull(storedValue)) {
      unminifiedData[mapping.key] = storedValue;
    }
  });

  return unminifiedData;
}

// As stated in name, the function is supposed to cast anything at runtime.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _tryCastToSpecificDataType(value: any) {
  if (value === '') {
    return value;
  }

  if (_isNull(value) ||
    typeof value !== 'string') {
    return value;
  }

  if (!Number.isNaN(Number(value))) {
    return Number(value);
  }

  if (value === 'true' || value === 'false') {
    return value === 'true';
  }

  return value;
}

function _isNull(data: unknown) {
  return data === null || data === undefined;
}

export {
  minifyState,
  unminifyState
};
