import { action, makeObservable, observable } from 'mobx';
import { scrollToElementIfInViewport } from '../helpers/scroll.helper';
import piwikService from '../services/piwik.service';

class TariffDetailsGradeStore {
  @observable element: Element|null = null;
  elementClassName = 'scrollToElementTargetGrade';
  @observable tariffDetailsTabOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action
  scrollToGradeDetails() {
    if (!this.tariffDetailsTabOpen) {
      this.tariffDetailsTabOpen = true;
      scrollToElementIfInViewport(this.element, this.elementClassName);
    }
  }

  @action
  toggleTariffDetailsTabOpen() {
    this.tariffDetailsTabOpen = !this.tariffDetailsTabOpen;
    piwikService.trackCurrentPageEvent('TariffGradeArrow_Clicked');

    if (!this.tariffDetailsTabOpen) {
      scrollToElementIfInViewport(this.element, this.elementClassName);
    }
  }
}

export default TariffDetailsGradeStore;
