const CUSTOM_VARIABLES_VISIT = {
  13: 'wishlistEntry',
  14: 'offeredGF',
  15: 'b2BPartner',
  16: 'b2BAdPartner',
  18: 'isUserLoggedIn',
  19: 'buRef',
  20: 'visibleConsultantId',
} as const;

export default CUSTOM_VARIABLES_VISIT;
