import { configure } from 'mobx';
import { isClient } from 'bu.helpers';
import { asyncLocalStorageService } from 'bu.http';

import ActiveTariffInfoStore from '../stores/active-tariff-info.store';
import SearchDataStore from '../stores/search-data.store';
import SearchDataReadStore from '../stores/search-data-read.store';
import OnboardingValidationStore from '../stores/onboarding-validation.store';
import TariffStore from '../stores/tariff.store';
import PersonalDataStore from '../stores/personal-data.store';
import InquiryStore from '../stores/inquiry.store';
import TariffListStore from '../stores/tariff-list.store';
import SsoStore from '../stores/sso.store';
import DeviceOutputStore from './../stores/device-output.store';
import ResultFiltersStore from './../stores/result-filters.store';
import TariffDetailsGradeStore from './../stores/tariff-details-grade.store';
import TariffComparisonStore from './../stores/tariff-comparison.store';
import ConsultantCarouselStore from '../stores/consultant-carousel.store';
import PersonalDataValidationStore from './../stores/personal-data-validation.store';

import StoreContainer from './store.container';

import TariffSelectionStore from '../stores/tariff-selection.store';
import EFeedbackStore from '../stores/efeedback.store';
import MinPricePerFilterStore from '../stores/min-price-per-filter.store';
import InsuranceFiltersStore from '../stores/insurance-filters.store';
import ConsultantCalendarStore from '../stores/consultant-calendar.store';
import ThankYouConsultantBoxStore from '../stores/thank-you-consultant-box.store';
import GfBannerStore from '../stores/gf-banner.store';
import UserjourneyStore from '../stores/userjourney.store';

import WishlistStore from '../stores/wishlist.store';
import TariffNavigationStore from '../stores/tariff-navigation.store';

class StoreFactory {
  clientStoreContainer?: StoreContainer;
  constructor() {
    configure({
      enforceActions: 'always',
    });
  }

  _getStoreContainer(): StoreContainer {
    if (isClient()) {
      if (!this.clientStoreContainer) {
        this.clientStoreContainer = new StoreContainer();
      }
      return this.clientStoreContainer;
    }

    const contextStore = asyncLocalStorageService.getStore<{
      storeContainer?: StoreContainer;
    }>();
    if (!contextStore!.storeContainer) {
      contextStore!.storeContainer = new StoreContainer();
    }
    return contextStore!.storeContainer;
  }

  getSearchDataStore() {
    return this._getStoreContainer().getStore(SearchDataStore);
  }

  getSearchDataReadStore() {
    return this._getStoreContainer().getStore(SearchDataReadStore);
  }

  getOnboardingValidationStore() {
    return this._getStoreContainer().getStore(OnboardingValidationStore);
  }

  getDeviceOutputStore() {
    return this._getStoreContainer().getStore(DeviceOutputStore);
  }

  getTariffListStore() {
    return this._getStoreContainer().getStore(TariffListStore);
  }

  getTariffSelectionStore() {
    return this._getStoreContainer().getStore(TariffSelectionStore);
  }

  getTariffStore() {
    return this._getStoreContainer().getStore(TariffStore);
  }

  getTariffComparisonStore() {
    return this._getStoreContainer().getStore(TariffComparisonStore);
  }

  getPersonalDataStore() {
    return this._getStoreContainer().getStore(PersonalDataStore);
  }

  getInquiryStore() {
    return this._getStoreContainer().getStore(InquiryStore);
  }

  getSsoStore() {
    return this._getStoreContainer().getStore(SsoStore);
  }

  getActiveTariffInfoStore() {
    return this._getStoreContainer().getStore(ActiveTariffInfoStore);
  }

  getResultFiltersStore() {
    return this._getStoreContainer().getStore(ResultFiltersStore);
  }

  getTariffDetailsGradeStore() {
    return this._getStoreContainer().getStore(TariffDetailsGradeStore);
  }

  getConsultantCarouselStore() {
    return this._getStoreContainer().getStore(ConsultantCarouselStore);
  }

  getEFeedbackStore() {
    return this._getStoreContainer().getStore(EFeedbackStore);
  }

  getPersonalDataValidationStore() {
    return this._getStoreContainer().getStore(PersonalDataValidationStore);
  }

  getMinPricePerFilterStore() {
    return this._getStoreContainer().getStore(MinPricePerFilterStore);
  }

  getInsuranceFiltersStore() {
    return this._getStoreContainer().getStore(InsuranceFiltersStore);
  }

  getConsultantCalendarStore() {
    return this._getStoreContainer().getStore(ConsultantCalendarStore);
  }

  getThankYouConsultantBoxStore() {
    return this._getStoreContainer().getStore(ThankYouConsultantBoxStore);
  }

  getGfBannerStore() {
    return this._getStoreContainer().getStore(GfBannerStore);
  }

  getUserjourneyStore() {
    return this._getStoreContainer().getStore(UserjourneyStore);
  }

  getWishlistStore() {
    return this._getStoreContainer().getStore(WishlistStore);
  }

  getTariffNavigationStore() {
    return this._getStoreContainer().getStore(TariffNavigationStore);
  }
}

export default new StoreFactory();
