import moment, { Moment } from 'moment-timezone';
import { DATE_FORMAT } from 'bu.formatters';
import { JOB_SITUATIONS, SEARCH_DATA_FIELDS, PUBLIC_SERVANT_JOB_SITUATIONS_VALUE, ValueOf } from 'bu.lookups';
import { SearchData } from '../types/search-data';
import { assertIsNotEmpty } from './validation.helper';
import {
  PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VALIDATION_STATUS
} from '../constants/public-servant-lifetime-appointment-date.constant';

export type ResultOfValidatePublicServantEnteredServiceDate = {
  valid: false;
  status: 'EMPTY'|'INVALID';
} | {
  status?: undefined;
  valid: true;
};

export type ResultOfValidatePublicServantLifetimeAppointmentDate = {
  valid: false;
  status: ValueOf<typeof PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VALIDATION_STATUS>;
} | {
  status?: undefined;
  valid: true;
};

export function isDUVapplicable(jobSituation: ValueOf<typeof JOB_SITUATIONS>|undefined) {
  return jobSituation === JOB_SITUATIONS.BEAMTET;
}

function _momentFormatDate(date: string|undefined) {
  return moment(date,
    DATE_FORMAT.ISO_8601_DATE,
    true
  );
}

export function validatePublicServantLifetimeAppointmentDate(searchData: SearchData):
  ResultOfValidatePublicServantLifetimeAppointmentDate {
  if (!searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]) {
    return { valid: false, status: 'EMPTY' };
  }

  if (!_isLifetimeAppointmentDateValid(searchData)) {
    return { valid: false, status: 'INVALID' };
  }

  if (isLifetimeAppointmentDateHigherThanEnteredServiceDate(searchData)) {
    return {
      valid: false,
      status: PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VALIDATION_STATUS
        .OVER_PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VIOLATED
    };
  }

  return { valid: true };
}

function isLifetimeAppointmentDateHigherThanEnteredServiceDate(searchData: SearchData) {
  const publicServantEnteredServiceDate =
    _momentFormatDate(searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]);

  const publicServantLifetimeAppointmentDate =
    _momentFormatDate(searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]);

  return publicServantEnteredServiceDate > publicServantLifetimeAppointmentDate;
}

function _isLifetimeAppointmentDateValid(searchData: SearchData) {

  const publicServantLifetimeAppointmentDate =
    _momentFormatDate(searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]);

  if (
    !publicServantLifetimeAppointmentDate.isValid()
    || !_isLifetimeAppointmentDateWithinRange(publicServantLifetimeAppointmentDate)) {
    return false;
  }

  if (searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION] &&
    !_validateLifetimeAppointmentDateAgainstInsuranceDate(
      publicServantLifetimeAppointmentDate,
      searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION],
      searchData[SEARCH_DATA_FIELDS.INSURANCE_START]
    )) {
    return false;
  }

  return true;
}

function _isLifetimeAppointmentDateWithinRange(publicServantLifetimeAppointmentDate: Moment) {
  const maxYearsAllowed = 15;
  const minYearAllowed = 1900;
  const today = moment().startOf('day');
  const diff = publicServantLifetimeAppointmentDate.diff(today, 'years', true);
  return publicServantLifetimeAppointmentDate.year() >= minYearAllowed && diff <= maxYearsAllowed;
}

function _validateLifetimeAppointmentDateAgainstInsuranceDate(
  publicServantLifetimeAppointmentDate: Moment, jobSituation: string|undefined, insureanceStart: string) {

  const insureanceStartDate = _momentFormatDate(insureanceStart);

  switch (jobSituation) {
    case PUBLIC_SERVANT_JOB_SITUATIONS_VALUE.BEAMTET_AUF_PROBE:
    case PUBLIC_SERVANT_JOB_SITUATIONS_VALUE.BEAMTET_AUF_WIDERRUF:
      return insureanceStartDate < publicServantLifetimeAppointmentDate;
    case PUBLIC_SERVANT_JOB_SITUATIONS_VALUE.BEAMTET_AUF_LEBENSZEIT:
      return insureanceStartDate >= publicServantLifetimeAppointmentDate;

    default:
      throw new Error(`Invalid public servant job situation ${jobSituation}`);
  }
}

export function isPublicServantEnteredServiceDateYoungEnough(
  dateMoment: Moment
) {
  const maxAgeExclusive = 48 + 1; // means 48 years plus the whole 48th year
  return moment().diff(dateMoment, 'years') < maxAgeExclusive;
}

export function validatePublicServantEnteredServiceDate(
  jobSituation: ValueOf<typeof JOB_SITUATIONS>|undefined,
  publicServantEnteredServiceDate: string|undefined
): ResultOfValidatePublicServantEnteredServiceDate {
  if (!isDUVapplicable(jobSituation)) {
    return { valid: true };
  }

  if (!assertIsNotEmpty(publicServantEnteredServiceDate).isValid) {
    return { valid: false, status: 'EMPTY' };
  }

  const dateMoment = moment(publicServantEnteredServiceDate,
    DATE_FORMAT.ISO_8601_DATE,
    true
  );

  if (!dateMoment.isValid() || !isPublicServantEnteredServiceDateYoungEnough(dateMoment)) {
    return { valid: false, status: 'INVALID' };
  }

  return { valid: true };
}