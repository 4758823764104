import http from 'bu.http';
import { StatusCodes } from 'http-status-codes';
import { SETUP_APPOINTMENT_FAILURE_REASON, ValueOf } from 'bu.lookups';
import { webAppLoggingService } from 'bu.logger-client';

import configService from './../services/config.service';
import { RelevantAppointment } from '../types/relevant-appointment';
import { TimeSlot } from '../types/timeslot';

export type AppointInquiryReturnType = {
  consultantId: number;
  isAlreadyAppointed: boolean;
  errorReason?: undefined;
  isSessionExpired: boolean;
}|{
  errorReason: ValueOf<typeof SETUP_APPOINTMENT_FAILURE_REASON>;
};

class AppointmentService {
  mobileApi: string;
  constructor() {
    this.mobileApi = configService.getConfig().services.mobileApi;
  }

  async setupAppointmentForInquiry({
    inquiryPublicId,
    timeslot,
    isDelayed
  }: {
    inquiryPublicId: string;
    timeslot: TimeSlot;
    isDelayed: boolean;
  }) {
    try {
      const setupAppointmentUrl = isDelayed ?
        `/inquiry/${inquiryPublicId}/setup-appointment-delayed` :
        `/inquiry/${inquiryPublicId}/setup-appointment`;

      return await http.post<AppointInquiryReturnType>(
        this.mobileApi, setupAppointmentUrl, timeslot
      );
    } catch (e) {
      if (e.status === StatusCodes.CONFLICT) {
        return { errorReason: SETUP_APPOINTMENT_FAILURE_REASON.UNAVAILABLE };
      }

      webAppLoggingService.error(
        `Error while setting appointment: ${JSON.stringify({ inquiryPublicId, timeslot, isDelayed })}`,
        e
      );
      return { errorReason: SETUP_APPOINTMENT_FAILURE_REASON.ERROR };
    }
  }

  cancelRelevantInquiryAppointment(inquiryPublicId: string) {
    return http.delete(this.mobileApi, `/inquiry/${inquiryPublicId}/relevant-appointment`);
  }

  getRelevantInquiryAppointment(inquiryPublicId: string) {
    return http.get<RelevantAppointment | undefined>(
      this.mobileApi, `/inquiry/${inquiryPublicId}/relevant-appointment`
    );
  }
}

export default new AppointmentService();
