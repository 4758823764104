import { action, computed, flow, makeObservable, observable, reaction, when } from 'mobx';
import { SortOption, PRODUCT, RESULT_ZERO_SORT_ORDER } from 'bu.lookups';
import http, { CancelTokenSource } from 'bu.http';

import tariffService, { TariffListQueryResult } from '../services/tariff.service';
import storeFactory from '../factories/store.factory';
import bpmService from '../services/bpm.service';
import { Tariff } from '../types/tariff';
import { TariffId } from '../types/tariff-id';
import { SearchData } from '../types/search-data';
import { filterTariffIfEuropaCrossSellingEnabled } from '../helpers/tariff.helper';

class TariffListStore {
  minPricePerFilterStore = storeFactory.getMinPricePerFilterStore();
  insuranceFiltersStore = storeFactory.getInsuranceFiltersStore();
  gfBannerStore = storeFactory.getGfBannerStore();

  @observable tariffs: Tariff[] = [];
  @observable isSpecialOccupation = false;
  @observable _loadingCount = 0;
  @observable isInitialLoading = true;
  @observable isInComparisonMode = false;
  @observable renderNoTariffsFoundCounter = 0;
  @observable campaignTariffs = null;
  @observable resultZeroTariffsInPriorityOrder: Tariff[] = [];

  cancellationToken?: CancelTokenSource;
  minTariffPrice?: number;

  constructor() {
    makeObservable(this);
    reaction(
      () => this.tariffs,
      () => { this.sortResultZeros(); });
  }

  @action
  increaseRenderNoTariffsFoundCounter() {
    this.renderNoTariffsFoundCounter++;
  }

  @action
  sortResultZeros() {
    const resultZeroes = this.tariffs.filter((t) => t.resultZeroType) ?? [];
    resultZeroes.sort((t1, t2) => {
      if (t1.resultZeroType === '') {
        return 1;
      }
      if (t2.resultZeroType === '') {
        return -1;
      }
      return RESULT_ZERO_SORT_ORDER[t1.resultZeroType] - RESULT_ZERO_SORT_ORDER[t2.resultZeroType];
    });
    if (!this.hasDuvTariff()) {
      const resultZeroCount = 2;
      this.resultZeroTariffsInPriorityOrder = resultZeroes.slice(0, resultZeroCount);
      for (const extraResultZero of resultZeroes.slice(resultZeroCount)) {
        this.tariffs.find((t) => t._id === extraResultZero._id)!.resultZeroType = '';
      }
    } else {
      const duvResultZeroCount = 1;
      this.resultZeroTariffsInPriorityOrder = resultZeroes.slice(0, duvResultZeroCount);
    }
  }

  @action
  setIsLoading(isLoading: boolean) {
    if (isLoading) {
      this._loadingCount = Math.max(this._loadingCount + 1, 1); // gte 1
    } else {
      this._loadingCount = Math.max(this._loadingCount - 1, 0); // gte 0
    }

    if (this.isInitialLoading && isLoading) {
      this.isInitialLoading = false;
    }
  }

  @computed get isLoading() {
    return this._loadingCount > 0;
  }

  async waitWhileIsLoading() {
    await when(() => !this.isLoading);
  }

  @action
  setComparisonMode(isInComparisonMode: boolean) {
    this.isInComparisonMode = isInComparisonMode;
  }

  cancelTariffListLoading() {
    if (this.cancellationToken) {
      this.cancellationToken.cancel();
      this.cancellationToken = undefined;
    }
  }

  async loadOrWaitForTariffList(searchData: SearchData) {
    await this.waitWhileIsLoading();
    await this.loadTariffList(searchData);
  }

  @flow *loadTariffList(searchData: SearchData, useCache = false) {
    try {
      const usedSearchData = { ...searchData };
      this.setIsLoading(true);
      this.gfBannerStore.isLoading = true;
      this.cancelTariffListLoading();

      this.cancellationToken = http.getCancelToken();
      const tariffsQueryResult: Awaited<ReturnType<typeof tariffService.getTariffList>> =
        yield tariffService.getTariffList(usedSearchData, this.cancellationToken, useCache);

      this._processTariffsQueryResult(tariffsQueryResult, usedSearchData);
    } finally {
      this.renderNoTariffsFoundCounter = 0;
      this.setIsLoading(false);
    }
  }

  @action
  _processTariffsQueryResult(tariffsQueryResult: TariffListQueryResult, usedSearchData: SearchData) {
    if (!tariffsQueryResult.tariffs.length) {
      bpmService.savePrefillingAndIpss({ searchData: usedSearchData, prefillOnly: true });
      this.tariffs = [];
      return;
    }

    bpmService.savePrefillingAndIpss({
      searchData: usedSearchData,
      prefillOnly: false,
      tariffCount: tariffsQueryResult.tariffs.length,
      minTariffPrice: tariffsQueryResult.minTariffPrice
    });

    const filteredTariffsByInsurances =
      this._getTariffsFilteredByInsuranceFilters(tariffsQueryResult.tariffs, usedSearchData);
    this.tariffs = this._filterTariffsByConsultantOnly(filteredTariffsByInsurances);
    this.tariffs = filterTariffIfEuropaCrossSellingEnabled(this.tariffs, usedSearchData.b2BAdPartner);
    this.isSpecialOccupation = tariffsQueryResult.isSpecialOccupation;
    this.gfBannerStore.loadGfMinPrice(usedSearchData, tariffsQueryResult.minTariffPrice);
    this._setMinPrice(tariffsQueryResult);
  }

  _getTariffsFilteredByInsuranceFilters(tariffs: Tariff[], searchData: SearchData) {
    if (!searchData.insuranceFilters?.length) {
      return tariffs;
    }
    return tariffs.filter((tariff) =>
      searchData.insuranceFilters?.includes(tariff.insurance._id) || tariff.product === PRODUCT.DUV);
  }

  _setMinPrice(tariffsQueryResult: TariffListQueryResult) {
    this.minTariffPrice = tariffsQueryResult.minTariffPrice;
    this.minPricePerFilterStore.setMinPricePerFeatureFilter(tariffsQueryResult.minPricesForFeatureFilters);
    this.insuranceFiltersStore.setMinPricePerInsurance(tariffsQueryResult.minPricesForInsuranceFilters);
  }

  _filterTariffsByConsultantOnly(tariffs: Tariff[]) {
    return tariffs.filter((tariff) => !tariff.isConsultantOnly);
  }

  hasTariffs() {
    return this.tariffs.length > 0;
  }

  getTariffById(tariffId: TariffId) {
    return this.tariffs.find((tariff: Tariff) => tariff._id === tariffId);
  }

  hasAtLeastOneTariffResultZero() {
    return Boolean(this.getResultZeroTariffsAmount() > 0);
  }

  hasDuvTariff() {
    return this.tariffs.some(tariff => tariff.product === PRODUCT.DUV);
  }

  shouldShowExtraResultZerosInList(sortedBy: SortOption) {
    return sortedBy === 'resultZero' && this.hasAtLeastOneTariffResultZero();
  }

  getMaxPriceOfTariffsNonDuv() {
    // for the case when there are no bu tariffs the return will be -Infinity, the component will handle this case
    return Math.max(...this.tariffs.filter((tariff) => tariff.product === PRODUCT.BU)
      .map((tariff) => tariff.netPrice));
  }

  getMaxPriceOfTariffs() {
    return Math.max(...this.tariffs.map((tariff) => tariff.netPrice));
  }

  getMinPriceOfTariffsNonDuv(): number {
    // for the case when there are no bu tariffs the return will be Infinity, the component will handle this case
    return Math.min(
      ...this.tariffs
        .filter((tariff) => tariff.product === PRODUCT.BU)
        .map((tariff) => tariff.netPrice)
    );
  }

  getMinPriceOfTariffs() {
    return Math.min(...this.tariffs.map((tariff) => tariff.netPrice));
  }

  @action
  sortTariffs(sortedBy: SortOption) {
    this.tariffs = tariffService.sortTariffs(this.tariffs, sortedBy);
  }

  getCarouselPositionIndex(showExtraResultZeros: boolean) {
    const basePosition = Math.min(3, this.tariffs.length);
    if (!showExtraResultZeros) {
      return basePosition;
    }

    const resultZeroCount = this.getResultZeroTariffsAmount();
    return Math.max(basePosition - resultZeroCount, 1);
  }

  getResultZeroTariffsAmount(): number {
    return this.tariffs.filter((tariff) => Boolean(tariff.resultZeroType)).length;
  }

  isOneTariff() {
    return this.tariffs.length === 1;
  }

  isOnlyDuvTariff() {
    return Boolean(this.tariffs.find((tariff) => tariff.product === PRODUCT.DUV) && this.isOneTariff());
  }
}

export default TariffListStore;
