import { JOB_SITUATIONS, SORT_OPTIONS, SEARCH_DATA_FIELDS, INDUSTRIES } from 'bu.lookups';
import { date } from 'bu.formatters';
import { SearchData } from '../types/search-data';

class SearchDataFactory {

  createDefaultSearchData(): SearchData {
    return {
      [SEARCH_DATA_FIELDS.OCCUPATION]: '',
      [SEARCH_DATA_FIELDS.BIRTHDAY]: '',
      [SEARCH_DATA_FIELDS.EDUCATION_TYPE]: '',
      [SEARCH_DATA_FIELDS.JOB_SITUATION]: JOB_SITUATIONS.ANGESTELLT,
      [SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT]: 65,
      [SEARCH_DATA_FIELDS.BENEFIT_AMOUNT]: '1000',
      [SEARCH_DATA_FIELDS.INDUSTRY]: INDUSTRIES.SONSTIGE_BRANCHE,
      [SEARCH_DATA_FIELDS.SMOKER]: false,
      [SEARCH_DATA_FIELDS.EXCELLENT_INCREASE_OPTION_WITH_REASON_DESIRED]: false,
      [SEARCH_DATA_FIELDS.DYNAMIC_PREMIUM_FILTER]: '',
      [SEARCH_DATA_FIELDS.TARIFF_GRADE_FILTER]: '',
      [SEARCH_DATA_FIELDS.B2B_PARTNER]: null,
      [SEARCH_DATA_FIELDS.B2B_AD_PARTNER]: null,
      [SEARCH_DATA_FIELDS.SORTED_BY]: SORT_OPTIONS.RESULT_ZERO,
      [SEARCH_DATA_FIELDS.INSURANCE_START]: date.getInsuranceStart(),
      [SEARCH_DATA_FIELDS.FRACTION_OFFICE_WORK]: 50,
      [SEARCH_DATA_FIELDS.STAFF_RESPONSIBILITY]: 0,
      [SEARCH_DATA_FIELDS.FAMILY_STATUS]: 'Keine Angabe',
      [SEARCH_DATA_FIELDS.EXCELLENT_SUSTAINABILITY_DESIRED]: false,
      [SEARCH_DATA_FIELDS.EXCELLENT_STIFTUNG_WARENTEST]: false,
      [SEARCH_DATA_FIELDS.EXCELLENT_CERTIFIED_SICKNESS]: false,
      [SEARCH_DATA_FIELDS.QUICK_BENEFIT_FOR_DREAD_DISEASE]: false,
      [SEARCH_DATA_FIELDS.INSURANCE_FILTERS]: [],
    };
  }
}

export default new SearchDataFactory();
