const PAGES = {
  ONBOARDING_PERSONAL_DATA: '/onboarding-personal-data',
  ONBOARDING_COVERAGE_DATA: '/onboarding-coverage-data',
  ONBOARDING_ADDITIONAL_DATA: '/onboarding-additional-data',
  RESULT: '/result',
  TARIFF_DETAILS: '/tariff-details',
  COMPARISON: '/comparison',
  PERSONAL_DATA: '/personal-data',
  PERSONAL_LOGIN: '/personal-login',
  THANK_YOU: '/thankyou',
  THANK_YOU_ERROR: '/thankyou-error',
  INSURANCE_LIST: '/insurance-list',
  SOCIAL_LOGIN_CLOSING: '/social-login-closing',
  RESUBSCRIBE: '/email/resubscribe/[email]',
  UNSUBSCRIBE: '/email/unsubscribe/[email]',
} as const;

export default PAGES;
