import ValidationState from './validation-state';

const emailRegex = /^[^\s@]+@[\wäÄöÖüÜß]+([\.-]?\w+)*(\.\w{2,})+$/; // eslint-disable-line no-useless-escape
const phoneNumberRegex = /^[^A-Za-zÄäÖöÜüß]{6,20}$/;
const postalCodeRegex = /^\d{5}/;

export function assertIsNotEmpty(value: string|undefined, errorMessage?: string) {
  if (value === undefined || value === null || value.length === 0) {
    return new ValidationState(false, errorMessage);
  }
  return new ValidationState();
}

export function assertIsGreaterOrEqualThan(valueToCompare: number, value: number, errorMessage: string) {
  if (value >= valueToCompare) {
    return new ValidationState();
  }

  return new ValidationState(false, errorMessage);
}

export function assertIsLessThan(valueToCompare: number, value?: number, errorMessage?: string) {
  if (typeof value !== 'undefined' && value < valueToCompare) {
    return new ValidationState();
  }

  return new ValidationState(false, errorMessage);
}

export function isInputPartOfList<TListElement extends { value?: string; text?: string }>(
  value: TListElement['value'], inputList: readonly TListElement[]):boolean {
  return Boolean(inputList.find((input) => {
    const inputValue = input!.value || input.text || input;
    return inputValue === value;
  }));
}

export function assertIsPartOfInputList<TListElement extends { readonly value?: string; readonly text?: string }>(
  value: TListElement['value'], inputList: readonly TListElement[], errorMessage: string) {
  if (!isInputPartOfList(value, inputList)) {
    return new ValidationState(false, errorMessage);
  }

  return new ValidationState();
}

export function assertMinMaxLength(value: string, minLength: number, maxLength: number, errorMessage: string) {
  const countOfChars = value.trim().length;

  if (countOfChars < minLength || countOfChars > maxLength) {
    return new ValidationState(false, errorMessage);
  }

  return new ValidationState();
}

export function assertRegex(value: string, regexPattern: RegExp, errorMessage?: string) {
  const regex = new RegExp(regexPattern);
  const isValid = regex.test(value);

  return new ValidationState(isValid, isValid ? undefined : errorMessage);
}

export function assertDigitsCountRegex(value: string, minimumLengthOfDigits: number, errorMessage?: string) {
  const regex = /\d/g;
  const matchedRegex = value.match(regex);
  const isValid = matchedRegex ? matchedRegex.length >= minimumLengthOfDigits : false;

  return new ValidationState(isValid, isValid ? undefined : errorMessage);
}

export function assertIsValidEmail(value: string, errorMessage?: string) {
  return assertRegex(value, emailRegex, errorMessage);
}

export function assertIsValidPhoneNumber(phoneNumber: string, errorMessage?: string) {
  return assertRegex(phoneNumber, phoneNumberRegex, errorMessage) &&
    assertDigitsCountRegex(phoneNumber, 5, errorMessage);
}

export function assertIsValidPostalCode(postalCode: string, errorMessage?: string) {
  return assertRegex(postalCode, postalCodeRegex, errorMessage);
}

export function assertIsTrue(value: unknown, errorMessage?: string) {
  if (value === true) {
    return new ValidationState();
  }
  return new ValidationState(false, errorMessage);
}

