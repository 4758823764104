import React, { useEffect } from 'react';
import App from 'next/app';
import { webAppLoggingService } from 'bu.logger-client';
import moment from 'moment-timezone';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'moment/locale/de';
import Head from 'next/head';
import { isClient } from 'bu.helpers';
import { asyncLocalStorageService } from 'bu.http';
import { getCookie } from 'cookies-next';

import configService from '../services/config.service';
import { useInit } from '../hooks/useInit';
import deviceOutputService from '../services/device-output.service';
import { convertQueryToStringsOnlyQuery } from '../helpers/convert-query.helper';
import { tryInitC24App } from '../helpers/c24-app.helper';
import { C24_APP_VERSION_COOKIE } from '../constants/cookies.constant';
import authService from '../services/auth.service';
import { BuAppContext, BuAppProps } from '../types/next-types';
import storeFactory from '../factories/store.factory';

import '../helpers/polyfill.helper';

// We want the ESLint to pass without building first
// eslint-disable-next-line import/no-unresolved
import '../styles.less';
import { toggleTapBar } from '../helpers/tap-bar.helper';

moment.locale('de');
moment.tz.setDefault('Europe/Berlin');

function initLogging({ deviceOutput, c24AppVersion }: { deviceOutput: string; c24AppVersion: string | undefined }) {
  const config = configService.getConfig();
  webAppLoggingService.init({
    apiBaseUrl: config.services.mobileApi,
    appName: config.name,
    deviceOutput,
    c24AppVersion,
  });
}

export default function BuMobileApp(
  props: BuAppProps & { deviceOutput: string; c24AppVersion: string | undefined; pathname: string },
) {
  const deviceOutputStore = storeFactory.getDeviceOutputStore();

  useInit(() => {
    deviceOutputStore.setDeviceOutput(props.deviceOutput);
    initLogging(props);
    if (isClient()) {
      tryInitC24App();
      window.history.scrollRestoration = 'manual';
    }
  });

  useEffect(() => {
    if (props.deviceOutput === 'app') {
      return;
    }

    toggleTapBar(props.pathname);
  }, [props.pathname, props.deviceOutput]);

  const { Component, pageProps, ssoUserData, expiredSessionData, deviceOutput } = props;

  return (
    <React.Fragment>
      <Head>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
        />
        <title>Berufsunf&auml;higkeitsversicherung Vergleich</title>
      </Head>
      <Component {...{ ...pageProps, ssoUserData, expiredSessionData, deviceOutput }} />
    </React.Fragment>
  );
}

BuMobileApp.getInitialProps = (appContext: BuAppContext) => {
  const {
    ctx: { req, res, query, asPath: pathWithSearch, pathname },
  } = appContext;
  const stringsOnlyQuery = convertQueryToStringsOnlyQuery(query);

  return asyncLocalStorageService.run(
    {
      pathname,
      query: stringsOnlyQuery,
      req,
      pathWithSearch,
      webAppConfig: configService.getConfig(),
    },
    async () => {
      const deviceOutput = deviceOutputService.setAndGetDeviceOutputCookie(stringsOnlyQuery, req, res);
      const c24AppVersion = getCookie(C24_APP_VERSION_COOKIE, { req });

      initLogging({ deviceOutput, c24AppVersion });
      const { ssoUserData, expiredSessionData } = await authService.getUserDataAndExpiredSessionData();

      // Making additional variables available in the Pages getInitialProps' "ctx"
      appContext.ctx.ssoUserData = ssoUserData;
      appContext.ctx.expiredSessionData = expiredSessionData;
      appContext.ctx.c24AppVersion = c24AppVersion;
      appContext.ctx.deviceOutput = deviceOutput;

      // Making additional variables available in the Pages props
      return {
        ...(await App.getInitialProps(appContext)),
        c24AppVersion,
        ssoUserData,
        expiredSessionData,
        pathname,
        deviceOutput,
      };
    },
  );
};
