import http from 'bu.http';
import { PRODUCT } from 'bu.lookups';
import configService from './config.service';

class UserjourneyService {
  mobileApiUrl: string;

  constructor() {
    const config = configService.getConfig();
    this.mobileApiUrl = config.services.mobileApi;
  }

  createUserjourney(isDuv: boolean, deviceOutput: string|undefined) {
    return http.post(this.mobileApiUrl, '/userjourneys', {
      product: isDuv ? PRODUCT.DUV : PRODUCT.BU,
      eventTypeName: 'comparison',
      deviceOutput: deviceOutput || 'mobile'
    });
  }
}

export default new UserjourneyService();
