import { SsoUserData } from '../types/sso-user-data';

function isPointsParticipantWithoutVersicherungPlus(
  ssoUserData: SsoUserData | undefined,
  isPointsParticipantFromUnifiedLoginData: boolean
): boolean {
  if (!ssoUserData) {
    return false;
  }
  const isInPointsProgram = ssoUserData.points === 'yes' || isPointsParticipantFromUnifiedLoginData;
  return isInPointsProgram && ssoUserData.isVersicherungPlusMember === 'no';
}

export { isPointsParticipantWithoutVersicherungPlus };
