import { action, flow, makeObservable, observable } from 'mobx';
import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import { isNullishOrEmpty } from 'bu.helpers';

import { validateBirthday } from '../helpers/birthday.helper';
import ValidationState from '../helpers/validation-state';
import {
  isDUVapplicable,
  validatePublicServantEnteredServiceDate,
  validatePublicServantLifetimeAppointmentDate
} from '../helpers/duv.helper';
import {
  assertIsGreaterOrEqualThan,
  assertIsNotEmpty,
  assertIsPartOfInputList,
  assertIsTrue,
  assertIsLessThan
} from '../helpers/validation.helper';
import * as ERROR_MESSAGES from '../constants/error-messages.constant';
import BENEFIT_AMOUNT from '../constants/benefit-amount.constant';
import { PAYGRADES } from '../constants/public-servant-pay-grade.constant';

import storeFactory from '../factories/store.factory';
import searchDataOptionsFactory from '../factories/search-data-options.factory';

import occupationService from '../services/occupation.service';
import piwikService from '../services/piwik.service';

class OnboardingValidationStore {
  searchDataStore = storeFactory.getSearchDataStore();

  @observable occupation = new ValidationState();
  @observable jobSituation = new ValidationState();
  @observable birthday = new ValidationState();
  @observable educationType = new ValidationState();
  @observable benefitAmount = new ValidationState();
  @observable publicServantExperienceYears = new ValidationState();
  @observable publicServantJobSituation = new ValidationState();
  @observable publicServantCareerGroup = new ValidationState();
  @observable publicServantEnteredServiceDate = new ValidationState();
  @observable publicServantLifetimeAppointmentDate = new ValidationState();
  @observable publicServantPayGrade = new ValidationState();

  constructor() {
    makeObservable(this);
  }

  @flow *validatePersonalDataOnboardingInputs() {
    let isValid = this.validatePublicServantExperienceYears();
    isValid = this.validatePublicServantJobSituation() && isValid;
    isValid = this.validatePublicServantCareerGroup() && isValid;
    isValid = this.validatePublicServantEnteredServiceDate() && isValid;
    isValid = this.validatePublicServantLifetimeAppointmentDate() && isValid;
    isValid = this.validatePublicServantPayGrade() && isValid;
    isValid = this.validateEducationType() && isValid;
    isValid = this.validateBirthday() && isValid;
    isValid = this.validateJobSituation() && isValid;
    isValid = this.validateBenefitAmount() && isValid;
    const isOccupationValid: Awaited<ReturnType<this['validateOccupation']>> = yield this.validateOccupation();
    isValid = isOccupationValid && isValid;
    return isValid;
  }

  async validateAll() {
    return this.isPublicServantExperienceYearsValid().isValid
      && this.isPublicServantJobSituationValid().isValid
      && this.isPublicServantCareerGroupValid().isValid
      && this.isPublicServantEnteredServiceDate().isValid
      && this.isPublicServantLifetimeAppointmentDateValid().isValid
      && this.isPublicServantPayGradeValid().isValid
      && this.isBirthdayValid().isValid
      && this.isJobSituationValid().isValid
      && this.isEducationTypeValid().isValid
      && (this.benefitAmount.isValid && this.isBenefitAmountValid().isValid)
      && (await this.isOccupationValid()).isValid;
  }

  isBenefitAmountValid(amount = this.searchDataStore.searchData.benefitAmount) {
    return assertIsGreaterOrEqualThan(
      BENEFIT_AMOUNT.MIN_BENEFIT_AMOUNT,
      Number(amount),
      ERROR_MESSAGES.INVALID_BENEFIT_AMOUNT.UNDER_LIMIT
    );
  }

  isEducationTypeValid() {
    return assertIsNotEmpty(this.searchDataStore.searchData.educationType,
      ERROR_MESSAGES.INVALID_EDUCATION_TYPE.EMPTY);
  }

  async isOccupationValid() {
    const INVALID_OCCUPATION = ERROR_MESSAGES.INVALID_OCCUPATION;
    const occupation = this.searchDataStore.searchData.occupation;

    if (!occupation) {
      return assertIsNotEmpty(undefined, INVALID_OCCUPATION.EMPTY);
    }

    let validationResult = assertIsNotEmpty(occupation, INVALID_OCCUPATION.EMPTY);
    if (validationResult.isValid) {
      const occupationIsValid = await occupationService.occupationIsValid(occupation);
      validationResult = assertIsTrue(occupationIsValid, INVALID_OCCUPATION.NOT_IN_THE_LIST);
    }

    return validationResult;
  }

  isJobSituationValid() {
    return assertIsPartOfInputList(this.searchDataStore.searchData.jobSituation,
      searchDataOptionsFactory.searchDataOptions.mandatoryOptions.jobSituation,
      ERROR_MESSAGES.INVALID_JOB_SITUATION.NOT_SELECTED);
  }

  isBirthdayValid() {
    const { valid, status } = validateBirthday(this.searchDataStore.searchData.birthday);
    return new ValidationState(valid, status === undefined ? undefined : ERROR_MESSAGES.INVALID_BIRTHDAY[status]);
  }

  @action validateJobSituation() {
    this.jobSituation = this.isJobSituationValid();
    return this.jobSituation.isValid;
  }

  @action validateBenefitAmount(amount = this.searchDataStore.searchData.benefitAmount): boolean {
    this.benefitAmount = this.isBenefitAmountValid(amount);
    return this.benefitAmount.isValid;
  }

  @action validateEducationType() {
    this.educationType = this.isEducationTypeValid();
    return this.educationType.isValid;
  }

  @flow *validateOccupation() {
    this.occupation = yield this.isOccupationValid();
    if (!this.occupation.isValid) {
      piwikService.trackCurrentPageEvent('OccupationError');
    }
    return this.occupation.isValid;
  }

  @action validateBirthday() {
    this.birthday = this.isBirthdayValid();
    return this.birthday.isValid;
  }

  isPublicServantExperienceYearsValid() {
    if (isDUVapplicable(this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])) {
      const value = this.searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS];
      if (isNullishOrEmpty(value)) {
        return new ValidationState(false, ERROR_MESSAGES.PUBLIC_SERVANT_EXPERIENCE_YEARS.EMPTY);
      }

      if (!Number.isInteger(value)) {
        return new ValidationState(false, ERROR_MESSAGES.PUBLIC_SERVANT_EXPERIENCE_YEARS.INVALID);
      }

      return assertIsLessThan(
        100,
        value,
        ERROR_MESSAGES.PUBLIC_SERVANT_EXPERIENCE_YEARS.INVALID
      );
    }
    return new ValidationState();
  }

  @action validatePublicServantExperienceYears() {
    this.publicServantExperienceYears = this.isPublicServantExperienceYearsValid();
    return this.publicServantExperienceYears.isValid;
  }

  isPublicServantJobSituationValid() {
    if (
      isDUVapplicable(this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])
      && !this.searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION]
    ) {
      return new ValidationState(false, ERROR_MESSAGES.PUBLIC_SERVANT_JOB_SITUATION.EMPTY);
    }
    return new ValidationState();
  }

  @action validatePublicServantJobSituation() {
    this.publicServantJobSituation = this.isPublicServantJobSituationValid();
    return this.publicServantJobSituation.isValid;
  }

  isPublicServantCareerGroupValid() {
    if (
      isDUVapplicable(this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])
      && !this.searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP]
    ) {
      return new ValidationState(false, ERROR_MESSAGES.PUBLIC_SERVANT_CAREER_GROUP.EMPTY);
    }
    return new ValidationState();
  }

  @action validatePublicServantCareerGroup() {
    this.publicServantCareerGroup = this.isPublicServantCareerGroupValid();
    return this.publicServantCareerGroup.isValid;
  }

  isPublicServantEnteredServiceDate() {
    const { valid, status } = validatePublicServantEnteredServiceDate(
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]
    );

    if (!valid) {
      return new ValidationState(false, ERROR_MESSAGES.PUBLIC_SERVANT_ENTERED_SERVICE_DATE[status]);
    }

    return new ValidationState();
  }

  @action validatePublicServantEnteredServiceDate() {
    this.publicServantEnteredServiceDate = this.isPublicServantEnteredServiceDate();
    return this.publicServantEnteredServiceDate.isValid;
  }

  isPublicServantLifetimeAppointmentDateValid() {
    if (isDUVapplicable(this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])) {
      const { valid, status } = validatePublicServantLifetimeAppointmentDate(
        this.searchDataStore.searchData
      );
      return new ValidationState(valid,
        valid ? undefined : ERROR_MESSAGES.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE[status]
      );
    }
    return new ValidationState();
  }

  @action validatePublicServantLifetimeAppointmentDate() {
    this.publicServantLifetimeAppointmentDate = this.isPublicServantLifetimeAppointmentDateValid();
    return this.publicServantLifetimeAppointmentDate.isValid;
  }

  isPublicServantPayGradeValid() {
    const payGrade = this.searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE];
    if (
      isDUVapplicable(this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])
      && (!payGrade || !PAYGRADES.includes(payGrade))
    ) {
      return new ValidationState(false, ERROR_MESSAGES.PUBLIC_SERVANT_PAY_GRADE.EMPTY);
    }
    return new ValidationState();
  }

  @action validatePublicServantPayGrade() {
    this.publicServantPayGrade = this.isPublicServantPayGradeValid();
    return this.publicServantPayGrade.isValid;
  }

}

export default OnboardingValidationStore;
