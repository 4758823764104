import http from 'bu.http';
import { webAppLoggingService } from 'bu.logger-client';
import { strict as assert } from 'assert';
import { Product } from 'bu.lookups';

import configService from './config.service';
import userAgentService from './user-agent.service';
import uaParserService from './ua-parser.service';
import deviceOutputService from './device-output.service';
import { AssignedConsultantInfo } from '../types/assigned-consultant-info';
import { Config } from '../types/config';
import { SearchData } from '../types/search-data';
import { CleanedUpTariff, Tariff } from '../types/tariff';
import { PersonalData } from '../types/personal-data';
import { InquiryResponse, WebInquiry } from '../types/inquiry';

type InitInquiryProps = {
  searchData: SearchData;
  tariff: Tariff;
  insuredPerson: PersonalData;
  requestedVersicherungPlusMembership: boolean;
};

class InquiryService {
  config: Config;
  constructor() {
    this.config = configService.getConfig();
  }

  initInquiry({
    searchData,
    tariff,
    insuredPerson,
    requestedVersicherungPlusMembership,
  }: InitInquiryProps): WebInquiry {

    const inquiry = {
      searchData: searchData,
      tariff: this._cleanupTariff(tariff),
      insuredPerson: insuredPerson,
      tracking: this._getTrackingObject(this.config.buRef ?? ''),
      product: tariff.product,
      foreignReferralLink: searchData.foreignReferralLink,
      requestedVersicherungPlusMembership,
    };
    if (tariff.savings && typeof tariff.regularPrice === 'number') {
      inquiry.tariff.netPrice = tariff.regularPrice;
    }

    return inquiry;
  }

  getPrefixedInquiryId(id: number, product: Product) {
    return `${product.toUpperCase()}-${id}`;
  }

  saveInquiry(inquiry: WebInquiry): Promise<InquiryResponse> {
    return http.post<InquiryResponse>(this.config.services.mobileApi, '/inquiries', inquiry);
  }

  async getInquiryAssignedConsultantPersonalInfo(inquiryPublicId: string): Promise<AssignedConsultantInfo> {
    const response = await http.get<{
      consultantId: number;
      firstName: string;
      lastName: string;
      salutation: string;
    } | null>(
      this.config.services.mobileApi,
      `/inquiries/${inquiryPublicId}/consultant/personal-info`
    );

    assert(response, '/consultant/personal-info returned null');

    return {
      id: response.consultantId,
      name: `${response.firstName} ${response.lastName}`,
      salutation: response.salutation
    };
  }

  _cleanupTariff(tariff: Tariff): CleanedUpTariff {
    const {
      // we just declare these variables to exclude them from the tariff
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      categories, usps, testReports, resultZero,
      ...cleanedUpTariff
    } = tariff;
    // remove result zero whitelisting config
    return cleanedUpTariff;
  }

  _getTrackingObject(buRef: string) {
    return {
      userAgent: userAgentService.getUserAgent(),
      deviceType: uaParserService.getDeviceType(),
      deviceOutput: deviceOutputService.getDeviceOutput() || 'mobile',
      buRef: buRef ?? ''
    };
  }

  async getHardBlacklistStatus(inquiryPublicId: string, timeoutMillis = 1000):
    Promise<{ leadExists: boolean; isHardBlacklisted: boolean }> {
    const FAILURE_RESPONSE = { leadExists: true, isHardBlacklisted: false };

    try {
      return await http.get(
        this.config.services.mobileApi,
        `/leads/blacklist-status/${inquiryPublicId}`,
        { timeout: timeoutMillis }
      );
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        webAppLoggingService.warn(
          `Timeout of ${timeoutMillis} expired while checking the hard-blacklist status of inquiry ${inquiryPublicId}`,
          e
        );
        return FAILURE_RESPONSE;
      }

      webAppLoggingService.error(`Error while checking the hard-blacklist status of inquiry ${inquiryPublicId}`, e);
      return FAILURE_RESPONSE;
    }
  }
}

export default new InquiryService();
