import { action, flow, makeObservable, observable } from 'mobx';

import inquiryService from './../services/inquiry.service';
import storeFactory from '../factories/store.factory';
import { getIndexByTariffIdFromSessionStorage } from '../helpers/index-session-storage.helper';
import { Inquiry } from '../types/inquiry';
import { Tariff } from '../types/tariff';
import { tryTrackConversionFunnel } from '../helpers/c24-app.helper';

class InquiryStore {
  searchDataStore = storeFactory.getSearchDataStore();
  personalDataStore = storeFactory.getPersonalDataStore();
  ssoStore = storeFactory.getSsoStore();

  @observable inquiry?: Inquiry;

  constructor() {
    makeObservable(this);
  }

  getPrefixedInquiryId() {
    return inquiryService.getPrefixedInquiryId(this.inquiry!._id, this.inquiry!.product);
  }

  @action setInquiry(inquiry: Inquiry) {
    this.inquiry = inquiry;
  }

  @action resetInquiry() {
    this.inquiry = undefined;
  }

  @flow *collectAndSendInquiry(tariff: Tariff) {
    tariff.positionInList = getIndexByTariffIdFromSessionStorage(tariff._id);

    const insuredPerson = {
      ...this.personalDataStore.personalData,
      isPointsParticipant: this.ssoStore.isUserInCheck24PointsProgram()
    };

    const webInquiry = inquiryService.initInquiry({
      searchData: this.searchDataStore.searchData,
      tariff,
      insuredPerson,
      requestedVersicherungPlusMembership: this.personalDataStore.requestedVersicherungPlusMembership
    });

    const response: Awaited<ReturnType<typeof inquiryService.saveInquiry>> =
      yield inquiryService.saveInquiry(webInquiry);

    if (!response.errorMessage && response.inquiryId && response.inquiryPublicId) {
      this.inquiry = {
        ...webInquiry,
        _id: response.inquiryId,
        inquiryPublicId: response.inquiryPublicId
      };

      tryTrackConversionFunnel();
    }

    return response;
  }
}

export default InquiryStore;
