import remove from 'lodash/remove';
import { action, flow, makeObservable, observable } from 'mobx';
import { strict as assert } from 'assert';

import { ValueOf } from 'bu.lookups';
import piwikService from '../services/piwik.service';
import tariffService from '../services/tariff.service';

import { showDifferences } from '../helpers/comparison-differences.helper';
import { removeIndexByTariffIdFromSessionStorage } from '../helpers/index-session-storage.helper';

import FEATURES_TOGGLE_BUTTON, { FEATURES_TOGGLE_PIWIK_EVENTS } from '../constants/features-toggle-button.constant';
import { SearchData } from '../types/search-data';
import { TariffId } from '../types/tariff-id';
import { Tariff, TariffCategory, TariffFeature } from '../types/tariff';

class TariffComparisonStore {
  @observable tariffs: Tariff[] = [];
  @observable isLoading = false;
  @observable comparisonView: ValueOf<typeof FEATURES_TOGGLE_BUTTON> = FEATURES_TOGGLE_BUTTON.IMPORTANT;

  constructor() {
    makeObservable(this);
  }

  @flow *loadTariffs(searchData: SearchData, tariffIds: TariffId[]) {
    try {
      this.isLoading = true;
      this.tariffs = yield tariffService.getSelectedTariffs(searchData, tariffIds);
      assert(this.tariffs?.length, `Tried to load tariff(s) "${tariffIds.join(', ')}" but no tariffs were found.`);
    } finally {
      this.isLoading = false;
    }
  }

  @action unselect(tariff: Tariff) {
    remove(this.tariffs, (t) => t._id === tariff._id);
    removeIndexByTariffIdFromSessionStorage(tariff._id);
  }

  getAllCategoriesWithFeatures() {
    const featureCategories = this.tariffs[0].categories;
    if (this.comparisonView === FEATURES_TOGGLE_BUTTON.ALL) {
      return featureCategories.map((category) => ({
        ...category,
        features: category.features
      }));
    }

    if (this.comparisonView === FEATURES_TOGGLE_BUTTON.DIFFERENT && this.tariffs.length > 1) {
      return showDifferences(this.tariffs);
    }

    return featureCategories.map((category: TariffCategory) => ({
      ...category,
      features: category.features.filter((feature: TariffFeature) => feature.isImportant)
    })
    ).filter((category: TariffCategory) => category.features.length > 0);

  }

  getCategoryForAllTariffs(category: TariffCategory) {
    return this.tariffs.map(tariff => tariff.categories).flat()
      .filter(tariffCategory => tariffCategory.title === category.title);
  }

  @action
  changeComparisonView(comparisonView: ValueOf<typeof FEATURES_TOGGLE_BUTTON>,
    piwikEvent: ValueOf<typeof FEATURES_TOGGLE_PIWIK_EVENTS>) {
    if (comparisonView === FEATURES_TOGGLE_BUTTON.DIFFERENT && this.tariffs.length < 2) {
      return;
    }

    this.comparisonView = comparisonView;
    piwikService.trackCurrentPageEvent(piwikEvent);
  }
}

export default TariffComparisonStore;
