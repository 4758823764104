import scrollToElement from 'scroll-to-element';

let iosScrollPosition = 0;

function scrollToTopOfResultPage() {
  scrollTo('.ResultPage');
}

function scrollToTopOfComparisonPage() {
  scrollTo('.ComparisonPage');
}

function scrollToFirstInvalidElement() {
  scrollTo('.scrollToElementTarget');
}

function scrollTo(selector: string, offset = -50) {
  const scrollOptions = {
    offset,
    ease: 'linear',
    duration: 750
  };

  setTimeout(() => scrollToElement(selector, scrollOptions), 1);
}

function scrollToTop() {
  scrollTo('.c24m-page', 0);
}

function isInViewPort(element: Element|null, offset = 0) {
  if (!element) {
    return false;
  }
  const top = element.getBoundingClientRect().top;
  return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
}

function scrollToElementIfInViewport(element: Element|null, elementClassName: string, offset = -200, duration = 500) {
  if (!isInViewPort(element)) {
    setTimeout(() => {
      scrollToElement(`.${elementClassName}`, {
        offset: offset,
        ease: 'linear',
        duration: duration
      });
    }, 1);
  }
}

function enableGlobalScroll(document: Document, isIOS: boolean, isApp: boolean) {
  if (!document) {
    return;
  }

  if (isIOS) {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    document.body.scrollTo(0, iosScrollPosition);

    if (!isApp) {
      document.body.style.removeProperty('position');
    }

    return;
  }

  document.body.style.overflow = '';
}

function disableGlobalScroll(document: Document, isIOS: boolean, isApp: boolean) {
  if (!document) {
    return;
  }

  if (isIOS) {
    iosScrollPosition = window.pageYOffset;
    document.body.style.overflow = 'hidden';
    document.body.style.top = `-${iosScrollPosition}px`;
    document.body.style.width = '100%';

    if (!isApp) {
      document.body.style.position = 'fixed';
    }

    return;
  }

  document.body.style.overflow = 'hidden';
}

function enableScrollForUnifiedLoginLayer() {
  if (window && window.document && window.document.getElementById('MainContainer')) {
    window.document.getElementById('MainContainer')!.style.removeProperty('overflow');
  }
}

function disableScrollForUnifiedLoginLayer() {
  if (window && window.document && window.document.getElementById('MainContainer')) {
    window.document.getElementById('MainContainer')!.style.overflow = 'hidden';
  }
}

export {
  scrollToTopOfResultPage,
  scrollToTopOfComparisonPage,
  scrollToFirstInvalidElement,
  scrollTo,
  scrollToTop,
  scrollToElementIfInViewport,
  enableGlobalScroll,
  disableGlobalScroll,
  enableScrollForUnifiedLoginLayer,
  disableScrollForUnifiedLoginLayer,
};
