import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import { reaction } from 'mobx';
import debounce from 'lodash/debounce';
import storeFactory from '../factories/store.factory';
import bpmService from '../services/bpm.service';

class SearchDataReadStore {
  searchDataStore = storeFactory.getSearchDataStore();
  onboardingValidationStore = storeFactory.getOnboardingValidationStore();

  async validateBpmFields() {
    return this.onboardingValidationStore.isBirthdayValid().isValid &&
    this.onboardingValidationStore.isEducationTypeValid().isValid &&
    this.onboardingValidationStore.isBenefitAmountValid().isValid &&
    this.onboardingValidationStore.isJobSituationValid().isValid &&
      (await this.onboardingValidationStore.isOccupationValid()).isValid;
  }

  savePrefillingOnBpmReaction() {
    return reaction(() => [
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.BIRTHDAY],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.OCCUPATION],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.EDUCATION_TYPE],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.BENEFIT_AMOUNT],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.BENEFIT_AGE_LIMIT],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.INDUSTRY],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.FAMILY_STATUS],
      this.searchDataStore.searchData[SEARCH_DATA_FIELDS.SMOKER]
    ], debounce(async () => {
      if (await this.validateBpmFields()) {
        await bpmService.savePrefillingAndIpss({ searchData: this.searchDataStore.searchData,
          prefillOnly: true });
      }
    }, 500));
  }

}

export default SearchDataReadStore;
