import { LRUCache } from 'lru-cache';
import sha1 from 'sha1';
import { Insurance, Tariff } from '../types/tariff';
import { TariffListQueryResult } from './tariff.service';

class TariffCachingService {
  insurancePriceCache = new LRUCache<string, Insurance[]>({ ttl: 60 * 60 * 1000, max: 20 });
  minGfPriceCache = new LRUCache<string, number>({ ttl: 60 * 60 * 1000, max: 20 });
  resultListCache = new LRUCache<string, TariffListQueryResult>({ ttl: 60 * 60 * 1000, max: 20 });
  tariffCache = new LRUCache<string, Tariff | ''>({ ttl: 60 * 60 * 1000, max: 30 });
  selectedTariffsCache = new LRUCache<string, Tariff[]>({ ttl: 60 * 60 * 1000, max: 30 });

  cacheResultList(variables: Record<string, unknown>, resultList: TariffListQueryResult) {
    this.resultListCache.set(this._getHashString(variables), resultList);
  }

  cacheTariff(variables: Record<string, unknown>, tariff: Tariff | '') {
    this.tariffCache.set(this._getHashString(variables), tariff);
  }

  cacheInsurancePrice(variables: Record<string, unknown>, price: Insurance[]) {
    this.insurancePriceCache.set(this._getHashString(variables), price);
  }

  cacheMinGfPrice(variables: Record<string, unknown>, price: number) {
    this.minGfPriceCache.set(this._getHashString(variables), price);
  }

  cacheSelectedTariffs(variables: Record<string, unknown>, tariffs: Tariff[]) {
    this.selectedTariffsCache.set(this._getHashString(variables), tariffs);
  }

  getResultList(variables: Record<string, unknown>) {
    return this.resultListCache.get(this._getHashString(variables));
  }

  getTariff(variables: Record<string, unknown>) {
    return this.tariffCache.get(this._getHashString(variables));
  }

  getInsurancePrice(variables: Record<string, unknown>) {
    return this.insurancePriceCache.get(this._getHashString(variables));
  }

  getMinGfPrice(variables: Record<string, unknown>) {
    return this.minGfPriceCache.get(this._getHashString(variables));
  }

  getSelectedTariffs(variables: Record<string, unknown>): Tariff[] | undefined {
    return this.selectedTariffsCache.get(this._getHashString(variables));
  }

  _getHashString(variables: Record<string, unknown>) {
    return sha1(JSON.stringify(variables));
  }
}

export default new TariffCachingService();
