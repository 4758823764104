import { sessionStorage } from 'js-storage';
import remove from 'lodash/remove';

import SESSION_STORAGE_KEYS from '../constants/session-storage-keys.constant';
import { TariffId } from '../types/tariff-id';

type TariffIndex = {
  tariffId: TariffId;
  tariffIndex: number|undefined;
}

function saveIndexToSessionStorage(tariffId: TariffId, index: number | undefined) {
  const indexArray: TariffIndex[] = sessionStorage.get(SESSION_STORAGE_KEYS.STORAGE_INDEX) || [];
  if (indexArray.findIndex((obj) => obj.tariffId === tariffId) !== -1) {
    return;
  }
  indexArray.push({ tariffId: tariffId, tariffIndex: index });
  sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_INDEX, JSON.stringify(indexArray));
}

function removeIndexByTariffIdFromSessionStorage(tariffId: TariffId) {
  const indexArray: TariffIndex[] = sessionStorage.get(SESSION_STORAGE_KEYS.STORAGE_INDEX) || [];
  remove(indexArray, (obj) => obj.tariffId === tariffId);
  sessionStorage.set(SESSION_STORAGE_KEYS.STORAGE_INDEX, JSON.stringify(indexArray));
}

function getIndexByTariffIdFromSessionStorage(tariffId: TariffId) {
  const indexArray: TariffIndex[] = sessionStorage.get(SESSION_STORAGE_KEYS.STORAGE_INDEX) || [];
  const tariffIdIndexPair = indexArray.find((obj) => obj.tariffId === tariffId);
  return tariffIdIndexPair ? tariffIdIndexPair.tariffIndex : undefined;
}

function cleanIndexesFromSessionStorage() {
  sessionStorage.remove(SESSION_STORAGE_KEYS.STORAGE_INDEX);
}

function replaceIndexesWithSingleIndex(tariffId: TariffId, index: number|undefined) {
  cleanIndexesFromSessionStorage();
  saveIndexToSessionStorage(tariffId, index);
}

export {
  saveIndexToSessionStorage,
  removeIndexByTariffIdFromSessionStorage,
  replaceIndexesWithSingleIndex,
  getIndexByTariffIdFromSessionStorage,
  cleanIndexesFromSessionStorage
};
