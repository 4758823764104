import { action, flow, makeObservable, observable } from 'mobx';

import emailService from '../services/email.service';
import storeFactory from './../factories/store.factory';

import {
  assertIsNotEmpty,
  assertIsPartOfInputList,
  assertIsTrue,
  assertIsValidEmail,
  assertIsValidPhoneNumber,
  assertIsValidPostalCode,
  assertMinMaxLength
} from '../helpers/validation.helper';
import ValidationState from '../helpers/validation-state';
import * as ERROR_MESSAGES from './../constants/error-messages.constant';

class PersonalDataValidationStore {
  @observable isEmailAssignedToSuperAccount = false;
  @observable salutationValidationState = new ValidationState();
  @observable firstNameValidationState = new ValidationState();
  @observable lastNameValidationState = new ValidationState();
  @observable emailValidationState = new ValidationState();
  @observable phoneNumberValidationState = new ValidationState();
  @observable postalCodeValidationState = new ValidationState();
  @observable cityValidationState = new ValidationState();
  @observable streetValidationState = new ValidationState();
  @observable houseNumberValidationState = new ValidationState();
  @observable agreementToContactFromAdvisorValidationState = new ValidationState();

  ssoStore = storeFactory.getSsoStore();
  personalDataStore = storeFactory.getPersonalDataStore();

  constructor() {
    makeObservable(this);
  }

  @flow *validate() {
    this.validateSalutation();
    this.validateFirstName();
    this.validateLastName();
    yield this.validateEmail();
    this.validatePhoneNumber();
    this.validatePostalCode();
    this.validateCity();
    this.validateStreet();
    this.validateHouseNumber();
    this.validateAgreementToContactFromAdvisor();

    return this.salutationValidationState.isValid &&
      this.firstNameValidationState.isValid &&
      this.lastNameValidationState.isValid &&
      this.emailValidationState.isValid &&
      this.phoneNumberValidationState.isValid &&
      this.postalCodeValidationState.isValid &&
      this.cityValidationState.isValid &&
      this.streetValidationState.isValid &&
      this.houseNumberValidationState.isValid &&
      this.agreementToContactFromAdvisorValidationState.isValid;
  }

  async validateCanShowBestSso() {
    const emailValidationState = await this._createEmailValidationState();

    return this._createSalutationValidationState().isValid &&
      this._createFirstNameValidationState().isValid &&
      this._createLastNameValidationState().isValid &&
      emailValidationState.isValid &&
      this._createPhoneValidationState().isValid &&
      this._createPostalCodeValidationState().isValid &&
      this._createCityValidationState().isValid &&
      this._createStreetValidationState().isValid &&
      this._createHouseNumberValidationState().isValid;
  }

  _createSalutationValidationState() {
    return assertIsNotEmpty(
      this.personalDataStore.personalData.salutation,
      ERROR_MESSAGES.PERSONAL_DATA.SALUTATION_REQUIRED);
  }

  _createFirstNameValidationState() {
    return assertMinMaxLength(
      this.personalDataStore.personalData.firstName,
      1,
      50,
      ERROR_MESSAGES.PERSONAL_DATA.FIRSTNAME_REQUIRED
    );
  }

  _createLastNameValidationState() {
    return assertMinMaxLength(
      this.personalDataStore.personalData.lastName,
      1,
      50,
      ERROR_MESSAGES.PERSONAL_DATA.LASTNAME_REQUIRED
    );
  }

  async _createEmailValidationState() {
    let validationState =
      assertIsNotEmpty(this.personalDataStore.personalData.email, ERROR_MESSAGES.PERSONAL_DATA.EMAIL_REQUIRED);

    if (validationState.isValid) {
      validationState =
        assertIsValidEmail(this.personalDataStore.personalData.email, ERROR_MESSAGES.PERSONAL_DATA.EMAIL_INVALID);
    }

    if (validationState.isValid) {
      const { isValid } = await emailService.validateEmailAddress(this.personalDataStore.personalData.email);
      validationState = assertIsTrue(isValid, ERROR_MESSAGES.PERSONAL_DATA.EMAIL_INVALID);
    }

    return validationState;
  }

  _createPhoneValidationState() {
    const phoneNumber = this.personalDataStore.personalData.phoneNumber;
    if (!phoneNumber) {
      return new ValidationState(false, ERROR_MESSAGES.PERSONAL_DATA.PHONENUMBER_REQUIRED);
    }

    return assertIsValidPhoneNumber(phoneNumber, ERROR_MESSAGES.PERSONAL_DATA.PHONENUMBER_INVALID);
  }

  _createPostalCodeValidationState() {
    const address = this.personalDataStore.personalData.address;

    let postalCodeValidationState =
      assertIsNotEmpty(address.postalCode, ERROR_MESSAGES.PERSONAL_DATA.POSTALCODE_REQUIRED);

    if (!postalCodeValidationState.isValid) {
      return postalCodeValidationState;
    }

    postalCodeValidationState =
      assertIsValidPostalCode(address.postalCode, ERROR_MESSAGES.PERSONAL_DATA.POSTALCODE_INVALID);

    postalCodeValidationState.isValid = postalCodeValidationState.isValid && this.personalDataStore.streets.length > 0;

    if (postalCodeValidationState.isValid === false) {
      postalCodeValidationState.errorMessage = ERROR_MESSAGES.PERSONAL_DATA.POSTALCODE_INVALID;
    }
    return postalCodeValidationState;
  }

  _createCityValidationState() {
    const postalCodeValidationState = this._createPostalCodeValidationState();

    if (postalCodeValidationState.isValid) {
      const { address } = this.personalDataStore.personalData;

      return assertIsPartOfInputList(
        address.city,
        this.personalDataStore.cities,
        ERROR_MESSAGES.PERSONAL_DATA.CITY_REQUIRED);
    }

    return new ValidationState();
  }

  _createStreetValidationState() {
    const postalCodeValidationState = this._createPostalCodeValidationState();
    if (postalCodeValidationState.isValid) {
      const address = this.personalDataStore.personalData.address;
      return assertMinMaxLength(address.street, 1, 255, ERROR_MESSAGES.PERSONAL_DATA.STREET_REQUIRED);
    }

    return new ValidationState();
  }

  _createHouseNumberValidationState() {
    const postalCodeValidationState = this._createPostalCodeValidationState();
    if (postalCodeValidationState.isValid) {
      const address = this.personalDataStore.personalData.address;

      let houseNumberValidationState =
        assertIsNotEmpty(address.houseNumber, ERROR_MESSAGES.PERSONAL_DATA.HOUSENUMBER_REQUIRED);

      if (houseNumberValidationState.isValid) {
        houseNumberValidationState =
          assertMinMaxLength(address.houseNumber, 1, 10, ERROR_MESSAGES.PERSONAL_DATA.HOUSENUMBER_REQUIRED);
      }

      return houseNumberValidationState;
    }

    return new ValidationState();
  }

  @action validateSalutation() {
    this.salutationValidationState = this._createSalutationValidationState();
  }

  @action validateFirstName() {
    this.firstNameValidationState = this._createFirstNameValidationState();
  }

  @action validateLastName() {
    this.lastNameValidationState = this._createLastNameValidationState();
  }

  @flow *validateEmail() {
    this.emailValidationState = yield this._createEmailValidationState();
  }

  @action validatePhoneNumber() {
    this.phoneNumberValidationState = this._createPhoneValidationState();
  }

  @action validatePostalCode() {
    this.postalCodeValidationState = this._createPostalCodeValidationState();
  }

  @action validateCity() {
    this.cityValidationState = this._createCityValidationState();
  }

  @action validateCityAfterPostalCode() {
    if (this.personalDataStore.streets.length > 0) {
      this.cityValidationState = new ValidationState();
    }
  }

  @action validateStreet() {
    this.streetValidationState = this._createStreetValidationState();
  }

  @action validateHouseNumber() {
    this.houseNumberValidationState = this._createHouseNumberValidationState();
  }

  @action validateAgreementToContactFromAdvisor() {
    const isValid = this.personalDataStore.personalData.agreementToContactFromAdvisor;

    this.agreementToContactFromAdvisorValidationState = new ValidationState(isValid,
      ERROR_MESSAGES.PERSONAL_DATA.AGREEMENT_REQUIRED);
  }

  hasValidPostalCode() {
    const { address } = this.personalDataStore.personalData;

    return assertIsNotEmpty(address.postalCode).isValid &&
      this.postalCodeValidationState.isValid;
  }
}

export default PersonalDataValidationStore;

