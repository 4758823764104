import type { IDevice, IOS, UAParserInstance } from 'ua-parser-js';
import UAParser from 'ua-parser-js';

function getUaParse(userAgent: string): UAParserInstance {
  const parser = new UAParser();
  return parser.setUA(userAgent);
}

export function getOs(userAgent: string): IOS {
  return getUaParse(userAgent).getOS();
}

export function getDevice(userAgent: string): IDevice {
  return getUaParse(userAgent).getDevice();
}
