import { observable, action, flow, makeObservable } from 'mobx';
import { webAppLoggingService } from 'bu.logger-client';
import { isClient, calculatePointsXtremePoints } from 'bu.helpers';
import { NO_RESULTS_SPECIAL_TARIFF_ID } from 'bu.lookups';

import wishlistService from '../services/wishlist.service';

import { getTariffListQueryVariables } from '../helpers/tariff.helper';

import { TariffId } from '../types/tariff-id';
import { ToggleWishListItem } from '../types/wishlist';
import storeFactory from '../factories/store.factory';
import { UnifiedLoginData } from '../types/unified-login-data';
import featureSwitchService from '../services/feature-switch.service';

class WishlistStore {
  @observable wishlistTariffIds: TariffId[] = [];
  #initialized: boolean = false;
  #pendingTariffToCreate: ToggleWishListItem | undefined;
  #pendingTariffIdToDelete: TariffId | undefined;
  ssoStore = storeFactory.getSsoStore();
  deviceOutputStore = storeFactory.getDeviceOutputStore();

  constructor() {
    makeObservable(this);
    if (isClient()) {
      window.addEventListener('sso-store-post-login', (e) => this.handleSsoEvent(e));
      window.addEventListener('unified-login', (e) => this.handleUnifiedLoginEvent(e));
      window.addEventListener('logout-custom-event', () => this.handleLogoutCustomEvent());
    }
  }

  handleUnifiedLoginEvent(e: CustomEvent<{ ul: UnifiedLoginData }>) {
    if (e.detail.ul.STATE === 'browserback') {
      this.updateUIStateAfterLoginPopupClosed();
    }
  }

  async handleSsoEvent(e: CustomEvent) {
    const ssoUserData = e.detail.ssoUserData;
    if (ssoUserData) {
      await this.processPendingTariff();
      const ids = await wishlistService.getWishlistTariffIdsOfCurrentUser();
      this.init(ids);
    }
  }

  handleLogoutCustomEvent() {
    this.init([]);
  }

  updateUIStateAfterLoginPopupClosed() {
    if (this.#pendingTariffToCreate?.tariff) {
      this.removeIdFromWishlistTariffIds(this.#pendingTariffToCreate.tariff._id);
    } else if (this.#pendingTariffIdToDelete) {
      this.addIdInWishlistTariffIds(this.#pendingTariffIdToDelete);
    }

    this.#pendingTariffToCreate = undefined;
    this.#pendingTariffIdToDelete = undefined;
  }

  getPendingTariffToCreate(): ToggleWishListItem | undefined {
    return this.#pendingTariffToCreate;
  }

  setPendingTariffToCreate(pendingTariffToCreate: ToggleWishListItem | undefined) {
    this.#pendingTariffToCreate = pendingTariffToCreate;
  }

  setPendingTariffIdToDelete(tariffId: TariffId | undefined) {
    this.#pendingTariffIdToDelete = tariffId;
  }

  @action
  init(wishlistTariffIds: TariffId[]) {
    if (this.#initialized) {
      return;
    }

    this.wishlistTariffIds = wishlistTariffIds;
    this.#initialized = true;
  }

  @action
  addIdInWishlistTariffIds(tariffId: TariffId) {
    this.wishlistTariffIds.push(tariffId);
  }

  @action
  removeIdFromWishlistTariffIds(tariffId: TariffId) {
    this.wishlistTariffIds = this.wishlistTariffIds.filter(id => id !== tariffId);
  }

  @flow *createWishlistItem({ tariff, searchData }: ToggleWishListItem) {
    const response: boolean = yield wishlistService.createWishlistItem({
      tariffId: tariff._id,
      price: tariff.netPrice,
      product: tariff.product,
      searchData: { ...getTariffListQueryVariables(searchData) }
    });

    if (!response) {
      this.setPendingTariffToCreate({ tariff, searchData });

      const pointsAmount = this.getPointsAmount(tariff);

      this.ssoStore.openUnifiedLoginLayer(pointsAmount);
    }
  }

  @flow *deleteWishlistItem(tariffId: TariffId) {
    const response: boolean = yield wishlistService.deleteWishlistItem(tariffId);

    if (!response) {
      this.setPendingTariffIdToDelete(tariffId);

      const pointsAmount = '';
      this.ssoStore.openUnifiedLoginLayer(pointsAmount);
    }
  }

  @flow *processPendingTariff() {
    try {
      if (this.#pendingTariffToCreate?.tariff) {
        yield this.createWishlistItem(this.#pendingTariffToCreate);
        this.#pendingTariffToCreate = undefined;
      } else if (this.#pendingTariffIdToDelete) {
        yield this.deleteWishlistItem(this.#pendingTariffIdToDelete);
        this.#pendingTariffIdToDelete = undefined;
      }
    } catch (error) {
      // Since the page will reload after this,the browser might not have time to log the error over the network
      yield webAppLoggingService.error('"processPendingTariff" failed', error);
    }
  }

  @flow *tryDeleteWishlistItem(tariffId: TariffId) {
    try {
      this.removeIdFromWishlistTariffIds(tariffId);
      yield this.deleteWishlistItem(tariffId);
    } catch (error) {
      webAppLoggingService.error('Failed to delete wishlist item: ', error);
      this.addIdInWishlistTariffIds(tariffId);
    }
  }

  @flow *tryAddWishlistItem({ tariff, searchData }: ToggleWishListItem) {
    try {
      this.addIdInWishlistTariffIds(tariff._id);
      yield this.createWishlistItem({ tariff, searchData });
    } catch (error) {
      webAppLoggingService.error('Failed to create wishlist item: ', error);
      this.removeIdFromWishlistTariffIds(tariff._id);
    }
  }

  @flow *toggleWishList({ tariff, searchData }: ToggleWishListItem) {
    if (!this.ssoStore.isLoggedIn) {
      this.setPendingTariffToCreate({ tariff, searchData: searchData });
      this.addIdInWishlistTariffIds(tariff._id);

      const pointsAmount = this.getPointsAmount(tariff);
      this.ssoStore.openUnifiedLoginLayer(pointsAmount);
      return;
    }

    if (this.wishlistTariffIds.includes(tariff._id)) {
      yield this.tryDeleteWishlistItem(tariff._id);
    } else {
      yield this.tryAddWishlistItem({ tariff, searchData });
    }
  }

  getPointsAmount(tariff: ToggleWishListItem['tariff']): string {
    if (featureSwitchService.isLinearPointsXtremeEnabled() && this.deviceOutputStore.isApp()) {
      const tariffNetPrice = tariff.netPrice || 0;
      const tariffId = tariff._id || NO_RESULTS_SPECIAL_TARIFF_ID;
      return calculatePointsXtremePoints(tariffNetPrice, tariffId, webAppLoggingService).toString();
    }
    return '';
  }
}

export default WishlistStore;
