import moment from 'moment-timezone';
import { DATE_FORMAT } from 'bu.formatters';
import { ValueOf } from 'bu.lookups';
import BIRTHDAY_VALIDATION_STATUS from '../constants/birthday-validation-status.constant';

export function getAge(birthdayInISOFormat: string) {
  const today = new Date();
  const birthDate = new Date(`${birthdayInISOFormat}T00:00:00`);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function isYoungEnough(date: string) {
  const maxAgeExclusive = 65 + 1; // means 65 years plus the whole 65th year
  return getAge(date) < maxAgeExclusive;
}

export function isOldEnough(date: string) {
  const minAge = 10;
  return getAge(date) >= minAge;
}

export function getAdjustedBirthdayYear(day: string, month: string, year: string) {
  if (!year || year === '20' || year === '19' || year.length !== 2) {
    return year;
  }

  const yearWith2Digits = formatWith2DigitsIfNeeded(year);
  const currentYear = Number(moment().format('YY'));
  if (Number(year) > currentYear) {
    const assumed1900Date = moment(`${day}.${month}.19${yearWith2Digits}`, DATE_FORMAT.GERMAN_DATE);

    if (assumed1900Date.isValid()) {
      return assumed1900Date.get('year');
    }
  }

  const assumed2000Date = moment(`${day}.${month}.20${yearWith2Digits}`, DATE_FORMAT.GERMAN_DATE);
  if (assumed2000Date.isValid()) {
    return assumed2000Date.get('year');
  }

  return year;
}

export function formatWith2DigitsIfNeeded(value: string|number) {
  if (!value) {
    return value;
  }

  return (`0${value}`).slice(-2);
}

export function isStringEmpty(value: string) {
  return value === '';
}

export function isISO8601DateValid(date: string) {
  return moment(date, DATE_FORMAT.ISO_8601_DATE, true).isValid();
}

export function validateBirthday(birthday: string):
  { valid: true; status?: undefined }|{ valid: false; status: ValueOf<typeof BIRTHDAY_VALIDATION_STATUS> } {
  if (isStringEmpty(birthday)) {
    return { valid: false, status: BIRTHDAY_VALIDATION_STATUS.EMPTY };
  }

  if (!isISO8601DateValid(birthday)) {
    return { valid: false, status: BIRTHDAY_VALIDATION_STATUS.INVALID };
  }

  if (!isYoungEnough(birthday)) {
    return { valid: false, status: BIRTHDAY_VALIDATION_STATUS.MAX_AGE_VIOLATED };
  }

  if (!isOldEnough(birthday)) {
    return { valid: false, status: BIRTHDAY_VALIDATION_STATUS.MIN_AGE_VIOLATED };
  }

  return { valid: true };
}

export function isAgeBetween(birthday: string, maxYearsIncluded: number, minYearsIncluded: number) {
  const age = getAge(birthday);
  return age <= maxYearsIncluded && age >= minYearsIncluded;
}
