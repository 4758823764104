import { action, makeObservable, observable } from 'mobx';
import { Tariff } from '../types/tariff';
import { TariffId } from '../types/tariff-id';

class ActiveTariffInfoStore {
  @observable tariff?: Tariff;
  @observable isResultZeroTariff = false;

  constructor() {
    makeObservable(this);
  }

  @action
  clear() {
    this.tariff = undefined;
    this.isResultZeroTariff = false;
  }

  isSelected(tariffId: TariffId, isResultZeroTariff: boolean) {
    return this.tariff?._id === tariffId && isResultZeroTariff === this.isResultZeroTariff;
  }
}

export default ActiveTariffInfoStore;
